import React from "react";
import Header from "./Header";
import WebNotif from "../components/WebNotif";
import Loader from "react-loader-spinner";
import $ from "jquery";
import { connect } from "react-redux";
import {
  login,
  getUser,
  SaveUser,
  logout,
  checkEpePoints,
} from "../redux/user/useraction";
var obj = new WebNotif();

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      cnic: "",
      password: "",
      checkemail: "admin",
      checkpassword: "admin123",
      loginResponse: [],
      engineeridResponse: [],
      enabled: false,
      showActive: false,
      isApplicant: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state?.epe === "epe") {
      this.setState({ isApplicant: true });
    }
    document.title = "Login";
    window.scrollTo(0, 0);
    var selector = ".nav a";
    $(selector).on("click", function () {
      $(selector).removeClass("active");
    });
  }

  logout = () => {
    this.props.logout();
    this.props.history.push("/login");
  };

  setClass = () => {
    this.setState({
      showActive: !this.state.showActive,
    });
  };

  loginFunction = (event) => {
    event.preventDefault();
    this.setState({ loading: true }, () => {
      this.props
        .login({ cnic: this.state.cnic, password: this.state.password })
        .then((res) => {
          if (res.success == true) {
            this.props
              .getUser(res.id)
              .then((res) => {
                this.props
                  .SaveUser(res)
                  .then((res) => {
                    obj.createNotification("success", "Login Successful");
                    if (
                      this.props.location.state &&
                      this.props.location.state.redirect
                    ) {
                      this.props.history.push({
                        pathname: this.props.location.state.redirect,
                        state: {
                          userId: res.UserID,
                        },
                      });
                    } else {
                      if (this.state.isApplicant === true) {
                        // Check if user is a PE (Uneligible)
                        // Check registration date and exam date have a gap of 5 years or more
                        // User should already have 17 approved points
                        // User should already have 10 or more work based activity points
                        // Check if user is a PE
                        // Check registration date and exam date have a gap of 5 years or more
                        // User should already have 17 approved points
                        // Check if user is graduate after january 15, 2008
                        this.props
                          .checkEpePoints({ id: res.data.UserID })
                          .then((res) => {
                            const {
                              hasValidExperience,
                              hasValidCPDPoints,
                              hasValidWorkBasedPoints,
                              isNotPE,
                              isValidGraduate,
                            } = res?.data;

                              // Store the response in local storage
                         
                            if (
                              hasValidExperience === false ||
                              hasValidCPDPoints === false ||
                              hasValidWorkBasedPoints === false ||
                              isNotPE === false ||
                              isValidGraduate === false
                            ) {
                              this.setState({ pageLoader: false });
                              // this.props.history.push(
                              //   "/engineer/engineer-payment"
                              // );
                              // TODO: Uncomment the code below for engineer application validation
                              this.props.history.push({
                                pathname: "/engineer/access-denied",
                                state: {
                                  title: "You are not eligible",
                                  hasValidCPDPoints: hasValidCPDPoints,
                                  hasValidExperience: hasValidExperience,
                                  hasValidWorkBasedPoints:
                                    hasValidWorkBasedPoints,
                                  isNotPE: isNotPE,
                                  isValidGraduate: isValidGraduate,
                                },
                              });
                            } else {
                              this.props.history.push(
                                "/engineer/engineer-epe-form"
                              );
                            }
                          })
                          .catch((err) => {
                            console.log("errrr login check epe points");
                            console.log(err);
                          });
                      } else {
                        this.props.history.push("/cpd/CpdFormProfile"); //cpd profile path after login
                      }
                    }
                  })
                  .catch((error) => {
                    console.log("out2 error", error);
                    this.setState({ loading: false });
                    obj.createNotification("error", "Something went wrong");
                  });
              })
              .catch((e) => {
                console.log("out1 error", e);
                this.setState({ loading: false });
                obj.createNotification("error", "Something went wrong");
              });
          } else {
            obj.createNotification(
              "error",
              "Login Failed! Please contact engineer registration portal"
            );
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          console.log("Login component error: ", err);
          this.setState({ loading: false });
          obj.createNotification("error", "Something went wrong");
        });
    });
  };

  statechange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { cnic, password } = this.state;
    const enabled = cnic.length > 0 && password.length > 0;
    return (
      <React.Fragment>
        <WebNotif />
        <section className="backco_white" style={{ fontFamily: "Arial" }}>
          <Header />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row col_margin mt_5v">
                  <div className="col-md-6">
                    <ul className="nav nav-tabs" role="tablist">
                      <a
                        className={`a_prop active ${
                          this.state.showActive ? "active" : ""
                        }`}
                        data-toggle="tab"
                        href="#OldWebinars"
                      >
                        Login
                      </a>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="nav nav-tabs" role="tablist">
                      <a
                        className="a_prop"
                        data-toggle="tab"
                        href="#HappeningWebinars"
                      >
                        Engineer Registeration
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="hrrow" />
          {this.props.User &&
          this.props?.history?.location?.state === undefined ? (
            <div>
              <div className="row coming-soon">
                <h2>You are Already logged In</h2>
              </div>

              <div className="row coming-soon ">
                <button
                  type="button"
                  onClick={this.logout}
                  class="btn btn-dark"
                >
                  <i className="fas fa-sign-out-alt"></i> Log out
                </button>
              </div>
            </div>
          ) : this.props.User &&
            this.props?.history?.location?.state !== undefined ? (
            this.props.history.push("/engineer/engineer-epe-form")
          ) : (
            <>
              <div className="tab-content">
                <div id="OldWebinars" className="container  tab-pane active ">
                  <div className="row col_margin backco_white">
                    <div className="col-md-3 col-sm-2 col-xs-12 col_padding"></div>

                    <div className="col-md-6 col-sm-6 col-xs-12 col_padding">
                      <form
                        className="form-horizontal form-paddin-register needs-validation"
                        data-toggle="validator"
                        role="form"
                      >
                        <h3 className="center bold">Login</h3>
                        <div className="col-sm-12">
                          <p className="center fz_14">
                            Please enter your account credentials to log into
                            Continuing Professional Development Portal.
                          </p>
                          <ul className="mp-20 bold fz_14">
                            <li>
                              Only Engineers Registered With PEC Can Login.
                            </li>
                            <li>
                              Please use same CNIC & Password as in Engineers
                              Registration Portal (portal.pec.org.pk)
                            </li>
                          </ul>
                        </div>

                        <div className="pl_26">
                          <div className="form-group">
                            <label
                              className="pl-18px control-label ipd_col_pad"
                              for="emailr"
                            >
                              CNIC<span className="co_red">*</span>
                            </label>
                            <div className="col-sm-8 ipd_col_pad">
                              <input
                                required
                                id="cnic"
                                value={this.state.cnic}
                                type="text"
                                name="cnic"
                                className="form-control co_black"
                                placeholder="Enter your CNIC"
                                onChange={this.statechange}
                              />
                            </div>
                            <div className="invalid-feedback">
                              Please fill out this field.
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="pl-18px control-label ipd_col_pad">
                              Password<span className="co_red">*</span>
                            </label>
                            <div className="col-sm-8 ipd_col_pad">
                              <input
                                type="password"
                                className="form-control co_black"
                                placeholder="Enter your Password "
                                onChange={this.statechange}
                                required
                                value={this.state.password}
                                name="password"
                              />
                            </div>
                          </div>
                          <div className="form-group col-sm-8">
                            <div className="custom-control custom-checkbox check-pl">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck"
                                name="example1"
                              />
                              <label
                                className="custom-control-label lb-font pt_3 "
                                htmlFor="customCheck"
                                style={{ color: "black" }}
                              >
                                Remember me
                              </label>
                              <a
                                target="_blank"
                                href="https://portal.pec.org.pk/Login/RecoverPassword"
                                className="forget float_r pt_3"
                              >
                                {" "}
                                Forgot my password
                              </a>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="col-sm-offset-2 col-sm-8">
                              {this.state.loading ? (
                                <div className="mb-40 center ">
                                  <Loader
                                    type="Oval"
                                    color="#06580e"
                                    height={80}
                                    width={80}
                                  />
                                </div>
                              ) : (
                                <button
                                  type="submit"
                                  disabled={!enabled}
                                  className="btn btn-success bold"
                                  style={{ width: "100%" }}
                                  onClick={this.loginFunction}
                                >
                                  LOGIN
                                </button>
                              )}
                              {/* <p
                                className="text-danger pt-1"
                                style={{ fontSize: 14 }}
                              >
                                Last date for EPE application submission is 29th
                                April 2024
                              </p> */}
                            </div>
                          </div>
                          <div className="form-group text-center">
                            <div className="col-sm-10">
                              <ul className="nav nav-tabs" role="tablist"></ul>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-auto col_padding"></div>
                  </div>
                </div>

                <div id="UpcomingWebinars" className="container tab-pane fade">
                  <div className="row  col_margin d-flex justify-content-center">
                    <div className="col-md-6 d-none col_padding mr_23">
                      <form className="form-horizontal form-paddin-register">
                        <h3 className="center bold">New Users</h3>
                        <p className="center mlr-p-30 fz_12">
                          If you only want to sign up as a new user for
                          Continuing Professional Development Portal, please
                          sign up below.
                        </p>
                        <div className="form-group pl_12">
                          <label className="pl-18px control-label">
                            Select Your Discipline
                            <span className="co_red">*</span>
                          </label>
                          <div className="col-sm-10">
                            <select
                              value={this.state.discipline}
                              onChange={this.statechange}
                              name="discipline"
                              className="form-control co_black"
                            >
                              <option>Choose Option </option>
                              <option>CVIL</option>
                              <option>ELECTRICAL</option>
                              <option>MECHANICAL</option>
                              <option>CHEMICAL</option>
                              <option>ELECTRONICS</option>
                              <option>METALLURGY</option>
                              <option>AGRICULTURAL</option>
                              <option>AERONAUTICAL</option>
                              <option>MINING</option>
                              <option>PETROGAS</option>
                              <option>TELE COMMUNICATION</option>
                              <option>MECHATRONICS</option>
                              <option>INDUSTRIAL</option>
                              <option>NUCLEAR</option>
                              <option>TEXTILE</option>
                              <option>BIOMEDICAL</option>
                              <option>ENGINEERING SCIENCES</option>
                              <option>ARCH ENGG</option>
                              <option>COMPUTER</option>
                              <option>GEOLOGY</option>
                              <option>TRANSPORT</option>
                              <option>POLY</option>
                              <option>ENVIRONMENT</option>
                              <option>URBAN</option>
                              <option>AUTOMOTIVE</option>
                              <option> GEOINFORMATICS</option>
                              <option>ENERGY</option>
                            </select>
                          </div>
                        </div>
                        <div className="pl_12">
                          <div className="form-group">
                            <label className="pl-18px control-label">
                              {" "}
                              PEC Number<span className="co_red">*</span>
                            </label>
                            <div className="col-sm-10">
                              <input
                                type="text"
                                name="pecnumber"
                                onChange={this.statechange}
                                className="form-control co_black"
                                placeholder="PEC Number "
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="pl-18px control-label">
                              Registeration Number
                              <span className="co_red">*</span>
                            </label>
                            <div className="col-sm-10">
                              <input
                                type="text"
                                name="registerNo"
                                onChange={this.statechange}
                                className="form-control co_black"
                                placeholder="Registeration Number "
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="pl-18px control-label">
                              Email<span className="co_red">*</span>
                            </label>
                            <div className="col-sm-10">
                              <input
                                type="email"
                                name="email"
                                onChange={this.statechange}
                                className="form-control co_black"
                                placeholder="Email "
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="pl-18px control-label">
                              Password<span className="co_red">*</span>
                            </label>
                            <div className="col-sm-10">
                              <input
                                type="password"
                                name="password"
                                onChange={this.statechange}
                                className="form-control co_black"
                                placeholder="Password "
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-sm-offset-2 col-sm-10">
                              <button
                                type="button"
                                disabled={!enabled}
                                className="btn btn-success bold"
                                style={{
                                  width: "100%",
                                  margin: "15px 0px",
                                  paddingLeft: "unset",
                                }}
                              >
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div id="HappeningWebinars" className="container tab-pane fade">
                  <div className="row col_margin d-flex justify-content-center">
                    <div className="col-md-6 col_padding mr_23  ">
                      <form className="form-horizontal form-paddin-register">
                        <h3 className="center bold">Engineers Registeration</h3>
                        <p className="center mlr-p-30 fz_12">
                          If you are not a registered engineer and want to
                          register yourself as an engineer, please click on
                          register button below.
                        </p>
                        <div className="pl_12">
                          <div className="form-group">
                            <div className="col-sm-offset-2 col-sm-10">
                              <a
                                type="button"
                                href="http://portal.pec.org.pk"
                                className="btn btn-success bold"
                                style={{ width: "100%" }}
                              >
                                Register
                              </a>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-sm-offset-2 col-sm-10 text-center">
                              <ul
                                className="nav nav-tabs nostyle_tab"
                                role="tablist"
                              >
                                <a
                                  className="forget a_width"
                                  onClick={this.setClass}
                                  data-toggle="tab"
                                  href="#OldWebinars"
                                >
                                  Already have an account? <u>Sign in Now</u>
                                </a>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    User: state.userData.user,
  };
}
export default connect(mapStateToProps, {
  login,
  getUser,
  SaveUser,
  logout,
  checkEpePoints,
})(Login);
