import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/App.css";
import "../assets/css/react-super-select.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/js/bootstrap.min.js";

import { NotificationContainer } from "react-notifications";

import UserRoute from "../Home/UserRoute";
import ProtectedAdminRoute from "./ProtectedAdminRoute";
import Footer from "../Home/Footer";
import StaticHome from "../Home/homeT";
import Introduction from "../Home/Introduction";
import Login from "../Home/Login";
import Register from "../Home/Register";
import Service from "../Home/Service";
import ForgotPassword from "../Home/ForgotPassword";
import OnlineCourse from "../Home/AssesmentCourse";
import CpdShortCourse from "../Home/CpdShort";
import UserView from "../Home/UserView";
import CpdProfile from "../Home/CpdProfile";
import LiveWebinar from "../Home/LiveWebinar";
import Chat from "../Home/Chat";
import MessageChairman from "../Home/MessageChairman";
import ByeLaws from "../Home/Cpdbyelaws";
import GuideLineMaual from "../Home/GuideLineManual";
import EPE from "../Home/EPE";
import PEB from "../Home/PEB";
import FORMDOCUMENT from "../Home/FormDocumen";
import ScheduleCpdActivityYY from "../Home/ScheduleCpdActivity";
import CPDRecognition from "../Home/CPDrecognition";
import ProfessionalEngnrBodie from "../Home/ProfessionalEngnrBodies";
import ComingSoon from "../Home/ComingSoon";
import ShortCoursesPPTA from "../Home/ShortCoursesPPT";
import Principal from "../Home/Registeration_PEB/Principal";
import Crieteria from "../Home/Registeration_PEB/Crieteria";
import Procedure from "../Home/Registeration_PEB/Procedure";
import GuidelinesCPDBodieswithPEC from "../Home/Registeration_PEB/GuidelinesCPDBodieswithPEC";
import FeeStructureforRegistration from "../Home/Registeration_PEB/FeeStructureforRegistration";
import WebinarSearch from "../Home/WebinarSearch";
import DescriptionWebinar from "../Home/Description_Webinar";
import UserLogss from "../Home/UserLogss";
import Webinartest from "../Home/Webinartest";
import EpdcIntroduction from "../Home/Epdc_introduction";
import ContactUS from "../Home/Contactus";
import Album from "../Home/Album";
import Gallery from "../Home/Gallery";
import Organogram from "../Home/Organogram";
import EPDCCompostion from "../Home/EPDC_Compostion";
import EPECustom from "../Home/EPECustom";
import CalculatorofCPD from "../Home/Calculator_cpd";
import EPEForms from "../Home/EPEForms";
import CPDForms from "../Home/CPDForms";
import Documents from "../Home/Documents";
import CPD_Resource_person from "../Home/CPDResourcePerson";
import Api_testing from "../Home/Api_testing";
import PEBs_calendar_2020 from "../Home/Peb_calender20";
import CPDRelaxPolicy from "../Home/CPDRelaxPolicy";
import CPDBriefs from "../Home/CPDBriefs";
import CPDListofResourcePerson from "../Home/CPDListofResourcePerson";
import CPDResourcePersonCriteria from "../Home/CPDResourcePersonCriteria";
import ApplicationForm from "../Home/ApplicationForm";
import UserPayment from "../Home/UserPayment";
// import Zoom from "../Home/zoom";

import signup from "./adminDashboard/setPassword";
import ResetPassword from "./adminDashboard/ResetPassword";

// Imports Mubashir Client Side Components
import ClaimCPD from "../Home/CPD Profile/ClaimCPD";
import CpdFormProfile from "../Home/CPD Profile/CpdFormProfile";
import Pdf from "../Home/Pdf";
import AdminLogin from "./adminDashboard/AdminLogin";
import AdminDashboard from "./adminDashboard/AdminMain";

import Page404 from "./404";

import * as routes from "../constants/routes";
// import EngineerLogin from "../Home/EPE/EngineerLogin";
import AccessDenied from "../Home/EPE/AccessDenied";
import EngineerPayment from "../Home/EPE/EngineerPayment";
import ApplicationPreview from "../Home/EPE/ApplicationPreview";
import EngineerSponsorForm from "../Home/EPE/EngineerSponser";
import EngineerPaymentPdf from "../Home/EPE/EngineerPaymentPdf";
import EngineerEpeForm from "../Home/EPE/EngineerEpeForm";
import CenterForm from "../Home/EPE/CenterForm";

// Ehtsham's Components
import NewResetPassword from "./adminDashboard/NewResetPassword";
import ResetAdminPassword from "./adminDashboard/ResetAdminPassword";

import AdminSummary from "./adminDashboard/AdminSummary";
import CpdProfilePoints from "../Home/CPDProfilePoints/CpdProfilePoints";
import News from "../Home/News";

class App extends Component {
  state = {
    auth: true,
    permissions: true,
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  checkout = (data) => {
    this.setState({ isclick: true, data: data });
  };
  render() {
    var isNav = true;
    var isFooter = true;
    var tp = routes.Question.split("?");
    var questionURL = tp[0];
    console.log;
    if (
      window.location.href.indexOf(questionURL) !== -1 ||
      window.location.href.indexOf("/admin") !== -1 ||
      window.location.href.indexOf("/UserPayment/pdf") !== -1 ||
      window.location.href.indexOf("/setPassword") !== -1 ||
      window.location.href.indexOf("/resetPassword") !== -1 ||
      window.location.href.indexOf("/loginReset") !== -1 ||
      window.location.href.indexOf("/setAdminPassword") !== -1 ||
      window.location.href.indexOf("/engineer/engineer-payment-pdf")
    ) {
      isNav = false;
      isFooter = false;
    }

    return (
      <React.Fragment>
        {this.state.auth && this.state.permissions ? (
          <Router>
            <div className="bg-grey UltBody">
              <div className="Total-Body">
                <Switch>
                  <Route
                    exact
                    path={"/"}
                    render={(props) => <StaticHome {...props} />}
                  />
                  <Route
                    exact
                    path={routes.StaticLogin}
                    render={(props) => <Login {...props} />}
                  />

                  <Route
                    exact
                    path={routes.Introduction}
                    render={(props) => <Introduction {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Service}
                    render={(props) => <Service {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Register}
                    render={(props) => <Register {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ForgotPassword}
                    render={(props) => <ForgotPassword {...props} />}
                  />

                  <Route
                    exact
                    path={routes.Messageforchirman}
                    render={(props) => <MessageChairman {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CpdProfile}
                    render={(props) => <CpdProfile {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CpdShortCourse}
                    render={(props) => <CpdShortCourse {...props} />}
                  />
                  {/* <Route exact path={routes.LiveWebinar} render={props => ( <UserView {...props}/>)} /> */}
                  <Route
                    exact
                    path={routes.LiveWebinar}
                    render={(props) => <DescriptionWebinar {...props} />}
                  />

                  <UserRoute exact path={routes.Chat} component={Chat} />
                  <Route
                    exact
                    path={routes.AssesmentCourse}
                    render={(props) => <OnlineCourse {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ByeLaws}
                    render={(props) => <ByeLaws {...props} />}
                  />
                  <Route
                    exact
                    path={routes.GuideLineMauals}
                    render={(props) => <GuideLineMaual {...props} />}
                  />
                  <Route
                    exact
                    path={routes.EPE}
                    render={(props) => <EPE {...props} />}
                  />
                  <Route
                    exact
                    path={routes.FormDocument}
                    render={(props) => <FORMDOCUMENT {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ScheduleCPDActivities}
                    render={(props) => <ScheduleCpdActivityYY {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDrecognition}
                    render={(props) => <CPDRecognition {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ProfessionalEngnrBodies}
                    render={(props) => <ProfessionalEngnrBodie {...props} />}
                  />

                  <Route
                    exact
                    path={routes.ComingSoon}
                    render={(props) => <ComingSoon {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ShortCoursesPPT}
                    render={(props) => <ShortCoursesPPTA {...props} />}
                  />
                  <Route
                    exact
                    path={routes.peb}
                    render={(props) => <PEB {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Principal}
                    render={(props) => <Principal {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Crieteria}
                    render={(props) => <Crieteria {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Procedure}
                    render={(props) => <Procedure {...props} />}
                  />
                  <Route
                    exact
                    path={routes.GuidelinesCPDBodieswithPEC}
                    render={(props) => (
                      <GuidelinesCPDBodieswithPEC {...props} />
                    )}
                  />
                  <Route
                    exact
                    path={routes.FeeStructureforRegistration}
                    render={(props) => (
                      <FeeStructureforRegistration {...props} />
                    )}
                  />
                  <Route
                    exact
                    path={routes.WebinarSearch}
                    render={(props) => <WebinarSearch {...props} />}
                  />
                  {/* <Route
                    exact
                    path={routes.DescriptionWebinar}
                    render={(props) => <DescriptionWebinar {...props} />}
                  /> */}

                  <Route
                    exact
                    path={routes.Instructorview}
                    render={(props) => <LiveWebinar {...props} />}
                  />
                  <Route
                    exact
                    path={routes.epdcIntro}
                    render={(props) => <EpdcIntroduction {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ContactUS}
                    render={(props) => <ContactUS {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Gallery}
                    render={(props) => <Gallery {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Album}
                    render={(props) => <Album {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Organogram}
                    render={(props) => <Organogram {...props} />}
                  />
                  <Route
                    exact
                    path={routes.EPDCCompostion}
                    render={(props) => <EPDCCompostion {...props} />}
                  />
                  <Route
                    exact
                    path={routes.EPECustom}
                    render={(props) => <EPECustom {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CalculatorofCPD}
                    render={(props) => <CalculatorofCPD {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDForms}
                    render={(props) => <CPDForms {...props} />}
                  />
                  <Route
                    exact
                    path={routes.EPEForms}
                    render={(props) => <EPEForms {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPD_Resource_person}
                    render={(props) => <CPD_Resource_person {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Documents}
                    render={(props) => <Documents {...props} />}
                  />

                  {/* <Route
                    exact
                    path={routes.api_testing}
                    render={(props) => <Api_testing {...props} />}
                  /> */}

                  <Route
                    exact
                    path={routes.userlogs}
                    render={(props) => <UserLogss {...props} />}
                  />
                  <UserRoute
                    exact
                    path={routes.webinartest}
                    component={Webinartest}
                  />
                  <Route
                    exact
                    path={routes.PEBs_calendar_2020}
                    render={(props) => <PEBs_calendar_2020 {...props} />}
                  />

                  <Route
                    exact
                    path={routes.CPDRelaxPolicy}
                    render={(props) => <CPDRelaxPolicy {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDBriefs}
                    render={(props) => <CPDBriefs {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDListofResourcePerson}
                    render={(props) => <CPDListofResourcePerson {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDResourcePersonCriteria}
                    render={(props) => <CPDResourcePersonCriteria {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ApplicationForm}
                    render={(props) => <ApplicationForm {...props} />}
                  />

                  {/* Mubashirs Client Side Routes */}

                  <Route
                    exact
                    path={"/"}
                    render={(props) => <StaticHome {...props} />}
                  />
                  <Route
                    exact
                    path={routes.StaticLogin}
                    render={(props) => <Login {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Introduction}
                    render={(props) => <Introduction {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Service}
                    render={(props) => <Service {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Register}
                    render={(props) => <Register {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ForgotPassword}
                    render={(props) => <ForgotPassword {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Messageforchirman}
                    render={(props) => <MessageChairman {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CpdProfile}
                    render={(props) => <CpdProfile {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CpdShortCourse}
                    render={(props) => <CpdShortCourse {...props} />}
                  />

                  <Route
                    exact
                    path={routes.UserView}
                    render={(props) => <UserView {...props} />}
                  />
                  <Route
                    exact
                    path={routes.LiveWebinar}
                    render={(props) => <DescriptionWebinar {...props} />}
                  />

                  <Route
                    exact
                    path={routes.AssesmentCourse}
                    render={(props) => <OnlineCourse {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ByeLaws}
                    render={(props) => <ByeLaws {...props} />}
                  />
                  <Route
                    exact
                    path={routes.GuideLineMauals}
                    render={(props) => <GuideLineMaual {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ImportantNews}
                    render={(props) => <News {...props} />}
                  />
                  <Route
                    exact
                    path={routes.EPE}
                    render={(props) => <EPE {...props} />}
                  />
                  <Route
                    exact
                    path={routes.FormDocument}
                    render={(props) => <FORMDOCUMENT {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ScheduleCPDActivities}
                    render={(props) => <ScheduleCpdActivityYY {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDrecognition}
                    render={(props) => <CPDRecognition {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ProfessionalEngnrBodies}
                    render={(props) => <ProfessionalEngnrBodie {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ShortCoursesPPT}
                    render={(props) => <ShortCoursesPPTA {...props} />}
                  />
                  <Route
                    exact
                    path={routes.peb}
                    render={(props) => <PEB {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Principal}
                    render={(props) => <Principal {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Crieteria}
                    render={(props) => <Crieteria {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Procedure}
                    render={(props) => <Procedure {...props} />}
                  />
                  <Route
                    exact
                    path={routes.GuidelinesCPDBodieswithPEC}
                    render={(props) => (
                      <GuidelinesCPDBodieswithPEC {...props} />
                    )}
                  />
                  <Route
                    exact
                    path={routes.FeeStructureforRegistration}
                    render={(props) => (
                      <FeeStructureforRegistration {...props} />
                    )}
                  />
                  <Route
                    exact
                    path={routes.WebinarSearch}
                    render={(props) => <WebinarSearch {...props} />}
                  />
                  <Route
                    exact
                    path={routes.DescriptionWebinar}
                    render={(props) => <DescriptionWebinar {...props} />}
                  />

                  <Route
                    exact
                    path={routes.Instructorview}
                    render={(props) => <LiveWebinar {...props} />}
                  />
                  <Route
                    exact
                    path={routes.epdcIntro}
                    render={(props) => <EpdcIntroduction {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ContactUS}
                    render={(props) => <ContactUS {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Gallery}
                    render={(props) => <Gallery {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Organogram}
                    render={(props) => <Organogram {...props} />}
                  />
                  <Route
                    exact
                    path={routes.EPDCCompostion}
                    render={(props) => <EPDCCompostion {...props} />}
                  />
                  <Route
                    exact
                    path={routes.EPECustom}
                    render={(props) => <EPECustom {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CalculatorofCPD}
                    render={(props) => <CalculatorofCPD {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDForms}
                    render={(props) => <CPDForms {...props} />}
                  />
                  <Route
                    exact
                    path={routes.EPEForms}
                    render={(props) => <EPEForms {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPD_Resource_person}
                    render={(props) => <CPD_Resource_person {...props} />}
                  />
                  <Route
                    exact
                    path={routes.Documents}
                    render={(props) => <Documents {...props} />}
                  />
                  {/* <Route
                    exact
                    path={routes.sub_Gallery}
                    render={(props) => <SubGallery {...props} />}
                  /> */}
                  {/* <Route
                    exact
                    path={routes.api_testing}
                    render={(props) => <Api_testing {...props} />}
                  /> */}

                  <Route
                    exact
                    path={routes.userlogs}
                    render={(props) => <UserLogss {...props} />}
                  />
                  <Route
                    exact
                    path={routes.webinartest}
                    render={(props) => <Webinartest {...props} />}
                  />
                  <Route
                    exact
                    path={routes.PEBs_calendar_2020}
                    render={(props) => <PEBs_calendar_2020 {...props} />}
                  />

                  <Route
                    exact
                    path={routes.CPDRelaxPolicy}
                    render={(props) => <CPDRelaxPolicy {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDBriefs}
                    render={(props) => <CPDBriefs {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDListofResourcePerson}
                    render={(props) => <CPDListofResourcePerson {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CPDResourcePersonCriteria}
                    render={(props) => <CPDResourcePersonCriteria {...props} />}
                  />
                  <Route
                    exact
                    path={routes.ApplicationForm}
                    render={(props) => <ApplicationForm {...props} />}
                  />

                  {/* <UserRoute
                    exact
                    path={routes.UserPayment}
                    component={UserPayment}
                  /> */}
                  {/* <Route
                    exact
                    path={'/zoom'}
                    component={Zoom}
                  /> */}

                  {/* Mubashirs Client Side Routes */}
                  <Route
                    exact
                    path={routes.UserPayment}
                    component={UserPayment}
                    render={(props) => <UserPayment {...props} />}
                  />

                  {/* <UserRoute
                    exact
                    path={routes.EngineerLogin}
                    render={(props) => <EngineerLogin {...props} />}
                  /> */}
                  <UserRoute
                    exact
                    path={routes.AccessDenied}
                    component={AccessDenied}
                  />
                  <UserRoute
                    exact
                    path={routes.EngineerPayment}
                    component={EngineerPayment}
                  />

                  <UserRoute
                    exact
                    path={routes.EngineerSponser}
                    component={EngineerSponsorForm}
                  />

                  <UserRoute
                    exact
                    path={routes.ApplicationPreview}
                    component={ApplicationPreview}
                  />
                  <UserRoute
                    exact
                    path={routes.EngineerPaymentPdf}
                    component={EngineerPaymentPdf}
                  />
                  <UserRoute
                    exact
                    path={routes.EngineerEpeForm}
                    component={EngineerEpeForm}
                  />
                  <UserRoute
                    exact
                    path={routes.CreateCenterForm}
                    component={CenterForm}
                  />
                  <UserRoute
                    exact
                    path={routes.CpdFormProfile}
                    component={CpdFormProfile}
                  />

                  <UserRoute exact path={routes.pdf} component={Pdf} />
                  <UserRoute
                    exact
                    path={routes.ClaimCPD}
                    component={ClaimCPD}
                  />

                  {/* Admin Side Routing */}
                  <Route
                    exact
                    path={routes.adminlogin}
                    render={(props) => <AdminLogin {...props} />}
                  />
                  <Route
                    exact
                    path={routes.CpdProfilePoints}
                    component={CpdProfilePoints}
                  />
                  <ProtectedAdminRoute
                    path={routes.adminDashboard}
                    component={AdminDashboard}
                  />

                  <Route exact path={routes.setPassword} component={signup} />
                  <Route
                    exact
                    path={routes.ResetPassword}
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path={routes.loginReset}
                    component={NewResetPassword}
                  />
                  <Route
                    exact
                    path={routes.setAdminPassword}
                    component={ResetAdminPassword}
                  />

                  <Route render={(props) => <Page404 {...props} />} />
                </Switch>
              </div>
              {isFooter ? <Footer /> : null}

              <NotificationContainer />
            </div>
          </Router>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default App;
