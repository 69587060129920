import React, { createRef, Fragment, Component } from "react";
import Header from "../Header";
import Cookies from "universal-cookie";
import WebNotif from "../../components/WebNotif";
import moment from "moment";
import Dropzone from "react-dropzone";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import img from "../../assets/image/designer_icon/Upload-files-here.png";
import { urlapiEPE, urlEPEImage } from "../../config/urlapi";
const cookies = new Cookies();
var obj = new WebNotif();

class EngineerPayment extends Component {
  constructor(props) {
    super(props);

    this.paymentMethod = createRef();
    this.applicantType = createRef();
    this.bankInput2 = createRef();
    this.challanInput = createRef();
    this.submissionDateRef = createRef();
    this.paperPartRef = createRef();
    this.paperTypeRef = createRef();

    this.state = {
      isLoading: false,
      pageLoader: false,
      reduxEpeForm: JSON.parse(localStorage.getItem("reduxEpeForm")),
      comp: false,
      loading: false,
      selectedOption: "",
      modalIsOpen2: false,
      image64: null,
      imageFile: null,
      userID: this?.props?.user?.PEC_REG_NO,
      userEmail: this?.props?.user?.PrimaryEmail,
      checkEpePointsID: this?.props?.user?.UserID,
      currentFileTypeofData: "",
      currentFileNameofData: "",
      examId: "",
      examName: "",
      conductDate: "",
      netcourseprice: "0",
      refno: "",
      bank: "",
      bankName: "BankAlfalah",
      selectedbankoption: { value: 0, label: "Select Payment Method" },
      selectedApplicantOption: { value: 0, label: "Select Applicant Type" },
      selectedPaperPart: { value: 0, label: "Select Attempt Number" },
      selectedPaperType: { value: 0, label: "Select Paper Type" },
      // bankoption: [{ value: 1, label: "Bank Challan Payment" }],
      // selectedBank: "BankAlfalah", // Default to HBL
      applicantOptions: [
        { value: 1, label: "New Candidates" },
        { value: 2, label: "Re-Appearing In Single Part" },
      ],
      paperPartOptions: [
        { value: 1, label: "First Attempt" },
        { value: 2, label: "Second Attempt" },
        { value: 3, label: "Third Attempt" },
      ],
      paperTypeOptions: [
        { value: 1, label: "Part I" },
        { value: 2, label: "Part II" },
      ],
      submissionDate: "",
      examData: null,
      examYear: "",
      examSeason: "",
      updateFormState: localStorage.getItem("updateApplication"),
      isApplicantDisabled: false,
    };
  }

  componentDidMount() {
    const examConductDate = localStorage.getItem("getLatestExamConductDate");
    const examName = localStorage.getItem("getLatestExamName");

    let isSpring = false;

    if (examName.includes("spring" || "Spring")) {
      isSpring = true;
    } else {
      isSpring = false;
    }

    const examArr = examConductDate.split("-");
    if (examConductDate) {
      this.setState({
        examYear: examArr[0],
        examSeason: isSpring ? "spring" : "fall",
      });
    }

    // Load saved form data from localStorage
    const savedFormData = JSON.parse(localStorage.getItem("reduxEpeForm"));
    const checkUserStatus = JSON.parse(localStorage.getItem("checkUserStatus"));
    if (savedFormData) {
      const { form } = savedFormData;
      this.setState({
        selectedApplicantOption:
          checkUserStatus?.hasUserAppeared === true
            ? { value: 2, label: "Re-Appearing In Single Part" }
            : { value: 1, label: "New Candidates" },
        isApplicantDisabled: checkUserStatus?.hasUserAppeared,
        selectedbankoption:
          { label: form.transactionType, value: 1 } ||
          this.state.selectedbankoption,
        bank: form.bankName || this.state.bank,
        refno: form.challanNumber || this.state.refno,
        netcourseprice: form.challanAmount || this.state.netcourseprice,
        submissionDate: form.submissionDate || this.state.submissionDate,
        currentFileNameofData:
          form.currentFileNameofData || this.state.currentFileNameofData,
        currentFileTypeofData:
          form.currentFileTypeofData || this.state.currentFileTypeofData,
        image64: form.paymentImage || this.state.image64,
        selectedPaperPart:
          this.state.paperPartOptions.find(
            (option) => option.label === form.selectedPaperPart
          ) || this.state.selectedPaperPart,
        selectedPaperType:
          this.state.paperTypeOptions.find(
            (option) => option.label === form.selectedPaperType
          ) || this.state.selectedPaperType,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedApplicantOption.value !==
      prevState.selectedApplicantOption.value
    ) {
      if (this.state.selectedApplicantOption.value === 1) {
        this.setState({ netcourseprice: "5000" });
      } else if (this.state.selectedApplicantOption.value === 2) {
        this.setState({ netcourseprice: "2500" });
      } else if (this.state.selectedApplicantOption.value === 0) {
        this.setState({ netcourseprice: "0" });
      }
    }
  }

  removeImage = () => {
    this.setState({ image64: null }, () => {});
  };

  delete = () => {
    this.setState({ image64: null }, () => {});
  };

  handletext = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  bankhandler = (selectedOption) => {
    this.setState({
      bank: "BankAlfalah",
      selectedbankoption: selectedOption,
    });
  };

  applicantHandler = (selectedOption) => {
    this.setState({
      selectedApplicantOption: selectedOption,
    });
  };

  paperPartHandler = (selectedOption) => {
    this.setState({
      selectedPaperPart: selectedOption,
    });
  };

  paperTypeHandler = (selectedOption) => {
    this.setState({
      selectedPaperType: selectedOption,
    });
  };

  handleOnDropImage = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyImageFile(files)) {
        const currentFile = files[0];
        this.setState({ imageFile: currentFile });
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.setState({ image64: reader.result });
          },
          false
        );
        reader.readAsDataURL(currentFile);

        obj.createNotification("success", "File Dropped");

        this.setState({
          currentFileTypeofData: currentFile.type,
          currentFileNameofData: currentFile.name,
        });
      }
    }
  };

  verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > 2e6) {
        obj.createNotification(
          "error",
          "File should be less than 2mbs, Try Again"
        );
        return false;
      }
      if (
        ![
          "image/x-png",
          "image/png",
          "image/jpg",
          "image/jpeg",
          "image/gif",
          "application/pdf",
        ].includes(currentFileType)
      ) {
        obj.createNotification(
          "error",
          "This file type is not allowed, Images and PDF are allowed"
        );
        return false;
      }
      return true;
    }
  };

  handleSubmissionDate = (e) => {
    this.setState({ ...this.state, submissionDate: e.target.value });
  };

  saveToLocalStorage = () => {
    const {
      selectedApplicantOption,
      selectedbankoption,
      selectedBank,
      bank,
      bankName,
      refno,
      submissionDate,
      selectedPaperPart,
      selectedPaperType,
      image64,
      updateFormState,
      reduxEpeForm,
    } = this.state;
    let errorMessages;
    let formFields;

    if (this.state.selectedApplicantOption.value === 0) {
      this.setState({ loading: false });
      this.applicantType.current.focus();
      return obj.createNotification(
        "error",
        "Choose Applicant Type Is Required"
      );
    }

    if (this.state.selectedbankoption.value === 0) {
      this.setState({ loading: false });
      this.paymentMethod.current.focus();
      return obj.createNotification("error", "Payment Method Is Required");
    }

    if (this.state.selectedApplicantOption.value === 1) {
      errorMessages = {
        submissionDate: "Challan submission date is required",
        challanInput:
          this.state.selectedbankoption.value === 1
            ? "Challan Number Is Required"
            : "Bank Name Is Required",
      };

      formFields = {
        submissionDate: {
          value: this.state.submissionDate,
          ref: this.submissionDateRef,
        },
        challanInput: {
          value:
            this.state.selectedbankoption.value === 1
              ? this.state.refno
              : this.state.bankName,
          ref: this.challanInput,
        },
      };
    } else if (this.state.selectedApplicantOption.value === 2) {
      errorMessages = {
        attemptNumber: "Attempt Number Is Required",
        paperType: "Paper Type Is Required",
        bankInput2: "Bank Option Is Required",
        challanInput:
          this.state.selectedbankoption.value === 1
            ? "Challan Number Is Required"
            : "Bank Name Is Required",
        submissionDate: "Challan Submission Date Is Required",
      };

      formFields = {
        attemptNumber: {
          value: this.state.selectedPaperPart,
          ref: this.paperPartRef,
        },
        paperType: {
          value: this.state.selectedPaperType,
          ref: this.paperTypeRef,
        },
        challanInput: {
          value:
            this.state.selectedbankoption.value === 1
              ? this.state.refno
              : this.state.bankName,
          ref: this.challanInput,
        },
        submissionDate: {
          value: this.state.submissionDate,
          ref: this.submissionDateRef,
        },
      };

      if (this.state.selectedPaperPart.value === 0) {
        this.setState({ loading: false });
        this.paperPartRef.current.focus();
        return obj.createNotification(
          "error",
          "Choose Attempt Number Is Required"
        );
      }

      if (this.state.selectedPaperType.value === 0) {
        this.setState({ loading: false });
        this.paperTypeRef.current.focus();
        return obj.createNotification("error", "Choose Paper Part Is Required");
      }
    }

    // Check if form fields are empty
    for (const field in formFields) {
      if (
        !formFields[field].value ||
        formFields[field].value === "0" ||
        formFields[field].value === "" ||
        formFields[field].value === 0
      ) {
        obj.createNotification("error", errorMessages[field]);
        if (field === "challanInput") {
          formFields[field].ref.current.style.border = "3px solid #20A84C";
        }
        formFields[field].ref.current.focus();
        this.setState({ loading: false });
        return;
      }
    }

    if (this.state.image64 === null) {
      return obj.createNotification("error", "Challan image is required");
    }

    const updatedForm = {
      ...reduxEpeForm,
      form: {
        ...reduxEpeForm.form,
        applicationType: selectedApplicantOption,
        transactionType: selectedbankoption.label,
        // bankName: selectedbankoption.value === 1 ? bank : bankName,
        bankName: selectedBank || "BankAlfalah",
        challanNumber: refno,
        challanAmount: this.state.netcourseprice,
        submissionDate: submissionDate,
        paymentImage: image64,
        selectedPaperPart:
          selectedPaperPart?.value === 0 ? "" : selectedPaperPart?.label,
        selectedPaperType:
          selectedPaperType?.value === 0 ? "" : selectedPaperType?.label,
        isAlreadyPaymentDone: updateFormState,
      },
    };

    localStorage.setItem("reduxEpeForm", JSON.stringify(updatedForm));
    obj.createNotification("success", "Form data saved successfully.");

    this.props.history.push("/engineer/applicant-sponser");
  };

  goToNextPage = () => {
    const formData = JSON.parse(localStorage.getItem("reduxEpeForm"));
    const paymentData = JSON.parse(localStorage.getItem("paymentData"));

    localStorage.setItem(
      "reduxEpeForm",
      JSON.stringify({
        form: {
          ...formData.form,
          ...paymentData,
          isAlreadyPaymentDone: this.state.updateFormState,
        },
      })
    );
    obj.createNotification("success", "Form data saved successfully.");

    this.props.history.push("/engineer/applicant-sponser");
  };

  // handleBankSelection = (event) => {
  //   this.setState({
  //     selectedBank: event.target.value,
  //   });
  // };

  handleBankSelection = (event) => {
    const selectedBank = event.target.value;

    // Update state with the selected bank
    this.setState({ selectedBank }, () => {
      // Update local storage
      const updatedForm = {
        ...this.state.reduxEpeForm,
        form: {
          ...this.state.reduxEpeForm.form,
          bankName: selectedBank,
        },
      };

      localStorage.setItem("reduxEpeForm", JSON.stringify(updatedForm));
    });
  };

  generateChallanPDF = async () => {
    this.setState({ isLoading: true });
    try {
      const savedFormData = JSON.parse(localStorage.getItem("reduxEpeForm"));
      const latestExamId = JSON.parse(localStorage.getItem("getLatestExamId"));
      const timeStamp = Date.now().toString();
      const remainingDigits = timeStamp.slice(-14);
      const voucherNo = `06${remainingDigits}`;

      const payload = {
        epeRegistrationFee: this.state.netcourseprice,
        nameOfEngineer: savedFormData?.form?.name,
        examId: latestExamId,
        pecRegistrationNo: savedFormData?.form?.pecNo,
        engineeringDescipline:
          savedFormData?.form?.alliedDiscipline ||
          savedFormData?.form?.discipline,
        pecFee: "0",
        bankName: "BankAlfalah",
        voucherNo: voucherNo,
      };

      const response = await fetch(`${urlapiEPE}/challan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Error generating challan");
      }

      const responseData = await response.json();

      let voucherNumber;
      console.log("dddddddddddddd", responseData);
      if (responseData.message === "Existing challan found") {
        // If existing challan is found, populate refno and download existing file
        console.log("Existing challan found:", responseData.filePath);
        voucherNumber = responseData.existingChallanData.voucherNo;
        this.setState({ refno: responseData.existingChallanData.voucherNo });
      } else {
        voucherNumber = responseData.voucherNo;
        this.setState({ refno: responseData.voucherNo });
      }

      // / Fetch the existing PDF from the URL
      fetch(urlEPEImage + responseData.filePath)
        .then((res) => res.blob()) // Convert response to blob
        .then((blob) => {
          // Create a temporary link element to trigger the download
          const downloadLink = document.createElement("a");
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = `challan_${voucherNumber}.pdf`;
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Clean up: remove the temporary link element and revoke the object URL
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Error fetching the PDF:", error));
      // } else {
      //   // If new challan is created, handle the blob download
      //   this.setState({ refno: responseData.voucherNo });

      //   // Convert base64 to binary blob and download the file
      //   const base64Response = await fetch(
      //     `data:application/pdf;base64,${responseData.pdfBuffer}`
      //   );
      //   const pdfBlob = await base64Response.blob();

      //   const downloadLink = document.createElement("a");
      //   downloadLink.href = window.URL.createObjectURL(pdfBlob);
      //   downloadLink.download = `challan_${responseData.voucherNo}.pdf`;
      //   downloadLink.click();
      // }
    } catch (error) {
      console.error("Error generating challan PDF:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { reduxEpeForm } = this.state;
    console.log(
      "image......FilName",
      this.state.currentFileNameofData,
      "image.... image64",
      this.state.image64
    );
    return (
      <Fragment>
        {this.state.pageLoader ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            {/* Loader here */}
          </div>
        ) : (
          <Fragment>
            <Header />
            <div
              className="mtb-25 payment-container"
              style={{
                padding: "20px",
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h3 className="text-center ptb-30 bold payment_heading">
                EPE Application
              </h3>
              <h5 className="text-center mt-3 co_g20A84C font-weight-bold font-16 mb-4">
                Step 4: Fee Challan
              </h5>
              <div>
                {this.state.updateFormState === "true" ? (
                  <>
                    <div className="row mtb-15">
                      <div className="col-md-12 d-flex align-items-center justify-content-center">
                        <div className="approved-payment-container text-center">
                          <b style={{ textAlign: "center", color: "#06580e" }}>
                            Note: Your payment has already been approved, please
                            submit your application
                          </b>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div
                        className="container"
                        style={{ textAlign: "center" }}
                      >
                        <div className="row mtb-15">
                          <div className="col-md-4">
                            <label
                              style={{
                                fontWeight: "bold",
                                marginTop: "5px",
                                marginLeft: "55px",
                              }}
                            >
                              Choose Applicant Type{" "}
                              <span style={{ color: "#06580e" }}>*</span>
                            </label>
                          </div>
                          <div className="col-md-4">
                            <Select
                              ref={this.applicantType}
                              options={this.state.applicantOptions}
                              value={this.state.selectedApplicantOption}
                              onChange={this.applicantHandler}
                              isDisabled={this.state.isApplicantDisabled}
                            />
                          </div>
                        </div>
                        <b style={{ textAlign: "center", color: "#06580e" }}>
                          Note: Rs. 5000/- for new candidates and Rs. 2500/- for
                          reappearing candidates in single part
                        </b>
                      </div>

                      {this.state.selectedApplicantOption?.value === 2 && (
                        <div
                          className="container"
                          style={{ textAlign: "center", marginTop: "55px" }}
                        >
                          <div className="row mtb-15">
                            <div className="col-md-4">
                              <label
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "5px",
                                  marginLeft: "55px",
                                }}
                              >
                                Choose Attempt Number{" "}
                                <span style={{ color: "#06580e" }}>*</span>
                              </label>
                            </div>
                            <div className="col-md-4">
                              <Select
                                ref={this.paperPartRef}
                                options={this.state.paperPartOptions}
                                value={this.state.selectedPaperPart}
                                onChange={this.paperPartHandler}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.selectedApplicantOption?.value === 2 && (
                        <div
                          className="container"
                          style={{ textAlign: "center", marginTop: "55px" }}
                        >
                          <div className="row mtb-15">
                            <div className="col-md-4">
                              <label
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "5px",
                                  marginLeft: "55px",
                                }}
                              >
                                Choose Paper Part{" "}
                                <span style={{ color: "#06580e" }}>*</span>
                              </label>
                            </div>
                            <div className="col-md-4">
                              <Select
                                ref={this.paperTypeRef}
                                options={this.state.paperTypeOptions}
                                value={this.state.selectedPaperType}
                                onChange={this.paperTypeHandler}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className="container"
                        style={{ textAlign: "center", marginTop: "55px" }}
                      >
                        {/* <div className="row mtb-15">
                      <div className="col-md-4">
                        <label
                          style={{
                            fontWeight: "bold",
                            marginTop: "5px",
                            marginLeft: "55px",
                          }}
                        >
                          Choose Payment Method{" "}
                          <span style={{ color: "#06580e" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-4">
                        <Select
                          ref={this.paymentMethod}
                          options={this.state.bankoption}
                          value={this.state.selectedbankoption}
                          onChange={this.bankhandler}
                        />
                      </div>
                    </div> */}
                        <b style={{ textAlign: "center", color: "#06580e" }}>
                          Note: If you have already generated challan, please
                          upload it here
                        </b>{" "}
                        <br />
                        <span
                          style={{
                            textAlign: "center",
                            color: "#06580e",
                            fontWeight: "bold",
                          }}
                        >
                          Fee can only be submitted through Bank Alfalah system
                          generated voucher. Fee deposited through any other
                          method will not be considered
                        </span>
                        {this.state.selectedbankoption?.value === 1 && (
                          <div className="row mtb-15">
                            <div className="col-md-12">
                              {/* <label>
                            <input
                              type="radio"
                              name="bankSelection"
                              value="BankAlfalah"
                              checked={
                                this.state.selectedBank === "BankAlfalah"
                              }
                              onChange={this.handleBankSelection}
                            />
                            Bank Alfalah
                          </label> */}
                              {/* <label style={{ marginLeft: "20px" }}>
                              <input
                                type="radio"
                                name="bankSelection"
                                value="BankAlfalah"
                                checked={
                                  this.state.selectedBank === "BankAlfalah"
                                }
                                onChange={this.handleBankSelection}
                              />
                              Bank Alfalah
                            </label> */}
                            </div>
                          </div>
                        )}
                      </div>

                      {/* <div className="container mt-40">
                    <div className="row mtb-20 text_center_mob detailbox">
                      <div className="col-md-6 bold"> Bank Details </div>
                      <div className="col-md-6 text-right"> HBL </div>
                      <div className="col-md-6 bold"> Account Title </div>
                      <div className="col-md-6 text-right">
                        {" "}
                        Pakistan Engineering Council
                      </div>
                      <div className="col-md-6 bold"> Ac/No </div>
                      <div className="col-md-6 text-right">
                        {" "}
                        00427901578603{" "}
                      </div>
                    </div>
                  </div> */}

                      <div className="container mt-40">
                        <div
                          className="row mtb-20 text_center_mob detailbox"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="btn admin_btn_default bold float-right text-capitalize"
                            style={{ width: "200px" }}
                            onClick={this.generateChallanPDF}
                            disabled={this.state.isLoading} // Disable the button when loading is true
                          >
                            {this.state.isLoading ? (
                              <span>
                                <i className="fa fa-spinner fa-spin" />{" "}
                                Generating...
                              </span>
                            ) : (
                              "Generate Challan"
                            )}
                          </button>

                          {/* <div className="col-md-6 bold"> Bank Details </div>
                        <div className="col-md-6 text-right">
                          {this.state.selectedBank}
                        </div>
                        <div className="col-md-6 bold"> Account Title </div>
                        <div className="col-md-6 text-right">
                          {" "}
                          Pakistan Engineering Council
                        </div>
                        <div className="col-md-6 bold"> Ac/No </div>
                        <div className="col-md-6 text-right">
                          {this.state.selectedBank === "HBL"
                            ? "00427901578603"
                            : "00427901578605"}
                        </div> */}
                        </div>
                      </div>

                      <div
                        className="container"
                        style={{ textAlign: "center" }}
                      >
                        <p style={{ fontWeight: "bold" }}>
                          Challan Information
                        </p>
                      </div>
                      <div
                        className="container mt-2"
                        style={{ textAlign: "center" }}
                      >
                        <div className="row mtb-15">
                          <div className="col-md-2">
                            <label
                              style={{
                                fontWeight: "bold",
                                marginTop: "3px",
                                marginLeft: "55px",
                              }}
                            >
                              Total Amount{" "}
                              <span
                                style={{ fontWeight: "bold", color: "#06580e" }}
                              >
                                *
                              </span>
                            </label>
                          </div>
                          <div className="col-md-1">
                            <label
                              style={{
                                borderRadius: "5px",
                                fontSize: "1.24rem",
                                fontWeight: "700",
                                marginTop: "3px",
                                color: "#06580e",
                              }}
                            >
                              Rs. {this.state.netcourseprice}
                            </label>
                          </div>
                          <div className="col-md-2">
                            <label
                              style={{
                                fontWeight: "bold",
                                marginTop: "5px",
                                marginLeft: "55px",
                              }}
                            >
                              Enter Challan No
                              <span style={{ color: "#06580e" }}>*</span>
                            </label>
                          </div>
                          <div className="col-md-2">
                            <input
                              id="challanInputId"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              ref={this.challanInput}
                              type="text"
                              name="refno"
                              value={this.state.refno}
                              placeholder="Enter Challan No"
                              min="0"
                              className="form-control"
                              onChange={this.handletext}
                              required
                              disabled
                            />
                          </div>
                          <div className="col-md-2">
                            <label
                              style={{
                                fontWeight: "bold",
                                marginTop: "5px",
                                marginLeft: "40px",
                              }}
                            >
                              Date of Submission{" "}
                              <span style={{ color: "#06580e" }}>*</span>
                            </label>
                          </div>
                          <div className="col-md-2">
                            <input
                              id="bankInputDate"
                              type="date"
                              className="form-control"
                              ref={this.submissionDateRef}
                              value={this.state.submissionDate}
                              onChange={this.handleSubmissionDate}
                              min={moment(new Date("01/01/2020")).format(
                                "YYYY-MM-DD"
                              )}
                              max={moment(new Date()).format("YYYY-MM-DD")}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="container mt-40"
                        style={{ textAlign: "center" }}
                      >
                        <b style={{ textAlign: "center" }}>
                          Upload your payment slip
                        </b>
                      </div>

                      <div className="container mt-40">
                        <div className="col-md-6 offset-md-3 file_upload_admin ptb_4v ">
                          {this.state.image64 !== null &&
                          this.state.currentFileTypeofData.match("image.*") ? (
                            <>
                              <div
                                onClick={this.removeImage}
                                className="pointer"
                                style={{
                                  display: "block",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  color: "#20A84C",
                                  height: "300px",
                                  width: "300px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                  src={this.state.image64}
                                  alt="Challan"
                                />
                                <i
                                  className="fa fa-times-circle preview-close-icon fa-lg"
                                  style={{ color: "#20A84C" }}
                                />
                              </div>
                              <p style={{ marginTop: "10px" }}>
                                {this.state.currentFileNameofData}
                              </p>
                            </>
                          ) : this.state.image64 !== null &&
                            this.state.currentFileTypeofData ===
                              "application/pdf" ? (
                            <>
                              <div
                                onClick={this.removeImage}
                                className="pointer"
                              >
                                <i className="far fa-file-pdf fa-3x pb-10" />
                                <i
                                  className="fa fa-times-circle preview-close-icon fa-lg"
                                  style={{ color: "#20A84C" }}
                                />
                              </div>
                              <p style={{ marginTop: "10px" }}>
                                {this.state.currentFileNameofData}
                              </p>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.image64 == null && (
                            <>
                              <label>
                                Only these file types are accepted: x-png, png,
                                jpg, jpeg, gif, pdf
                              </label>
                              <Dropzone
                                name="dropzone1"
                                onDrop={this.handleOnDropImage}
                                maxSize={1e7}
                                thumbnailWidth={300}
                                thumbnailHeight={300}
                              >
                                {({
                                  getRootProps,
                                  getInputProps,
                                  isDragActive,
                                }) => (
                                  <section>
                                    <div
                                      className="text-center"
                                      {...getRootProps()}
                                    >
                                      <input
                                        ref={this.fileUploadInput}
                                        name="myFiles"
                                        {...getInputProps()}
                                      />
                                      <img
                                        src={img}
                                        className="height_7v mtb-10"
                                        alt="Download"
                                      />
                                      {isDragActive ? (
                                        <h5 className="mb_unset">
                                          Drop Image/PDF File Here!
                                        </h5>
                                      ) : (
                                        <h5 className="mb_unset">
                                          Take a screenshot of the transaction /
                                          deposit slip and drop here
                                          <br /> (Size should be less than 2
                                          mbs){" "}
                                        </h5>
                                      )}
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="row col_margin mt-40 mb-40 ">
                  <div
                    className="d-flex justify-content-end mt-4 mb-4"
                    style={{ width: "95%" }}
                  >
                    <button
                      type="button"
                      className="btn admin_btn_default bold  float-right  text-capitalize"
                      style={{ width: "200px" }}
                      onClick={
                        this.state.updateFormState === "true"
                          ? this.goToNextPage
                          : this.saveToLocalStorage
                      }
                    >
                      save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default withRouter(EngineerPayment);
