// import React from "react";
// import Header from "./Header";
// import { Link } from "react-router-dom";
// import Cookies from "universal-cookie";
// import WebNotif from "../components/WebNotif";
// import Moment from "react-moment";
// import Dropzone from "react-dropzone";
// import { pdf as routes } from "../constants/routes";
// import { connect } from "react-redux";
// import moment from "moment";
// import { submitpayment } from "../redux/user/useraction";
// import Loader from "react-loader-spinner";
// import Modal from "react-modal";
// import { confirmAlert } from "react-confirm-alert";
// import Pdf from "./Pdf";
// import "../assets/css/pdf.css";
// import img from "../assets/image/designer_icon/Upload-files-here.png";
// import jsPDF from "jspdf";
// import Button from "@material-ui/core/Button";
// import html2canvas from "html2canvas";
// import Select from "react-select";

// Modal.setAppElement("#root");
// const acceptedImageFileTypes =
//   "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
// const acceptedImageFileTypesArray = acceptedImageFileTypes
//   .split(",")
//   .map((item) => {
//     return item.trim();
//   });
// const cookies = new Cookies();
// const newarray = [];
// // import $ from 'jquery';
// var obj = new WebNotif();

// class UserPayment extends React.Component {
//   constructor(props) {
//     super(props);
//   }

//   state = {
//     comp: false,
//     loading: false,
//     selectedOption: "",
//     modalIsOpen2: false,
//     image64: null,
//     imageFile: null,
//     userID: this.props.location.state.u_id,
//     userEmail: this.props.location.state.u_email,
//     courseID: this.props.location.state.currentCourse.CourseID,
//     coursename: this.props.location.state.currentCourse.Course_Name,
//     session: this.props.location.state.currentsession,
//     netcourseprice: 0,
//     refno: "",
//     bank: "",
//     bankName: "",
//     selectedbankoption: { value: 0, label: "Bank Challan Payment" },
//     bankoption: [
//       { value: 0, label: "Bank Challan Payment" },
//       { value: 1, label: "Direct Bank Payment" },
//     ],
//   };

//   componentDidMount() {
//     if (this.state.discount !== 0) {
//       this.setState({
//         netcourseprice: this.state.session.price - this.state.session.discount,
//       });
//     }
//   }
//   removeImage = () => {
//     this.setState({ image64: null }, () => {});
//   };
//   handletext = (event) => {
//     this.setState({
//       ...this.state,
//       [event.target.name]: event.target.value,
//     });
//   };
//   bankhandler = (selectedOption) => {
//     this.setState({
//       selectedbankoption: selectedOption,
//     });
//   };
//   printDocument = () => {
//     const input = document.getElementById("Artboard__1");
//     html2canvas(input).then((canvas) => {
//       var imgWidth = 200;
//       var pageHeight = 290;
//       var imgHeight = (canvas.height * imgWidth) / canvas.width;
//       var heightLeft = imgHeight;
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF("p", "mm", "a4");
//       var position = 0;
//       var heightLeft = imgHeight;
//       pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
//       pdf.save("download.pdf");
//     });
//   };
//   closeModal2 = () => {
//     this.setState({
//       modalIsOpen2: false,
//       isEditable: false,
//     });
//   };
//   verifyImageFile = (files) => {
//     if (files && files.length > 0) {
//       const currentFile = files[0];
//       const currentFileType = currentFile.type;
//       const currentFileSize = currentFile.size;
//       if (currentFileSize > 2e6) {
//         obj.createNotification(
//           "error",
//           "Image should be less than 2mbs, Try Again"
//         );
//         return false;
//       }
//       if (!acceptedImageFileTypesArray.includes(currentFileType)) {
//         obj.createNotification(
//           "error",
//           "This file type is not allowed, Only Images are allowed"
//         );

//         return false;
//       }
//       return true;
//     }
//   };
//   handleOnDropImage = (files, rejectedFiles) => {
//     if (rejectedFiles && rejectedFiles.length > 0) {
//       this.verifyImageFile(rejectedFiles);
//     }
//     if (files && files.length > 0) {
//       if (this.verifyImageFile(files)) {
//         const currentFile = files[0];
//         const reader = new FileReader();
//         reader.addEventListener(
//           "load",
//           () => {
//             this.setState({ image64: reader.result, imageFile: currentFile });
//           },
//           false
//         );
//         reader.readAsDataURL(currentFile);

//         obj.createNotification("success", "Image Dropped");
//       }
//     }
//   };

//   handleOptionChange = (changeEvent) => {
//     this.setState({
//       bank: changeEvent.target.value,
//       selectedOption: "BankTransfer",
//     });
//   };

//   checkout = () => {
//     this.setState({ loading: true });
//     if (
//       this.state.imageFile &&
//       (this.state.bank != "" || this.state.bankName != "")
//     ) {
//       console.log("user_Emaillllllllllllllllll", this.state.userEmail);
//       confirmAlert({
//         title: "Confirm to submit",
//         message: "Are you sure to register for this paid session?",
//         buttons: [
//           {
//             label: "Yes",
//             onClick: () => {
//               const fd = new FormData();
//               fd.append("payment_Image", this.state.imageFile);
//               fd.append("selected_Option", this.state.selectedbankoption.label);
//               fd.append("user_ID", this.state.userID);
//               fd.append("user_Email", this.state.userEmail);
//               fd.append("courseID", this.state.courseID);
//               fd.append("refenceNo", this.state.refno);
//               fd.append("bank", this.state.bank);
//               fd.append("bankName", this.state.bankName);
//               fd.append("sessionID", this.state.session.session_id);
//               console.log("Form data fdddd: ", fd);
//               this.props
//                 .submitpayment(fd)
//                 .then((res) => {
//                   this.setState({ loading: false });
//                   obj.createNotification(
//                     "success",
//                     "Payment Submitted, You will be notified via Email on Approval"
//                   );

//                   this.props.history.push("/WebinarSearch/livewebinar");
//                 })
//                 .catch((error) => {
//                   console.log("errorrrrrr" , error.message)
//                   obj.createNotification(
//                     "warning",
//                     "Email wont be send, Email provided is incorrect"
//                   );
//                   this.setState({ loading: false });
//                 });
//               this.props.history.push("/WebinarSearch/livewebinar");
//             },
//           },
//           {
//             label: "No",
//             onClick: () => {
//               this.setState({ loading: false });
//             },
//           },
//         ],
//       });
//     } else {
//       obj.createNotification("error", "select an option and upload a file");
//       this.setState({ loading: false });
//     }
//   };

//   handlePDF = (account, bank) => {
//     newarray.push({ CouseName: this.state.session.Session_Name });
//     newarray.push({ price: this.state.session.price });
//     newarray.push({ Date: this.state.session.Session_StartDate });
//     newarray.push({ Name: this.props.User.FullName });
//     newarray.push({ RegNo: this.props.User.PEC_REG_NO });
//     newarray.push({ account: account });
//     newarray.push({ discipline: this.props.User.discipline });
//     newarray.push({ bank: bank });
//     cookies.set("my", JSON.stringify(newarray));
//     window.location.href = routes;
//   };
//   render() {
//     return (
//       <React.Fragment>
//         <Header />
//         <div className=" mtb-25 payment-container">
//           <h3 className="text-center ptb-30 bold payment_heading">Payment</h3>
//           <div className="container userpayment_container ptb-30">
//             <div className="userpayment_div">
//               <div className="text-left">
//                 Course Name : <b>{this.state.coursename}</b>
//               </div>
//               <div className="text-left">
//                 Session : <b>{this.state.session.Session_Name}</b>
//               </div>
//               <div className="text-left">
//                 Session Price :{" "}
//                 {this.state.netcourseprice !== 0 ? (
//                   <span>
//                     <b>
//                       <span className="linethru">
//                         {" "}
//                         {this.state.session.price}
//                       </span>
//                     </b>
//                     <span className="lrm-5">
//                       <b>{this.state.netcourseprice}</b>
//                     </span>
//                   </span>
//                 ) : (
//                   <b>{this.state.session.price}</b>
//                 )}
//               </div>
//             </div>
//             <div className="userpayment_div">
//               <div className="text-left">
//                 Session Start :{" "}
//                 <b>
//                   <Moment format="ddd Do MMMM YY">
//                     {this.state.session.Session_StartDate}
//                   </Moment>
//                 </b>
//               </div>
//               <div className="text-left">
//                 Session End :{" "}
//                 <b>
//                   <Moment format="ddd Do MMMM YY">
//                     {this.state.session.Session_EndDate}
//                   </Moment>
//                 </b>
//               </div>
//               <div className="text-left">
//                 Session CPD Points : <b>{this.state.session.CPDPoints}</b>
//               </div>
//             </div>
//           </div>
//           {this.state.loading ? (
//             <div className="mtb-50" style={{ textAlign: "center" }}>
//               <Loader type="Oval" color="#06580e" height={80} width={80} />
//             </div>
//           ) : (
//             <div>
//               <div>
//                 <div className="container" style={{ textAlign: "center" }}>
//                   <b style={{ textAlign: "center" }}>Choose Payment Method</b>
//                   <div className="row mtb-15">
//                     <div className="col-md-4"></div>
//                     <div className="col-md-4">
//                       <Select
//                         options={this.state.bankoption}
//                         value={this.state.selectedbankoption}
//                         onChange={this.bankhandler}
//                       />
//                     </div>
//                     <div className="col-md-4"></div>
//                   </div>
//                   {this.state.selectedbankoption.value === 0 ? (
//                     <div className="row mtb-15">
//                       <div className="col-sm"></div>
//                       <div style={{ "text-align": "end" }} className="col-sm">
//                         <label>
//                           <input
//                             type="radio"
//                             name="react-tips"
//                             value="MCB"
//                             checked={this.state.bank === "MCB"}
//                             onChange={this.handleOptionChange}
//                             className="form-check-input"
//                           />
//                           MCB
//                         </label>
//                       </div>
//                       <div className="col-sm" style={{ "text-align": "left" }}>
//                         <label>
//                           <input
//                             type="radio"
//                             name="react-tips"
//                             value="HBL"
//                             checked={this.state.bank === "HBL"}
//                             onChange={this.handleOptionChange}
//                             className="form-check-input"
//                           />
//                           HBL
//                         </label>
//                       </div>
//                       <div className="col-sm"></div>
//                     </div>
//                   ) : (
//                     ""
//                   )}
//                   <div className="row mtb-15">
//                     <div className="col-md-4"></div>
//                     <div className="col-md-4">
//                       <label>
//                         {this.state.selectedbankoption.value === 0
//                           ? "Challan No"
//                           : "Bank Name"}
//                       </label>
//                       <input
//                         type={
//                           this.state.selectedbankoption.value === 0
//                             ? "number"
//                             : "text"
//                         }
//                         name={
//                           this.state.selectedbankoption.value === 0
//                             ? "refno"
//                             : "bankName"
//                         }
//                         value={
//                           this.state.selectedbankoption.value === 0
//                             ? this.state.refno
//                             : this.state.bankName
//                         }
//                         placeholder={
//                           this.state.selectedbankoption.value === 0
//                             ? `00270520000453798`
//                             : "Enter Your Bank Name"
//                         }
//                         className="form-control"
//                         onChange={this.handletext}
//                         required
//                       />
//                     </div>
//                     <div className="col-md-4"></div>
//                   </div>
//                 </div>
//               </div>

//               <div className="container mt-40">
//                 {this.state.bank == "MCB" && (
//                   <div className="row col_margin mtb-20 text_center_mob detailbox">
//                     <div className="col-md-6 bold"> Bank Details </div>
//                     <div className="col-md-6 text-right"> MCB TBD </div>
//                     <div className="col-md-6 bold"> Account Title </div>
//                     <div className="col-md-6 text-right">
//                       {" "}
//                       Pakistan Engineering Council Islamabad{" "}
//                     </div>
//                     <div className="col-md-6 bold"> Ac/No </div>
//                     <div className="col-md-6 text-right">
//                       {" "}
//                       0685583041005497{" "}
//                     </div>
//                     <div className="col-md-6 bold"> Swift Code </div>
//                     <div className="col-md-6 text-right"> </div>
//                     <div className="col-md-6 bold"> Download PDF </div>
//                     <div className="col-md-6 text-right">
//                       {/* <Pdf
//                     courseName={this.state.session.Session_Name}
//                     coursePrice={this.state.session.price}
//                     courseDate={this.state.session.Session_StartDate}
//                     name={this.props.User.FullName}
//                     pecNo={this.props.User.PEC_REG_NO}
//                   /> */}
//                       <button
//                         type="button"
//                         onClick={() =>
//                           this.handlePDF("0685583041005497", "MCB")
//                         }
//                         class="btn btn-success"
//                       >
//                         Generate Bank Deposit Slip
//                       </button>

//                       {/* <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={() => this.handlePDF("0685583041005497","MCB")}
//                   >
//                     Generate Bank Deposit Slip
//                   </Button> */}
//                     </div>
//                   </div>
//                 )}
//                 {this.state.bank == "HBL" && (
//                   <div className="row col_margin mtb-20 text_center_mob detailbox">
//                     <div className="col-md-6 bold"> Bank Details </div>
//                     <div className="col-md-6 text-right"> HBL </div>
//                     <div className="col-md-6 bold"> Account Title </div>
//                     <div className="col-md-6 text-right">
//                       {" "}
//                       Pakistan Engineering Council
//                     </div>
//                     <div className="col-md-6 bold"> Ac/No </div>
//                     <div className="col-md-6 text-right"> 00427901578603 </div>
//                     <div className="col-md-6 bold"> Swift Code </div>
//                     <div className="col-md-6 text-right"> </div>
//                     <div className="col-md-6 bold"> Download PDF </div>
//                     <div className="col-md-6 text-right">
//                       <button
//                         type="button"
//                         onClick={() => this.handlePDF("00427901578603", "HBL")}
//                         class="btn btn-success"
//                       >
//                         Generate Bank Deposit Slip
//                       </button>

//                       {/* <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={() => this.handlePDF("00427901578603","HBL")}
//                   >
//                     Generate Bank Deposit Slip
//                   </Button> */}
//                     </div>
//                   </div>
//                 )}
//                 {this.state.selectedOption == "JazzCash" && (
//                   <div className="row col_margin mtb-20 text_center_mob  detailbox">
//                     <div className="col-md-6 bold"> Account Detail </div>
//                     <div className="col-md-6 text-right"> 12312321 </div>
//                   </div>
//                 )}
//               </div>

//               <div className="container mt-40">
//                 <div className="col-md-6 offset-md-3 file_upload_admin ptb_4v ">
//                   {this.state.image64 !== null ? (
//                     <div
//                       onClick={this.removeImage}
//                       className="pointer"
//                       style={{
//                         paddingLeft: "25px",
//                         marginLeftL: "10px",
//                         color: "#20A84C",
//                         height: "500px",
//                         width: "500px",
//                       }}
//                     >
//                       <img
//                         className=""
//                         style={{
//                           height: "100%",
//                           width: "100%",
//                           objectFit: "cover",
//                         }}
//                         src={this.state.image64}
//                       />
//                       <i
//                         className="fa fa-times-circle preview-close-icon fa-lg"
//                         style={{ color: "#20A84C" }}
//                       />
//                     </div>
//                   ) : (
//                     ""
//                   )}
//                   {this.state.image64 == null && (
//                     <Dropzone
//                       name="dropzone1"
//                       onDrop={this.handleOnDropImage}
//                       maxSize={2 * 1024 * 1024} // 2 MB limit
//                     >
//                       {({ getRootProps, getInputProps, isDragActive }) => (
//                         <section>
//                           <div className="text-center" {...getRootProps()}>
//                             <input name="myFiles" {...getInputProps()} />
//                             <img
//                               src={img}
//                               className="height_7v mtb-10"
//                               alt="Download"
//                             />
//                             {isDragActive ? (
//                               <h5 className="mb_unset">Drop Image Here!</h5>
//                             ) : (
//                               <>
//                               <h5 className="mb_unset">
//                                 Take a screenshot of the transaction /deposit
//                                 slip and drop here{" "}
//                               </h5>
//                               <br />
//                               <p className="text-danger">Note: Files larger than 2 MB cannot be uploaded. </p>
//                               </>
//                             )}
//                           </div>
//                         </section>
//                       )}
//                     </Dropzone>
//                   )}
//                 </div>
//               </div>
//               <div className="row col_margin mt-40">
//                 <div className="col-md-3"></div>
//                 <div className="col-md-6 text-center">
//                   <button
//                     type="button"
//                     class="btn btn-success bold  btn-checkout text-capitalize"
//                     onClick={this.checkout}
//                   >
//                     Submit Payment
//                   </button>
//                 </div>
//                 <div className="col-md-3"></div>
//               </div>
//             </div>
//           )}
//         </div>
//       </React.Fragment>
//     );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     User: state.userData.user,
//   };
// }

// export default connect(mapStateToProps, { submitpayment })(UserPayment);

import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import WebNotif from "../components/WebNotif";
import Moment from "react-moment";
import Dropzone from "react-dropzone";
import { pdf as routes } from "../constants/routes";
import { connect } from "react-redux";
import moment from "moment";
import { submitpayment } from "../redux/user/useraction";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert";
import Pdf from "./Pdf";
import "../assets/css/pdf.css";
import img from "../assets/image/designer_icon/Upload-files-here.png";
import jsPDF from "jspdf";
import Button from "@material-ui/core/Button";
import html2canvas from "html2canvas";
import Select from "react-select";

Modal.setAppElement("#root");
const acceptedImageFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
const acceptedImageFileTypesArray = acceptedImageFileTypes
  .split(",")
  .map((item) => {
    return item.trim();
  });
const cookies = new Cookies();
const newarray = [];
var obj = new WebNotif();

class UserPayment extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    comp: false,
    loading: false,
    selectedOption: "",
    modalIsOpen2: false,
    image64: null,
    imageFile: null,
    userID: this.props.location.state.u_id,
    userEmail: this.props.location.state.u_email,
    courseID: this.props.location.state.currentCourse.CourseID,
    coursename: this.props.location.state.currentCourse.Course_Name,
    session: this.props.location.state.currentsession,
    netcourseprice: 0,
    refno: "",
    bank: "",
    bankName: "",
    challanNo: "", // New state for challan number
    selectedbankoption: { value: 0, label: "Bank Challan Payment" },
    bankoption: [
      { value: 0, label: "Bank Challan Payment" },
      { value: 1, label: "Direct Bank Payment" },
    ],
  };

  componentDidMount() {
    if (this.state.discount !== 0) {
      this.setState({
        netcourseprice: this.state.session.price - this.state.session.discount,
      });
    }
  }
  removeImage = () => {
    this.setState({ image64: null }, () => {});
  };
  handletext = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };
  bankhandler = (selectedOption) => {
    this.setState({
      selectedbankoption: selectedOption,
    });
  };
  printDocument = () => {
    const input = document.getElementById("Artboard__1");
    html2canvas(input).then((canvas) => {
      var imgWidth = 200;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      var position = 0;
      var heightLeft = imgHeight;
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      pdf.save("download.pdf");
    });
  };
  closeModal2 = () => {
    this.setState({
      modalIsOpen2: false,
      isEditable: false,
    });
  };
  verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > 2e6) {
        obj.createNotification(
          "error",
          "Image should be less than 2mbs, Try Again"
        );
        return false;
      }
      if (!acceptedImageFileTypesArray.includes(currentFileType)) {
        obj.createNotification(
          "error",
          "This file type is not allowed, Only Images are allowed"
        );

        return false;
      }
      return true;
    }
  };
  handleOnDropImage = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyImageFile(files)) {
        const currentFile = files[0];
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.setState({ image64: reader.result, imageFile: currentFile });
          },
          false
        );
        reader.readAsDataURL(currentFile);

        obj.createNotification("success", "Image Dropped");
      }
    }
  };

  handleOptionChange = (changeEvent) => {
    this.setState({
      bank: changeEvent.target.value,
      selectedOption: "BankTransfer",
    });
  };

  checkout = () => {
    this.setState({ loading: true });
    if (
      this.state.imageFile &&
      (this.state.bank != "" ||
        this.state.bankName != "" ||
        this.state.challanNo != "")
    ) {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to register for this paid session?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const fd = new FormData();
              fd.append("payment_Image", this.state.imageFile);
              fd.append("selected_Option", this.state.selectedbankoption.label);
              fd.append("user_ID", this.state.userID);
              fd.append("user_Email", this.state.userEmail);
              fd.append("courseID", this.state.courseID);
              fd.append("refenceNo", this.state.refno);
              fd.append("bank", this.state.bank);
              fd.append("bankName", this.state.bankName);
              fd.append("challanNo", this.state.challanNo); // Include challanNo
              fd.append("sessionID", this.state.session.session_id);

              this.props
                .submitpayment(fd)
                .then((res) => {
                  this.setState({ loading: false });
                  obj.createNotification(
                    "success",
                    "Payment Submitted, You will be notified via Email on Approval"
                  );

                  this.props.history.push("/WebinarSearch/livewebinar");
                })
                .catch((error) => {
                  console.log("error", error.message);
                  obj.createNotification(
                    "warning",
                    "Email won't be sent, Email provided is incorrect"
                  );
                  this.setState({ loading: false });
                });
              this.props.history.push("/WebinarSearch/livewebinar");
            },
          },
          {
            label: "No",
            onClick: () => {
              this.setState({ loading: false });
            },
          },
        ],
      });
    } else {
      obj.createNotification("error", "select an option and upload a file");
      this.setState({ loading: false });
    }
  };

  handlePDF = (account, bank) => {
    newarray.push({ CouseName: this.state.session.Session_Name });
    newarray.push({ price: this.state.session.price });
    newarray.push({ Date: this.state.session.Session_StartDate });
    newarray.push({ Name: this.props.User.FullName });
    newarray.push({ RegNo: this.props.User.PEC_REG_NO });
    newarray.push({ account: account });
    newarray.push({ discipline: this.props.User.discipline });
    newarray.push({ bank: bank });
    cookies.set("my", JSON.stringify(newarray));
    // window.location.href = routes;
    this.props.history.push(routes);
  };
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className=" mtb-25 payment-container">
          <h3 className="text-center ptb-30 bold payment_heading">
            Webinar Payment
          </h3>
          <div className="container userpayment_container ptb-30">
            <div className="userpayment_div">
              <div className="text-left">
                Webinar Name : <b>{this.state.coursename}</b>
              </div>
              <div className="text-left">
                Session : <b>{this.state.session.Session_Name}</b>
              </div>
              <div className="text-left">
                Session Price :{" "}
                {this.state.netcourseprice !== 0 ? (
                  <span>
                    <b>
                      <span className="linethru">
                        {" "}
                        {this.state.session.price}
                      </span>
                    </b>
                    <span className="lrm-5">
                      <b>{this.state.netcourseprice}</b>
                    </span>
                  </span>
                ) : (
                  <b>{this.state.session.price}</b>
                )}
              </div>
            </div>
            <div className="userpayment_div">
              <div className="text-left">
                Session Start :{" "}
                <b>
                  <Moment format="ddd Do MMMM YYYY">
                    {this.state.session.Session_StartDate}
                  </Moment>
                </b>
              </div>
              <div className="text-left">
                Session End :{" "}
                <b>
                  <Moment format="ddd Do MMMM YYYY">
                    {this.state.session.Session_EndDate}
                  </Moment>
                </b>
              </div>
              <div className="text-left">
                Session CPD Points : <b>{this.state.session.CPDPoints}</b>
              </div>
            </div>
          </div>
          {this.state.loading ? (
            <div className="mtb-50" style={{ textAlign: "center" }}>
              <Loader type="Oval" color="#06580e" height={80} width={80} />
            </div>
          ) : (
            <div>
              <div>
                <div className="container" style={{ textAlign: "center" }}>
                  <b style={{ textAlign: "center" }}>Choose Payment Method</b>
                  <div className="row mtb-15">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <Select
                        options={this.state.bankoption}
                        value={this.state.selectedbankoption}
                        onChange={this.bankhandler}
                      />
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                  {this.state.selectedbankoption.value === 0 ? (
                    <div className="row mtb-15">
                      <div className="col-sm"></div>
                      <div style={{ textAlign: "end" }} className="col-sm">
                        <label>
                          <input
                            type="radio"
                            name="react-tips"
                            value="MCB"
                            checked={this.state.bank === "MCB"}
                            onChange={this.handleOptionChange}
                            className="form-check-input"
                          />
                          MCB
                        </label>
                      </div>
                      <div className="col-sm" style={{ textAlign: "left" }}>
                        <label>
                          <input
                            type="radio"
                            name="react-tips"
                            value="HBL"
                            checked={this.state.bank === "HBL"}
                            onChange={this.handleOptionChange}
                            className="form-check-input"
                          />
                          HBL
                        </label>
                      </div>
                      <div className="col-sm"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row mtb-15">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <label>
                        {this.state.selectedbankoption.value === 0
                          ? "Challan No"
                          : "Bank Name"}
                      </label>
                      <input
                        type={
                          this.state.selectedbankoption.value === 0
                            ? "number"
                            : "text"
                        }
                        name={
                          this.state.selectedbankoption.value === 0
                            ? "challanNo"
                            : "bankName"
                        } // Updated name
                        value={
                          this.state.selectedbankoption.value === 0
                            ? this.state.challanNo
                            : this.state.bankName
                        } // Updated value
                        placeholder={
                          this.state.selectedbankoption.value === 0
                            ? `00270520000453798`
                            : "Enter Your Bank Name"
                        }
                        className="form-control"
                        onChange={this.handletext}
                        required
                      />
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
              </div>

              <div className="container mt-40">
                {this.state.bank == "MCB" && (
                  <div className="row col_margin mtb-20 text_center_mob detailbox">
                    <div className="col-md-6 bold"> Bank Details </div>
                    <div className="col-md-6 text-right"> MCB TBD </div>
                    <div className="col-md-6 bold"> Account Title </div>
                    <div className="col-md-6 text-right">
                      {" "}
                      Pakistan Engineering Council Islamabad{" "}
                    </div>
                    <div className="col-md-6 bold"> Ac/No </div>
                    <div className="col-md-6 text-right">
                      {" "}
                      0685583041005497{" "}
                    </div>
                    <div className="col-md-6 bold"> Swift Code </div>
                    <div className="col-md-6 text-right"> </div>
                    <div className="col-md-6 bold"> Download PDF </div>
                    <div className="col-md-6 text-right">
                      <button
                        type="button"
                        onClick={() =>
                          this.handlePDF("0685583041005497", "MCB")
                        }
                        class="btn btn-success"
                      >
                        Generate Bank Deposit Slip
                      </button>
                    </div>
                  </div>
                )}
                {this.state.bank == "HBL" && (
                  <div className="row col_margin mtb-20 text_center_mob detailbox">
                    <div className="col-md-6 bold"> Bank Details </div>
                    <div className="col-md-6 text-right"> HBL </div>
                    <div className="col-md-6 bold"> Account Title </div>
                    <div className="col-md-6 text-right">
                      {" "}
                      Pakistan Engineering Council
                    </div>
                    <div className="col-md-6 bold"> Ac/No </div>
                    <div className="col-md-6 text-right"> 00427901578603 </div>
                    <div className="col-md-6 bold"> Swift Code </div>
                    <div className="col-md-6 text-right"> </div>
                    <div className="col-md-6 bold"> Download PDF </div>
                    <div className="col-md-6 text-right">
                      <button
                        type="button"
                        onClick={() => this.handlePDF("00427901578603", "HBL")}
                        class="btn btn-success"
                      >
                        Generate Bank Deposit Slip
                      </button>
                    </div>
                  </div>
                )}
                {this.state.selectedOption == "JazzCash" && (
                  <div className="row col_margin mtb-20 text_center_mob  detailbox">
                    <div className="col-md-6 bold"> Account Detail </div>
                    <div className="col-md-6 text-right"> 12312321 </div>
                  </div>
                )}
              </div>

              <div className="container mt-40">
                <div className="col-md-6 offset-md-3 file_upload_admin ptb_4v ">
                  {this.state.image64 !== null ? (
                    <div
                      onClick={this.removeImage}
                      className="pointer"
                      style={{
                        paddingLeft: "25px",
                        marginLeftL: "10px",
                        color: "#20A84C",
                        height: "500px",
                        width: "500px",
                      }}
                    >
                      <img
                        className=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        src={this.state.image64}
                      />
                      <i
                        className="fa fa-times-circle preview-close-icon fa-lg"
                        style={{ color: "#20A84C" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.image64 == null && (
                    <Dropzone
                      name="dropzone1"
                      onDrop={this.handleOnDropImage}
                      maxSize={2 * 1024 * 1024} // 2 MB limit
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <section>
                          <div className="text-center" {...getRootProps()}>
                            <input name="myFiles" {...getInputProps()} />
                            <img
                              src={img}
                              className="height_7v mtb-10"
                              alt="Download"
                            />
                            {isDragActive ? (
                              <h5 className="mb_unset">Drop Image Here!</h5>
                            ) : (
                              <>
                                <h5 className="mb_unset">
                                  Take a screenshot of the transaction /deposit
                                  slip and drop here{" "}
                                </h5>
                                <br />
                                <p className="text-danger">
                                  Note: Files larger than 2 MB cannot be
                                  uploaded.{" "}
                                </p>
                              </>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  )}
                </div>
              </div>
              <div className="row col_margin mt-40">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <button
                    type="button"
                    class="btn btn-success bold  btn-checkout text-capitalize"
                    onClick={this.checkout}
                  >
                    Submit Payment
                  </button>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    User: state.userData.user,
  };
}

export default connect(mapStateToProps, { submitpayment })(UserPayment);
