// import React, { Component } from "react";
// import { confirmAlert } from "react-confirm-alert"; // Import
// import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import Collapsible from "react-collapsible";
// import Feedback_Webinar from "./Feedback_Webinar";
// import WebNotif from "../WebNotif";
// import { connect } from "react-redux";
// import {
//   delete_faq,
//   edit_faq,
//   add_faq,
//   get_faqs,
//   saveWebinarDraft,
// } from "../../redux/admin/webinar/webinarActions";
// var obj = new WebNotif();

// const byPropKey = (propertyName, value) => () => ({
//   [propertyName]: value,
// });

// class Addfaq extends Component {
//   constructor(props) {
//     super(props);
//     this.focusInput = React.createRef();
//   }
//   state = {
//     faqs: [],
//     test: "",
//     question: "",
//     answer: "",
//     showLoader: true,
//     id: "",
//     c_id: "",
//     update: false,
//     className: "down",
//   };

//   UNSAFE_componentWillMount() {
//     if (this.props.isEdit == false) {
//       if (this.props.webinarDetails.insertId) {
//         this.props.get_faqs(this.props.webinarDetails.insertId);
//       } else {
//       }
//     }
//   }
//   // componentDidMount() {
//   //   console.log(this.props.User.RoleID)
//   // }
//   validateForm = (data) => {
//     var obj = new WebNotif();
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].value == null || data[i].value == "") {
//         obj.createNotification("error", data[i].slug + " is required");
//         return false;
//         break;
//       }
//     }
//     return true;
//   };

//   handleChange1 = (event) => {
//     this.setState({
//       ...this.state,
//       [event.target.name]: event.target.value.toUpperCase(),
//     });
//   };

//   addFaq = () => {
//     1;

//     if (this.state.question !== "" && this.state.answer !== "") {
//       if (this.props.isEdit == true) {
//         const data = {
//           question: this.state.question,
//           answer: this.state.answer,
//         };
//         this.props.add_faq({
//           data: data,
//           id:
//             this.props.currentWebinarData &&
//             this.props.currentWebinarData.CourseID,
//         });
//         obj.createNotification("success", "Successfully Added FAQ");

//         this.setState({
//           update: false,
//           question: "",
//           answer: "",
//         });
//       } else {
//         if (this.props.webinarDetails.insertId) {
//           const data = {
//             question: this.state.question,
//             answer: this.state.answer,
//           };

//           this.props.add_faq({
//             data: data,
//             id: this.props.webinarDetails.insertId,
//           });

//           obj.createNotification("success", "Successfully Added FAQ");

//           this.setState({
//             update: false,
//             question: "",
//             answer: "",
//           });
//         } else {
//           obj.createNotification("error", "Save Webinar Details first");
//         }
//       }
//     } else {
//       obj.createNotification("error", "Fill Question and answer");
//     }
//   };
//   deleteFaq = (item) => {
//     confirmAlert({
//       title: "Confirm to submit",
//       message: "Are you sure to do this.",
//       buttons: [
//         {
//           label: "Yes",
//           onClick: () => {
//             var obj = new WebNotif();
//             const data = { c_id: item.course_id, faq_id: item.faq_id };
//             this.props.delete_faq(data);
//             obj.createNotification("success", "faq is deleted");
//           },
//         },
//         {
//           label: "No",
//           onClick: () => {},
//         },
//       ],
//     });
//   };
//   update_faq = () => {
//     var obj = new WebNotif();
//     const data = {
//       c_id: this.state.c_id,
//       faq_id: this.state.id,
//       question: this.state.question,
//       answer: this.state.answer,
//     };
//     this.props.edit_faq(data);
//     obj.createNotification("success", "Faq Edited");
//     this.setState({
//       update: false,
//       question: "",
//       answer: "",
//     });
//   };
//   edit = (item) => {
//     this.focusInput.current.focus();
//     this.setState(
//       {
//         c_id: item.course_id,
//         id: item.faq_id,
//         question: item.question,
//         answer: item.answer,
//         update: true,
//       },
//       () => {}
//     );
//   };

//   accordionOpen = () => {
//     this.setState({ className: "up" });
//   };

//   accordionClose = () => {
//     this.setState({ className: "down" });
//   };

//   saveForm = () => {
//     if (this.state.faqs.length > 0) {
//       if (this.props.isEdit == true) {
//         this.props.setEditFaqs(this.state.faqs);
//         obj.createNotification(
//           "success",
//           "Webinar successfully added to draft"
//         );
//       } else {
//         if (this.props.flag == true) {
//           this.props.setEditFaqs(this.state.faqs);
//           obj.createNotification(
//             "success",
//             "Webinar successfully added to draft"
//           );
//         } else {
//           this.props.setFaqs(this.state.faqs);
//           obj.createNotification(
//             "success",
//             "Webinar successfully added to draft"
//           );
//         }
//       }
//     } else {
//       obj.createNotification("error", "Add An FAQ");
//     }
//   };
//   saveLinks = () => {
//     if (this.props.currentWebinarData === null && this.props.isEdit === false) {
//       obj.createNotification("success", "Webinar successfully added to draft");
//     } else {
//       if (this.props.currentWebinarData === null) {
//         obj.createNotification("error", "Cannot save empty webinar");
//       } else {
//         this.props
//           .saveWebinarDraft(
//             this.props.currentWebinarData &&
//               this.props.currentWebinarData.CourseID
//           )
//           .then((res) => {
//             obj.createNotification(
//               "success",
//               "Webinar successfully added to draft"
//             );
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//       }
//     }
//   };
//   publishWebinar = () => {
//     this.props.publishWebinar();
//   };
//   // savedraft =()=>{
//   //   this.props.savedraft();
//   // }

//   render() {
//     return (
//       <React.Fragment>
//         {this.state.redirect ? (
//           "<Unauthorized />"
//         ) : (
//           <React.Fragment>
//             <section>
//               <div className=" col-md-12">
//                 <div className="row">
//                   <div className="col-md-6">
//                     <h5 className="card-title regular_font">FAQs</h5>
//                     {/* <div className="col lrp-0">
//                       <span>
//                         <b>Course title:</b>
//                         {this.state.courseTitle != undefined
//                           ? " (" + this.state.courseTitle + ")"
//                           : ""}
//                       </span>
//                     </div> */}
//                   </div>
//                   <div className="col-md-6 lrp-0 text-right">
//                     &nbsp; &nbsp;
//                     {this.props.User.RoleID === 7 ||
//                     this.props.User.RoleID === 3 ||
//                     this.props.User.RoleID === 4 ||
//                     this.props.User.RoleID === 5 ||
//                     this.props.User.RoleID === 8 ? (
//                       <button
//                         className="btn btn-save bold  float right"
//                         onClick={this.saveLinks}
//                       >
//                         Save Draft
//                       </button>
//                     ) : (
//                       ""
//                     )}
//                     {this.props.User.RoleID === 8 ||
//                     this.props.User.RoleID === 2 ||
//                     this.props.User.RoleID === 4 ? (
//                       ""
//                     ) : this.props.isEdit == false ? (
//                       this.props.permissions.Publish ? (
//                         <button
//                           className="btn btn-save bold  float right"
//                           onClick={this.publishWebinar}
//                         >
//                           Publish The Webinar
//                         </button>
//                       ) : (
//                         ""
//                       )
//                     ) : (
//                       ""
//                     )}
//                     {/* {(this.props.User.RoleID !== 8 || this.props.User.RoleID !== 2 || this.props.User.RoleID !== 4) &&
//                         (this.props.isEdit == false) ?
//                         (
//                           <button
//                             className="btn btn-save bold  float right"
//                             onClick={this.publishWebinar}
//                           >
//                             Publish The Webinar
//                           </button>) : ""
//                       } */}
//                   </div>
//                 </div>
//               </div>
//               <hr />
//             </section>

//             <section>
//               <div className="row">
//                 <div
//                   className="col-sm-12 lrp-0"
//                   tabindex="0"
//                   ref={this.focusInput}
//                 >
//                   <div className="card  br-7 p-10">
//                     <div className="row">
//                       <div className="col-md-12">
//                         <div className="input-group">
//                           <div className="input-group-prepend icon-adjustment">
//                             <i className="fa fa-align-justify" />
//                           </div>
//                           <input
//                             type="text"
//                             className="form-control no_border fs-14 faq_add_question"
//                             name="question"
//                             placeholder="Enter Your Question Here"
//                             value={this.state.question}
//                             onChange={this.handleChange1}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <hr className="mt-0_5" />
//                     <div className="col-md-12">
//                       <textarea
//                         className="form-control no_border fs-14"
//                         rows="2"
//                         name="answer"
//                         placeholder="Type your answer here"
//                         value={this.state.answer}
//                         onChange={this.handleChange1}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <br />
//               <div className="col-md-12">
//                 <div className="row">
//                   {this.state.update == false ? (
//                     <div className="col-md-12  text-left">
//                       <button
//                         className="btn btn-save fix_width_btn   bold fz_14"
//                         onClick={this.addFaq}
//                       >
//                         Save FAQ
//                       </button>
//                     </div>
//                   ) : (
//                     <div className="col-md-12  text-left">
//                       <button
//                         className="btn btn-save fix_width_btn   bold fz_14"
//                         onClick={this.update_faq}
//                       >
//                         Update FAQ
//                       </button>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </section>

//             <div className="row bm-20">
//               <div className="col-md-12">
//                 <div className="faq-container">
//                   {this.props.faqs && this.props.faqs.length > 0
//                     ? this.props.faqs.map((item, key) => (
//                         <Collapsible
//                           key={key}
//                           transitionTime={100}
//                           onOpen={() => this.accordionOpen}
//                           onClose={() => this.accordionClose}
//                           trigger={
//                             <div className="row pt-7 ">
//                               <div className="col-md-11 text-left ">
//                                 <i className="fa fa-align-justify " />

//                                 <span className="faq-collapsible-question ">
//                                   {item.question}
//                                 </span>
//                               </div>
//                               <div className="col-md-1 text-center">
//                                 <button
//                                   className="cancel-file-btn prp-5"
//                                   onClick={() => this.edit(item)}
//                                 >
//                                   <i className="fas fa-align-justify co_g20A84C  fa-edit">
//                                     {" "}
//                                   </i>
//                                 </button>

//                                 <button
//                                   className="cancel-file-btn"
//                                   onClick={() => this.deleteFaq(item)}
//                                 >
//                                   <i className="fas fa-align-justify co_g20A84C  fa-trash ">
//                                     {" "}
//                                   </i>
//                                 </button>
//                               </div>
//                             </div>
//                           }
//                         >
//                           <p>{item.answer}</p>
//                         </Collapsible>
//                       ))
//                     : "No FAQS Availabe"}
//                 </div>
//               </div>
//             </div>
//             {this.props.isEdit == true &&
//             (this.props.User.RoleID === 8 ||
//               this.props.User.RoleID === 7 ||
//               this.props.User.RoleID === 3 ||
//               this.props.User.RoleID === 4 ||
//               this.props.User.RoleID === 5) ? (
//               <Feedback_Webinar
//                 webinarId={
//                   this.props.currentWebinarData &&
//                   this.props.currentWebinarData.CourseID
//                 }
//                 owner={
//                   this.props.currentWebinarData &&
//                   this.props.currentWebinarData.user_id
//                 }
//               />
//             ) : (
//               ""
//             )}

//             {/* <div className="row">
//                 <div className="col-md-12">
//                 <div className="faq-container">
//                   {this.state.faqs == null
//                     ? <h3>No Faqs Added</h3>
//                     : this.state.faqs.map((item,key) => (
//                       <Collapsible 
//                         key={key}
//                         transitionTime={100}
//                         onOpen={()=>this.accordionOpen}
//                         onClose={()=>this.accordionClose}
//                         trigger={
//                         <div className="row">
//                             <div className="col-md-1 ">
//                               <i className="fa fa-align-justify "/>
//                             </div>
//                             <div className="col-md-10 lrp-0"><span className="faq-collapsible-question" >{item.question}</span> </div>  
                               
                            
//                             <div className="col-md-1 text-center">
//                               <i className="fas fa-align-justify  fa-caret-down"> </i> 
//                               </div>

//                           </div>}>
//                         <p className="faq-collapsible-question">{item.answer}</p>
//                       </Collapsible>
//                       ))}    

                         
//                     </div>
//                     </div>
//                 </div>
//              <div className="col-md-12 text-right">
//              <button onClick={this.saveForm}className="btn btn-save  fix_width_btn fz_16 black bold">
//                      {this.props.isEdit==true ? "Save" : "Save"} 
//                     </button>
//                     </div>                */}
//           </React.Fragment>
//         )}
//       </React.Fragment>
//     );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     faqs: state.webinarData.webinarFaqs,
//     flag: state.webinarData.FirstTimeSave.faqs,
//     webinarDetails: state.webinarData.webinarDetails,
//     User: state.userData.admin,
//   };
// }

// export default connect(mapStateToProps, {
//   delete_faq,
//   edit_faq,
//   add_faq,
//   get_faqs,
//   saveWebinarDraft,
// })(Addfaq);


import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Collapsible from "react-collapsible";
import Feedback_Webinar from "./Feedback_Webinar";
import WebNotif from "../WebNotif";
import { connect } from "react-redux";
import {
  delete_faq,
  edit_faq,
  add_faq,
  get_faqs,
  saveWebinarDraft,
} from "../../redux/admin/webinar/webinarActions";
var obj = new WebNotif();

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class Addfaq extends Component {
  constructor(props) {
    super(props);
    this.focusInput = React.createRef();
  }
  state = {
    faqs: [],
    test: "",
    question: "",
    answer: "",
    showLoader: true,
    id: "",
    c_id: "",
    update: false,
    className: "down",
    charCount: 0,
    charLimit: 500,
  };

  UNSAFE_componentWillMount() {
    if (this.props.isEdit == false) {
      if (this.props.webinarDetails.insertId) {
        this.props.get_faqs(this.props.webinarDetails.insertId);
      } else {
      }
    }
  }

  validateForm = (data) => {
    var obj = new WebNotif();
    for (let i = 0; i < data.length; i++) {
      if (data[i].value == null || data[i].value == "") {
        obj.createNotification("error", data[i].slug + " is required");
        return false;
        break;
      }
    }
    return true;
  };

  handleChange1 = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value.toUpperCase(),
      charCount: name === "answer" ? value.length : this.state.charCount,
    });
  };

  addFaq = () => {
    if (this.state.question !== "" && this.state.answer !== "") {
      if (this.props.isEdit == true) {
        const data = {
          question: this.state.question,
          answer: this.state.answer,
        };
        this.props.add_faq({
          data: data,
          id:
            this.props.currentWebinarData &&
            this.props.currentWebinarData.CourseID,
        });
        obj.createNotification("success", "Successfully Added FAQ");

        this.setState({
          update: false,
          question: "",
          answer: "",
          charCount: 0,
        });
      } else {
        if (this.props.webinarDetails.insertId) {
          const data = {
            question: this.state.question,
            answer: this.state.answer,
          };

          this.props.add_faq({
            data: data,
            id: this.props.webinarDetails.insertId,
          });

          obj.createNotification("success", "Successfully Added FAQ");

          this.setState({
            update: false,
            question: "",
            answer: "",
            charCount: 0,
          });
        } else {
          obj.createNotification("error", "Save Webinar Details first");
        }
      }
    } else {
      obj.createNotification("error", "Fill Question and answer");
    }
  };

  deleteFaq = (item) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var obj = new WebNotif();
            const data = { c_id: item.course_id, faq_id: item.faq_id };
            this.props.delete_faq(data);
            obj.createNotification("success", "faq is deleted");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  update_faq = () => {
    var obj = new WebNotif();
    const data = {
      c_id: this.state.c_id,
      faq_id: this.state.id,
      question: this.state.question,
      answer: this.state.answer,
    };
    this.props.edit_faq(data);
    obj.createNotification("success", "Faq Edited");
    this.setState({
      update: false,
      question: "",
      answer: "",
      charCount: 0,
    });
  };

  edit = (item) => {
    this.focusInput.current.focus();
    this.setState(
      {
        c_id: item.course_id,
        id: item.faq_id,
        question: item.question,
        answer: item.answer,
        update: true,
        charCount: item.answer.length,
      },
      () => {}
    );
  };

  accordionOpen = () => {
    this.setState({ className: "up" });
  };

  accordionClose = () => {
    this.setState({ className: "down" });
  };

  saveForm = () => {
    if (this.state.faqs.length > 0) {
      if (this.props.isEdit == true) {
        this.props.setEditFaqs(this.state.faqs);
        obj.createNotification(
          "success",
          "Webinar successfully added to draft"
        );
      } else {
        if (this.props.flag == true) {
          this.props.setEditFaqs(this.state.faqs);
          obj.createNotification(
            "success",
            "Webinar successfully added to draft"
          );
        } else {
          this.props.setFaqs(this.state.faqs);
          obj.createNotification(
            "success",
            "Webinar successfully added to draft"
          );
        }
      }
    } else {
      obj.createNotification("error", "Add An FAQ");
    }
  };

  saveLinks = () => {
    if (this.props.currentWebinarData === null && this.props.isEdit === false) {
      obj.createNotification("success", "Webinar successfully added to draft");
    } else {
      if (this.props.currentWebinarData === null) {
        obj.createNotification("error", "Cannot save empty webinar");
      } else {
        this.props
          .saveWebinarDraft(
            this.props.currentWebinarData &&
              this.props.currentWebinarData.CourseID
          )
          .then((res) => {
            obj.createNotification(
              "success",
              "Webinar successfully added to draft"
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  publishWebinar = () => {
    this.props.publishWebinar();
  };

  render() {
    const { charCount, charLimit } = this.state;

    return (
      <React.Fragment>
        {this.state.redirect ? (
          "<Unauthorized />"
        ) : (
          <React.Fragment>
            <section>
              <div className=" col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="card-title regular_font">FAQs</h5>
                  </div>
                  <div className="col-md-6 lrp-0 text-right">
                    &nbsp; &nbsp;
                    {this.props.User.RoleID === 7 ||
                    this.props.User.RoleID === 3 ||
                    this.props.User.RoleID === 4 ||
                    this.props.User.RoleID === 5 ||
                    this.props.User.RoleID === 8 ? (
                      <button
                        className="btn btn-save bold  float right"
                        onClick={this.saveLinks}
                      >
                        Save Draft
                      </button>
                    ) : (
                      ""
                    )}
                    {this.props.User.RoleID === 8 ||
                    this.props.User.RoleID === 2 ||
                    this.props.User.RoleID === 4 ? (
                      ""
                    ) : this.props.isEdit == false ? (
                      this.props.permissions.Publish ? (
                        <button
                          className="btn btn-save bold  float right"
                          onClick={this.publishWebinar}
                        >
                          Publish The Webinar
                        </button>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <hr />
            </section>

            <section>
              <div className="row">
                <div
                  className="col-sm-12 lrp-0"
                  tabindex="0"
                  ref={this.focusInput}
                >
                  <div className="card  br-7 p-10">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-group">
                          <div className="input-group-prepend icon-adjustment">
                            <i className="fa fa-align-justify" />
                          </div>
                          <input
                            type="text"
                            className="form-control no_border fs-14 faq_add_question"
                            name="question"
                            placeholder="Enter Your Question Here"
                            value={this.state.question}
                            onChange={this.handleChange1}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="mt-0_5" />
                    <div className="col-md-12">
                      <textarea
                        className="form-control no_border fs-14"
                        rows="2"
                        name="answer"
                        placeholder="Type your answer here"
                        value={this.state.answer}
                        onChange={this.handleChange1}
                        maxLength={500}
                      />
                      <div className="char-count">
                        {charCount} / {charLimit} characters
                      </div>
                      {charCount >= charLimit && (
                        <div className="char-limit-warning" style={{ color: "red" }}>
                          You have reached the character limit of {charLimit}.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-12">
                <div className="row">
                  {this.state.update == false ? (
                    <div className="col-md-12  text-left">
                      <button
                        className="btn btn-save fix_width_btn   bold fz_14"
                        onClick={this.addFaq}
                      >
                        Save FAQ
                      </button>
                    </div>
                  ) : (
                    <div className="col-md-12  text-left">
                      <button
                        className="btn btn-save fix_width_btn   bold fz_14"
                        onClick={this.update_faq}
                      >
                        Update FAQ
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </section>

            <div className="row bm-20">
              <div className="col-md-12">
                <div className="faq-container">
                  {this.props.faqs && this.props.faqs.length > 0
                    ? this.props.faqs.map((item, key) => (
                        <Collapsible
                          key={key}
                          transitionTime={100}
                          onOpen={() => this.accordionOpen}
                          onClose={() => this.accordionClose}
                          trigger={
                            <div className="row pt-7 ">
                              <div className="col-md-11 text-left ">
                                <i className="fa fa-align-justify " />

                                <span className="faq-collapsible-question ">
                                  {item.question}
                                </span>
                              </div>
                              <div className="col-md-1 text-center">
                                <button
                                  className="cancel-file-btn prp-5"
                                  onClick={() => this.edit(item)}
                                >
                                  <i className="fas fa-align-justify co_g20A84C  fa-edit">
                                    {" "}
                                  </i>
                                </button>

                                <button
                                  className="cancel-file-btn"
                                  onClick={() => this.deleteFaq(item)}
                                >
                                  <i className="fas fa-align-justify co_g20A84C  fa-trash ">
                                    {" "}
                                  </i>
                                </button>
                              </div>
                            </div>
                          }
                        >
                          <p>{item.answer}</p>
                        </Collapsible>
                      ))
                    : "No FAQS Availabe"}
                </div>
              </div>
            </div>
            {this.props.isEdit == true &&
            (this.props.User.RoleID === 8 ||
              this.props.User.RoleID === 7 ||
              this.props.User.RoleID === 3 ||
              this.props.User.RoleID === 4 ||
              this.props.User.RoleID === 5) ? (
              <Feedback_Webinar
                webinarId={
                  this.props.currentWebinarData &&
                  this.props.currentWebinarData.CourseID
                }
                owner={
                  this.props.currentWebinarData &&
                  this.props.currentWebinarData.user_id
                }
              />
            ) : (
              ""
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    faqs: state.webinarData.webinarFaqs,
    flag: state.webinarData.FirstTimeSave.faqs,
    webinarDetails: state.webinarData.webinarDetails,
    User: state.userData.admin,
  };
}

export default connect(mapStateToProps, {
  delete_faq,
  edit_faq,
  add_faq,
  get_faqs,
  saveWebinarDraft,
})(Addfaq);
