import React from "react";
import { Link } from "react-router-dom";
import image from "../assets/image/16thepe.jpg";
import { connect } from "react-redux";
import { logout } from "../redux/user/useraction";
import "../assets/css/hover.css";
import img1 from "../assets/image/pec_logo.jpg";
import img2 from "../assets/image/PEC.png";
import epeexam from "../assets/image/17thEPEAd.91a59e02.jpg";
// import "../assets/css/main.css";
import $ from "jquery";
import { withRouter } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "none",
    };
  }
  logout = () => {
    this.props.logout();
    window.location.reload();
  };
  profile = () => {
    window.location.href = "/cpd/CpdFormProfile";
  };
  componentDidMount() {}

  block = () => {
    this.setState({ display: "block" });
  };
  none = () => {
    this.setState({ display: "none" });
  };
  render() {
    return (
      <React.Fragment>
        <div className="backco_white">
          <div className="row col_margin backco_white">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 flex_center">
              <a href="https://www.pec.org.pk/">
                <img src={img1} alt="LOGO" className="img_head" />
              </a>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 flex_center">
              <Link to="/">
                <h5 className="Conti_Prof pt_2v mb_uns ">
                  Continuing Professional Development
                </h5>
              </Link>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 flex_end  pt_2v pb_2v pt_4_5v">
              <img
                src={img2}
                alt="LOGO"
                className="pecxlogo"
                style={{ width: "30%", height: "auto" }}
              />
            </div>
          </div>

          <div className="container-fluid backco_green containe_unset">
            <nav className="navbar menuNav navbar-expand-lg navbar-dark col_padding">
              <Link
                className="navbar-brand Log_none"
                to="/"
                style={{ fontWeight: "bold", color: "green", fontSize: "3rem" }}
              >
                {" "}
              </Link>
              <button
                className="navbar-toggler custom-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExample03"
                aria-controls="navbarsExample03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span
                  className="navbar-toggler-icon"
                  style={{ height: "50px", width: "60px" }}
                />
              </button>
              <div
                className="collapse navbar-collapse nav_zind backco_green "
                id="navbarsExample03"
              >
                {this.props.User ? (
                  <a onClick={this.profile} className="logoutbtn ">
                    <i className="fas fa-user"></i> User
                  </a>
                ) : (
                  " "
                )}
                <ul className="navbar-nav  backco_green">
                  <li className="nav-item active  padding_un mlr_10 font_sans fw_sans8">
                    <li className="nav-item dropdown  active">
                      <Link
                        className="nav-link hvr-underline-from-center headerline dropdown-toggle mb_uns  padding_un mlr_10 co_white fz_16 pt_10I"
                        to="#"
                        id="navbarDropdown"
                      >
                        ABOUT CPD
                      </Link>
                      <div
                        className="dropdown-menu  "
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/"
                        >
                          Home
                        </Link>
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/Introduction"
                        >
                          Introduction to CPD
                        </Link>
                        {/* <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/CPDrecognition">CPD Recognition of Foregin Institutions</Link> */}
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/byelaws"
                        >
                          CPD Bye-Laws 2008 (amended upto October 31, 2017)
                        </Link>

                        {/* <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="GuideLineMauals">CPD GuideLine Manual</Link> */}
                        <li className="dropdown-submenu">
                          {/* onMouseEnter={this.block}  style={{display:this.state.display}}*/}
                          <Link
                            className="dropdown-item co_white font_sans fw_sans8 fz_12 dropdown-toggle"
                            to="#"
                            id="navbarDropdown111"
                          >
                            Engineering Professional Development Committee
                            (EPDC)
                          </Link>
                          <ul
                            className="dropdown-menu custom-drop-sub backco_green "
                            aria-labelledby="navbarDropdown111"
                          >
                            <li>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/epdc/intro"
                              >
                                Introduction
                              </Link>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/Organogram"
                              >
                                Organogram
                              </Link>
                              {/* <Link className="dropdown-item co_white font_sans fw_sans8 fz_12" to="/EPDCCompostion">EPDC Composition for Term 2018-21</Link> */}
                            </li>
                          </ul>
                        </li>
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/GuideLineMauals"
                        >
                          CPD Guideline Manual{" "}
                        </Link>
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/CPDRelaxPolicy"
                        >
                          CPD Relaxed Policy
                        </Link>
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/CPDBriefs"
                        >
                          CPD Brief
                        </Link>

                        <li className="dropdown-submenu">
                          <Link
                            className="dropdown-item co_white font_sans fw_sans8 fz_12 dropdown-toggle"
                            to="#"
                          >
                            PEC Approved Resource Person
                          </Link>
                          <ul className=" backco_green  custom-drop-sub dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/CPDListofResourcePerson"
                              >
                                List of Resourse Person
                              </Link>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/CPDResourcePersonCriteria"
                              >
                                Criteria for Resourse Person{" "}
                              </Link>
                              {/* <Link className="dropdown-item co_white font_sans fw_sans8 fz_12" to="/ApplicationForm">Application Form</Link> */}
                            </li>
                          </ul>
                        </li>

                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/important-news"
                        >
                          Important News for Engineers
                        </Link>
                      </div>
                    </li>
                  </li>

                  <li className="nav-item active   padding_un mlr_10 font_sans fw_sans8">
                    <li className="nav-item dropdown  active ">
                      <Link
                        className="nav-link headerline hvr-underline-from-center dropdown-toggle mb_uns  padding_un mlr_10  fz_16 pt_10I"
                        to="#"
                        id="navbarDropdown"
                      >
                        CPD BY PEC
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {/* <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/ScheduleCPDActivities">CPD Activities</Link> */}

                        {/* <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/WebinarSearch">Webinars</Link> */}
                        {/* <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/userview">User View</Link> */}

                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/CpdShortCourse"
                        >
                          Short Courses and Training
                        </Link>

                        <li className="dropdown-submenu">
                          <Link
                            className="dropdown-item co_white font_sans fw_sans8 fz_12 dropdown-toggle"
                            to="#"
                          >
                            Webinars
                          </Link>
                          <ul className="dropdown-menu custom-drop-sub">
                            <li>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/WebinarSearch/livewebinar"
                              >
                                Live Webinars By PEC
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/WebinarSearch/recordedwebinar"
                              >
                                Recorded Webinars By PEC
                              </Link>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/WebinarSearch/pebwebinar"
                              >
                                Webinars By PEB (Professional Engineering
                                Bodies)
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/onlineCourse"
                        >
                          Online Courses
                        </Link>
                        {/* <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/CpdProfile">Engineer's CPD Profile</Link> */}

                        {/* <li className="dropdown-submenu">
                        <Link className="dropdown-item co_white font_sans fw_sans8 fz_12 dropdown-toggle" to="#">
                        Engineer's CPD Profile
                        </Link>
                          <ul className="dropdown-menu custom-drop-sub">
                            <li>
                              <Link className="dropdown-item co_white font_sans fw_sans8 fz_12" to="/CpdProfile">
                              CPD Profile
                           </Link>
                              </li>
                             <li>
                              <Link className="dropdown-item co_white font_sans fw_sans8 fz_12" to="/CalculatorofCPD">
                                Calculator of CPD points</Link>
                              </li>
                            </ul>
                          </li> */}

                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/CpdProfile"
                        >
                          CPD Profile
                        </Link>
                        <Link
                          className="dropdown-item co_white font_sans fw_sans8 fz_12"
                          to="/CalculatorofCPD"
                        >
                          Calculator of CPD points
                        </Link>
                      </div>
                    </li>
                  </li>

                  <li className="nav-item active   padding_un mlr_10 font_sans fw_sans8">
                    <li className="nav-item dropdown  active">
                      <Link
                        className="nav-link headerline hvr-underline-from-center dropdown-toggle mb_uns  padding_un mlr_10  fz_16 pt_10I"
                        to="#"
                        id="navbarDropdown"
                      >
                        EPE
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/EPECustom"
                        >
                          Introduction to EPE
                        </Link>
                        {/* <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          href={epeexam}
                          target="_blank"
                          download
                        >
                          17th EPE Fall 2021
                        </a> */}

                        {/* <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          href="/EPECustom#EPEMechanism"
                        >
                          EPE Mechanism
                        </a> */}
                        <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          // href="/EPECustom"
                          onClick={() => {
                            this.props.history.push("/login", { epe: "epe" });
                          }}
                        >
                          Apply for 24th EPE Fall 2024
                        </a>
                        <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          href="/EPECustom#EligibilityCrieteriaforEngineeringPracticeExamination"
                        >
                          Eligibility Criteria
                        </a>
                        <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          href="/EPECustom#GuidelinesforCandidatesAppearinginEPE"
                        >
                          Guideline for Candidate Appearing in EPE
                        </a>
                        <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          href="/EPECustom#EPECurriculaandStudyMaterial"
                        >
                          Curriculum of EPE
                        </a>
                        <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          href="/EPECustom#ApplicationFormsforEngineeringPracticeExamination"
                        >
                          Application Form
                        </a>
                        <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          href="/EPECustom#PolicyGuidelinesforRedresselofEPECandidatesApealsComplaintsandGrievances"
                        >
                          Policy Guideline for Redressel of EPE Grievances
                        </a>
                      </div>
                    </li>
                  </li>
                  <li className="nav-item active   padding_un mlr_10 font_sans fw_sans8">
                    <li className="nav-item dropdown  active">
                      <Link
                        className="nav-link headerline hvr-underline-from-center dropdown-toggle mb_uns  padding_un mlr_10  fz_16 pt_10I"
                        to="#"
                        id="navbarDropdown"
                      >
                        PEB
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {/* <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/PEB">CPD Activity of Calendar for PEB</Link>
                      <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/ProfessionalEngnrBodies">Registration of Professional Engineering Bodies</Link>
                      <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/Principle">Principles</Link>
                      <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/Crieteria">Crieteria</Link>
                      <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/GuidelinesCPDBodieswithPEC">Guidelines for Registration of CPD Bodies with PEC</Link>
                      <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/FeeStructureforRegistration">Fee Structure for Registration</Link>
                   */}
                        {/* <Link className="dropdown-item  co_green font_sans fw_sans8 fz_12" to="/PEB">CPD Activity of Calendar for PEB</Link> */}
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/ProfessionalEngnrBodies"
                        >
                          Introduction
                        </Link>
                        <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          href="/ProfessionalEngnrBodies#crieteria"
                        >
                          Crieteria for Registration
                        </a>
                        <a
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          href="/ProfessionalEngnrBodies#procedure"
                        >
                          Procedure for Registration
                        </a>
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/PEB"
                        >
                          List of PEBs
                        </Link>
                        <li className="dropdown-submenu">
                          <Link
                            className="dropdown-item co_white font_sans fw_sans8 fz_12 dropdown-toggle"
                            to="#"
                          >
                            PEB's Dashboard
                          </Link>
                          <ul className="custom-drop-sub dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="#"
                              >
                                Apply for Renewal(Coming Soon)
                              </Link>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/PEBs_calendar_2020"
                              >
                                CPD Calendar{" "}
                              </Link>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="#"
                              >
                                CPD Returns
                              </Link>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/WebinarSearch/pebwebinar"
                              >
                                Webinars By PEB
                              </Link>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="#"
                              >
                                Contact Us (Coming Soon)
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </div>
                    </li>
                  </li>
                  <li className="nav-item active   padding_un mlr_10 font_sans fw_sans8">
                    <li className="nav-item dropdown  active">
                      <Link
                        className="nav-link headerline hvr-underline-from-center dropdown-toggle mb_uns  padding_un mlr_10  fz_16 pt_10I"
                        to="#"
                        id="navbarDropdown"
                      >
                        DOWNLOADS
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/ShortCoursesPPT"
                        >
                          Short Courses Presentation by PEC
                        </Link>
                        <li className="dropdown-submenu">
                          <Link
                            className="dropdown-item co_white font_sans fw_sans8 fz_12 dropdown-toggle"
                            to="/Forms"
                          >
                            Forms
                          </Link>
                          <ul className="custom-drop-sub dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/CPD_Resource_person"
                              >
                                CPD Resourse Person Application Form
                              </Link>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/EPEForms"
                              >
                                EPE Application Form{" "}
                              </Link>
                              <Link
                                className="dropdown-item co_white font_sans fw_sans8 fz_12"
                                to="/CPDForms"
                              >
                                PEB Registration Form
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <Link
                          className="dropdown-item  co_white font_sans fw_sans8 fz_12"
                          to="/Documents"
                        >
                          Documents
                        </Link>
                      </div>
                    </li>
                  </li>
                  <li
                    className="nav-item active    padding_un mlr_10"
                    style={{ display: "none" }}
                  >
                    <Link
                      to="#"
                      className="nav-link headerline mb_uns padding_un mlr_10  fz_16 pt_10I font_sans fw_sans8"
                    >
                      NEWS
                    </Link>
                  </li>
                  <li className="nav-item active    padding_un mlr_10">
                    <Link
                      to="/ContactUS"
                      className="nav-link headerline  hvr-underline-from-center mb_uns padding_un mlr_10  fz_16 pt_10I font_sans fw_sans8"
                    >
                      CONTACT
                    </Link>
                  </li>
                  <li className="nav-item active    padding_un mlr_10">
                    <Link
                      to="/Login"
                      className="nav-link headerline mb_uns hvr-underline-from-center padding_un mlr_10  fz_16 pt_10I font_sans fw_sans8"
                    >
                      LOGIN/REGISTER
                    </Link>
                  </li>
                  <li className="nav-item active    padding_un mlr_10">
                    <Link
                      to="/album"
                      className="nav-link  headerline hvr-underline-from-center mb_uns padding_un mlr_10  fz_16 pt_10I font_sans fw_sans8"
                    >
                      GALLERY
                    </Link>
                  </li>
                  <li
                    className="nav-item active   padding_un mlr_10"
                    style={{ border: "0px" }}
                  >
                    <a
                      href="https://www.pec.org.pk/"
                      className="nav-link headerline hvr-underline-from-center mb_uns padding_un mlr_10  fz_16 font_sans pt_10I fw_sans8"
                      target="_blank"
                    >
                      PEC HOME{" "}
                      <i className="fas fa-external-link-alt co_white"></i>
                    </a>
                  </li>

                  {/* <li className="nav-item active   Log_none  padding_un mlr_10">
                  <Link to="/Login" className="nav-link">
                    <p className="nav-link mb_uns padding_un mlr_10 co_greeen_imp fz_16 pt_10I font_sans fw_sans8">Login</p>
                  </Link>
                </li>
                <li className="nav-item active   Log_none  padding_un mlr_10">
                  <Link to="/Register" className="nav-link">
                    <p className="nav-link mb_uns padding_un mlr_10 co_greeen_imp fz_16 pt_10I font_sans fw_sans8">Register</p>
                  </Link>
                </li> */}
                </ul>

                {this.props.User ? (
                  <a onClick={this.logout} className="logoutbtn ">
                    <i className="fas fa-sign-out-alt"></i> Log out
                  </a>
                ) : (
                  " "
                )}
              </div>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    User: state.userData.user,
  };
}
export default withRouter(connect(mapStateToProps, { logout })(Header));
