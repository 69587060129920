// import React, { Component } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import Loader from "react-loader-spinner";
// import WebNotif from "../WebNotif";
// import ReactSuperSelect from "react-super-select";
// import _ from "lodash";
// import { confirmAlert } from "react-confirm-alert"; // Import
// import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import Moment from "react-moment";
// import { connect } from "react-redux";
// import Select from "react-select";
// import Feedback_Webinar from "./Feedback_Webinar";
// import {
//   getInstructors,
//   get_sessions,
//   editSession,
//   add_session,
//   addPricePlan,
//   getPaymentPlans,
//   updatePaymentPlan,
//   delete_session,
// } from "../../redux/admin/webinar/webinarActions";
// var obj = new WebNotif();

// class CCSessionsHome extends Component {
//   constructor(props) {
//     super(props);
//     this.focusInput = React.createRef();
//   }
//   state = {
//     items: [],
//     keyProp: null,
//     activeComp: 0,
//     contentType: null,
//     allowSave: true,
//     isEdit: false,
//     loader: false,
//     courseType: null,
//     editable: false,
//     startDate: null,
//     createPlanFlag: false,
//     selectedInstructor: false,
//     sessions: [],
//     sessiondetails: {
//       sessiontitle: "",
//       sessionInstructor: "",
//       planplaceholder: "Select a Plan",
//       CPDPoints: 0,
//       totalSeats: 0,
//       startDate: new Date(),
//       endDate: new Date(),
//       registrationstatus: "Open",
//       priceType: "Free",
//       selectedPlan: { pricePlan: "", price: "", discount: "" },
//       pricePlan: "",
//       updateFlag: false,
//       price: 0,
//       discount: 0,
//     },

//     InstructorSet: [{}],
//     PricePlans: [{ value: {}, label: "" }],
//   };

//   PoolData = {};

//   UNSAFE_componentWillMount() {
//     this.props.getInstructors().then((res) => {
//       var temp = [];
//       for (var i = 0; i < res.length; i++) {
//         var obj = {
//           id: res[i].instructor_id,
//           name: res[i].FullName,
//           size: "large",
//         };
//         temp.push(obj);
//       }
//       let temp2 = [];
//       temp2.push({
//         id: res[0].instructor_id,
//         name: res[0].FullName,
//         size: "large",
//       });
//       this.setState(
//         { InstructorSet: temp, InstructorPlaceHolder: temp2, InstPlace: temp2 },
//         () => {}
//       );
//     });
//     if (this.props.isEdit == false) {
//       if (this.props.webinarDetails.insertId) {
//         this.props.get_sessions(this.props.webinarDetails.insertId);
//       } else {
//       }
//     }
//     // if(this.props.isEdit==true){
//     //   const a = this.props.currentWebinarData;
//     //   const start_date= new Date(a.Session_StartDate)
//     //   const end_date= new Date(a.Session_EndDate)

//     //    var tempSessions=[]

//     //    for(var i =0; i<a.sessions.length;i++ ){
//     //     const start_date= new Date(a.sessions[i].Start)
//     //     const end_date= new Date(a.sessions[i].End)

//     //      tempSessions.push(  {sessiontitle: a.sessions[i].Name,
//     //                           sessionInstructor: a.sessions[i].Instructor,
//     //                           CPDPoints:a.sessions[i].Points,
//     //                           startDate: start_date,
//     //                           endDate: end_date})

//     //   }
//     //   tempSessions.push({sessiontitle : a.Session_Name,
//     //     sessionInstructor: a.Instructor_Name,
//     //     CPDPoints   : a.CPDPoints,
//     //     startDate : start_date,
//     //     endDate : end_date })
//     //   this.setState({
//     //               ...this.state,      sessions:tempSessions
//     //   },()=>
//     //   console.log(this.state.sessions,this.state.sessiondetails))
//     // }
//   }
//   componentDidUpdate(prevProps, prevState) {
//     if (
//       this.state.sessiondetails.priceType !== prevState.sessiondetails.priceType
//     ) {
//       if (this.state.sessiondetails.priceType === "OneTimePurchase") {
//         const data = {
//           id: this.props.webinarDetails.insertId
//             ? this.props.webinarDetails.insertId
//             : this.props.currentWebinarData &&
//               this.props.currentWebinarData.CourseID,
//         };
//         let options = [];
//         this.props
//           .getPaymentPlans(data)
//           .then((res) => {
//             res.map((item) => {
//               options.push({
//                 label: item.pricing_plan + " Price " + item.price,
//                 value: {
//                   discount: item.discount,
//                   price: item.price,
//                   pricePlan: item.pricing_plan,
//                   id: item.pricing_id,
//                 },
//               });
//             });
//             this.setState({
//               PricePlans: options,
//             });
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//       }
//     }
//   }
//   componentDidMount() {
//     if (this.props.sessions && this.props.sessions.length > 0) {
//     }
//     for (let i = 0; i < this.props.sessions.length; i++) {
//       if (this.props.sessions[i].pricing_type == "OneTimePurchase") {
//         const plan = {
//           pricePlan: this.props.sessions[i].pricing_plan,
//           price: this.props.sessions[i].price,
//           discount: this.props.sessions[i].discount,
//         };
//         const temp = {
//           value: plan,
//           label: plan.pricePlan,
//         };
//         this.setState({ PricePlans: [...this.state.PricePlans, temp] });
//       }
//     }
//   }

//   handlerExample = (option) => {
//     if (option) {
//       this.setState({
//         ...this.state,
//         sessiondetails: {
//           ...this.state.sessiondetails,
//           sessionInstructor: option.id,
//         },
//       });
//     }
//   };

//   onChangeSelectPlan = (selectedOption) => {
//     this.setState({
//       editflag: true,
//       sessiondetails: {
//         ...this.state.sessiondetails,
//         selectedPlan: selectedOption.value,
//         pricePlan: selectedOption.value.pricePlan,
//         price: selectedOption.value.price,
//         discount: selectedOption.value.discount,
//         updateFlag: true,
//       },
//     });
//   };
//   createPlan = () => {
//     if (this.state.sessiondetails.pricePlan == "") {
//       obj.createNotification("error", "Price Plan and Price Cannot Be Empty.");
//     } else {
//       if (
//         parseInt(this.state.sessiondetails.discount, 10) >
//         parseInt(this.state.sessiondetails.price, 10)
//       ) {
//         obj.createNotification(
//           "error",
//           "Discount Cannot Be Greater Than Price."
//         );
//       } else if (
//         this.state.sessiondetails.price < 0 ||
//         this.state.sessiondetails.discount < 0
//       ) {
//         obj.createNotification(
//           "error",
//           "Price and Discount Cannot Be Negative."
//         );
//       } else {
//         this.setState({ createPlanFlag: false, editflag: false }, () => {
//           const plan = {
//             pricePlan: this.state.sessiondetails.pricePlan,
//             price: this.state.sessiondetails.price,
//             discount: this.state.sessiondetails.discount,
//           };
//           let newdata = {
//             priceType: this.state.sessiondetails.priceType,
//             pricePlan: this.state.sessiondetails.pricePlan,
//             price: this.state.sessiondetails.price,
//             discount: this.state.sessiondetails.discount
//               ? this.state.sessiondetails.discount
//               : 0,
//             id: this.props.webinarDetails.insertId
//               ? this.props.webinarDetails.insertId
//               : this.props.currentWebinarData.CourseID,
//           };

//           const temp = {
//             value: plan,
//             label: plan.pricePlan,
//           };
//           this.setState(
//             { PricePlans: [...this.state.PricePlans, temp] },
//             () => {
//               this.setState(
//                 {
//                   sessiondetails: {
//                     ...this.state.sessiondetails,
//                     pricePlan: "",
//                     price: 0,
//                     discount: 0,
//                   },
//                 },
//                 () => {
//                   this.props
//                     .addPricePlan(newdata)
//                     .then((res) => {})
//                     .catch((err) => console.log(err));
//                 }
//               );
//             }
//           );
//         });
//       }
//     }
//   };

//   editPlan = () => {
//     if (this.state.sessiondetails.pricePlan == "") {
//       obj.createNotification("error", "Price Plan and Price Cannot Be Empty.");
//     } else {
//       if (
//         parseInt(this.state.sessiondetails.discount, 10) >
//         parseInt(this.state.sessiondetails.price, 10)
//       ) {
//         obj.createNotification(
//           "error",
//           "Discount Cannot Be Greater Than Price."
//         );
//       } else if (
//         this.state.sessiondetails.price < 0 ||
//         this.state.sessiondetails.discount < 0
//       ) {
//         obj.createNotification(
//           "error",
//           "Price and Discount Cannot Be Negative."
//         );
//       } else {
//         let newdata = {
//           name: this.state.sessiondetails.pricePlan,
//           price: this.state.sessiondetails.price,
//           discount: this.state.sessiondetails.discount,
//           id: this.state.sessiondetails.selectedPlan.id,
//         };
//         let newitem = [...this.state.PricePlans];
//         this.state.PricePlans.map((item, index) => {
//           if (item.value.id === this.state.sessiondetails.selectedPlan.id) {
//             newitem[index].value.discount = this.state.sessiondetails.discount;
//             newitem[index].value.price = this.state.sessiondetails.price;
//             newitem[
//               index
//             ].value.pricePlan = this.state.sessiondetails.pricePlan;
//           }
//         });
//         this.props
//           .updatePaymentPlan(newdata)
//           .then((res) => {
//             this.setState(
//               {
//                 PricePlans: newitem,
//               },
//               () => {
//                 obj.createNotification(
//                   "success",
//                   "Price Plan Updated Successfully"
//                 );
//               }
//             );
//             this.setState({
//               sessiondetails: {
//                 ...this.state.sessiondetails,
//                 pricePlan: "",
//                 price: 0,
//                 discount: 0,
//               },
//             });
//           })
//           .catch((req) => {
//             console.log(req);
//           });
//       }
//     }
//   };

//   addPlan = () => {
//     this.setState({ createPlanFlag: true, editflag: false });
//   };

//   validate = (value) => {
//     let CPDPointsValid = value.CPDPoints;
//     let DatesValid = false;

//     //  if(value.startDate.getTime() < new Date().getTime() ){
//     //    return ({isValidated:false, errors:'Start Time Needs To Be Greater Than Current Time!'})

//     //  }
//     if (value.startDate.getTime() >= value.endDate.getTime()) {
//       return {
//         isValidated: false,
//         errors: "End date cannot be equal or smaller",
//       };
//     } else {
//       DatesValid = true;
//       {
//         if (value.priceType == "OneTimePurchase") {
//           if (value.selectedPlan.pricePlan == "") {
//             return { isValidated: false, errors: "Select A Plan" };
//           }
//         }
//         if (value.totalSeats > 500) {
//           return {
//             isValidated: false,
//             errors: "Total Number of Seats Cannot Exceed 500",
//           };
//         }
//         return { isValidated: true, errors: "" };
//       }
//     }
//   };

//   addSection = () => {
//     this.setState(
//       {
//         loader: true,
//       },
//       () => {
//         if (
//           this.state.sessiondetails.sessiontitle === "" ||
//           (this.state.sessiondetails.sessiontitle === undefined &&
//             this.state.sessiondetails.sessionInstructor === "") ||
//           (this.state.sessiondetails.sessionInstructor === undefined &&
//             this.state.sessiondetails.CPDPoints === "") ||
//           this.state.sessiondetails.CPDPoints === undefined
//         ) {
//           this.setState({ loader: false }, () => {
//             obj.createNotification("error", "Fill Required Fields");
//           });
//         } else {
//           const instructorId = this.state.sessionInstructor;
//           const resultValidation = this.validate(this.state.sessiondetails);
//           if (resultValidation.isValidated) {
//             let data;
//             if (this.props.isEdit == false) {
//               if (this.props.webinarDetails.insertId) {
//                 data = {
//                   session: this.state.sessiondetails,
//                   id: this.props.webinarDetails.insertId,
//                   isFirst:this.props.sessions && this.props.sessions.length > 0 ? false: true
//                 };
//                 this.props.add_session(data);
//                 this.setState({
//                   InstructorPlaceHolder: this.state.InstPlace,
//                   editable: false,
//                   loader: false,
//                   sessiondetails: {
//                     ...this.state.sessiondetails,
//                     s_id: "",
//                     sessiontitle: "",
//                     selectedPlan: { pricePlan: "", price: "", discount: "" },
//                     CPDPoints: "",
//                     sessionInstructor: "",
//                     totalSeats: 0,
//                     startDate: new Date(),
//                     endDate: new Date(),
//                     registrationstatus: "Open",
//                     priceType: "Free",
//                     pricePlan: "",
//                     price: 0,
//                     discount: 0,
//                   },
//                 });

//                 obj.createNotification("success", "New Session Created");
//                 this.props.setsessionDetails();
//               } else {
//                 obj.createNotification("error", "Save Webinar Details First");
//               }
//             } else {
//               data = {
//                 session: this.state.sessiondetails,
//                 id: this.props.currentWebinarData.CourseID,
//               };
//               this.props.add_session(data);
//               this.setState({
//                 InstructorPlaceHolder: this.state.InstPlace,
//                 editable: false,
//                 loader: false,
//                 sessiondetails: {
//                   ...this.state.sessiondetails,
//                   s_id: "",
//                   sessiontitle: "",
//                   selectedPlan: { pricePlan: "", price: "", discount: "" },
//                   CPDPoints: "",
//                   sessionInstructor: "",
//                   totalSeats: 0,
//                   startDate: new Date(),
//                   endDate: new Date(),
//                   registrationstatus: "Open",
//                   priceType: "Free",
//                   pricePlan: "",
//                   price: 0,
//                   discount: 0,
//                 },
//               });
//               obj.createNotification("success", "New Session Created");
//             }
//           } else {
//             this.setState({ loader: false }, () => {
//               obj.createNotification("error", resultValidation.errors);
//             });
//           }
//         }
//       }
//     );
//   };
//   handleBlur(event) {
//     const { name, value } = event.target;
//     let newValue = parseFloat(value);
//     if (newValue < 0.5) newValue = 0.5;
//     if (newValue > 5) newValue = 5;
    
//     this.setState((prevState) => ({
//       sessiondetails: {
//         ...prevState.sessiondetails,
//         [name]: newValue,
//       },
//     }));
//   }
//   handleChange1 = (event) => {
//     this.setState(
//       {
//         sessiondetails: {
//           ...this.state.sessiondetails,
//           [event.target.name]: event.target.value,
//         },
//       },
//       () => {
//         if (
//           this.state.sessiondetails.priceType !== "Free" &&
//           ((this.state.PricePlans === [] &&
//             this.state.PricePlans[0].label === "") ||
//             (this.state.PricePlans[1] && this.state.PricePlans[1].label))
//         ) {
//           this.setState({ createPlanFlag: true });
//         }
//       }
//     );
//   };
//   handleChangeStart = (date) => {
//     this.setState({
//       sessiondetails: { ...this.state.sessiondetails, startDate: date },
//     });
//   };

//   handleChangeStart2 = (date) => {
//     this.setState({
//       sessiondetails: { ...this.state.sessiondetails, endDate: date },
//     });
//   };
//   delete = (id) => {
//     confirmAlert({
//       title: "Confirm to submit",
//       message: "Are you sure to delete this session.",
//       buttons: [
//         {
//           label: "Yes",
//           onClick: () => {
//             if (this.props.isEdit == false) {
//               if (this.props.sessions.length > 1) {
//                 this.props.delete_session({
//                   s_id: id,
//                   c_id: this.props.webinarDetails.insertId,
//                 });
//                 obj.createNotification("success", "Webinar Session Deleted");
//               } else {
//                 obj.createNotification(
//                   "warning",
//                   "There must be atleast one session"
//                 );
//               }
//             } else {
//               if (this.props.sessions.length > 1) {
//                 this.props.delete_session({
//                   s_id: id,
//                   c_id: this.props.currentWebinarData.CourseID,
//                 });
//                 obj.createNotification("success", "Webinar Session Deleted");
//               } else {
//                 obj.createNotification(
//                   "warning",
//                   "There must be atleast one session"
//                 );
//               }
//             }
//           },
//         },
//         {
//           label: "No",
//           onClick: () => {},
//         },
//       ],
//     });
//   };
//   edit = (s) => {
//     this.setState({ createPlanFlag: true }, () => {
//       if (s.pricing_type == "OneTimePurchase") {
//         const plan = {
//           pricePlan: s.pricing_plan,
//           price: s.price,
//           discount: s.discount,
//         };

//         const temp = {
//           value: plan,
//           label: plan.pricePlan,
//         };
//         let sessionarray = [];
//         sessionarray.push({
//           id: s.Instructor_ID,
//           name: s.FullName,
//           size: "large",
//         });
//         this.setState(
//           {
//             InstructorPlaceHolder: sessionarray,
//             editable: true,
//             sessiondetails: {
//               ...this.state.sessiondetails,
//               planplaceholder: s.pricing_plan,
//               s_id: s.session_id,
//               sessiontitle: s.Session_Name,
//               sessionInstructor: s.Instructor_ID,
//               CPDPoints: s.CPDPoints,
//               startDate: new Date(s.Session_StartDate),
//               endDate: new Date(s.Session_EndDate),
//               registrationstatus: s.Registration,
//               totalSeats: s.total_seats,
//               planplaceholder: s.pricing_plan,
//               selectedPlan: { pricePlan: "", price: "", discount: "" },
//               priceType: s.pricing_type,
//               pricePlan: s.pricing_plan,
//               price: s.price,
//               discount: s.discount,
//             },
//           },
//           () => {
//             let alreadyPresent = false;

//             if (this.state.PricePlans.length > 1) {
//               for (let i = 0; i < this.state.PricePlans.length; i++) {
//                 if (
//                   plan.pricePlan == this.state.PricePlans[i].value.pricePlan
//                 ) {
//                   alreadyPresent = true;
//                   break;
//                 }
//               }
//               if (!alreadyPresent) {
//                 this.setState(
//                   {
//                     PricePlans: [...this.state.PricePlans, temp],
//                     sessiondetails: {
//                       ...this.state.sessiondetails,
//                       selectedPlan: plan,
//                     },
//                   },
//                   () => {
//                     this.focusInput.current.focus();
//                   }
//                 );
//               } else {
//                 console.log("already Present");
//               }
//             } else {
//               this.setState(
//                 {
//                   PricePlans: [...this.state.PricePlans, temp],
//                   sessiondetails: {
//                     ...this.state.sessiondetails,
//                     selectedPlan: plan,
//                   },
//                 },
//                 () => {
//                   this.focusInput.current.focus();
//                 }
//               );
//             }
//           }
//         );
//       } else {
//         let sessionarray = [];
//         sessionarray.push({
//           id: s.Instructor_ID,
//           name: s.FullName,
//           size: "large",
//         });
//         this.setState(
//           {
//             InstructorPlaceHolder: sessionarray,
//             editable: true,
//             sessiondetails: {
//               ...this.state.sessiondetails,
//               s_id: s.session_id,
//               sessiontitle: s.Session_Name,
//               sessionInstructor: s.Instructor_ID,
//               CPDPoints: s.CPDPoints,
//               startDate: new Date(s.Session_StartDate),
//               endDate: new Date(s.Session_EndDate),
//               registrationstatus: s.Registration,
//               totalSeats: s.total_seats,
//               planplaceholder: s.pricing_plan,
//               selectedPlan: { pricePlan: "", price: "", discount: "" },
//               priceType: s.pricing_type,
//               pricePlan: s.pricing_plan,
//               price: s.price,
//               discount: s.discount,
//             },
//           },
//           () => {
//             this.focusInput.current.focus();
//           }
//         );
//       }
//     });
//   };

//   saveForm = () => {
//     this.props.setsessionDetails(this.state.sessions);
//   };

//   editform = () => {
//     if (
//       this.state.sessiondetails.sessiontitle !== "" &&
//       this.state.sessiondetails.sessionInstructor !== "" &&
//       this.state.sessiondetails.CPDPoints !== ""
//     ) {
//       let data;
//       const instructorId = this.state.sessionInstructor;
//       const resultValidation = this.validate(this.state.sessiondetails);
//       if (resultValidation.isValidated) {
//         if (this.state.sessiondetails.priceType == "Free") {
//           this.setState(
//             {
//               sessiondetails: {
//                 ...this.state.sessiondetails,
//                 price: 0,
//                 discount: 0,
//               },
//             },
//             () => {
//               if (this.props.isEdit == false) {
//                 data = {
//                   session: this.state.sessiondetails,
//                   c_id: this.props.webinarDetails.insertId,
//                 };
//               } else {
//                 data = {
//                   session: this.state.sessiondetails,
//                   c_id: this.props.currentWebinarData.CourseID,
//                 };
//               }

//               this.props.editSession(data);
//               this.setState({
//                 InstructorPlaceHolder: this.state.InstPlace,
//                 editable: false,
//                 sessiondetails: {
//                   ...this.state.sessiondetails,
//                   s_id: "",
//                   sessiontitle: "",
//                   selectedPlan: { pricePlan: "", price: "", discount: "" },
//                   CPDPoints: "",
//                   startDate: new Date(),
//                   totalSeats: 0,
//                   endDate: new Date(),
//                   registrationstatus: "Open",
//                   priceType: "Free",
//                   pricePlan: "",
//                   price: 0,
//                   discount: 0,
//                 },
//               });
//               obj.createNotification("success", "Webinar Session Edited");
//             }
//           );
//         } else {
//           if (this.props.isEdit == false) {
//             data = {
//               session: this.state.sessiondetails,
//               c_id: this.props.webinarDetails.insertId,
//             };
//           } else {
//             data = {
//               session: this.state.sessiondetails,
//               c_id: this.props.currentWebinarData.CourseID,
//             };
//           }
//           this.props.editSession(data);
//           this.setState({
//             editable: false,
//             InstructorPlaceHolder: this.state.InstPlace,
//             sessiondetails: {
//               ...this.state.sessiondetails,
//               s_id: "",
//               sessiontitle: "",
//               selectedPlan: { pricePlan: "", price: "", discount: "" },
//               CPDPoints: "",
//               startDate: new Date(),
//               sessionInstructor: "",
//               totalSeats: 0,
//               endDate: new Date(),
//               registrationstatus: "Open",
//               priceType: "Free",
//               pricePlan: "",
//               price: 0,
//               discount: 0,
//             },
//           });
//           obj.createNotification("success", "Webinar Session Edited");
//         }
//       } else {
//         obj.createNotification("error", resultValidation.errors);
//       }
//     } else {
//       obj.createNotification("error", "Fill Required Fields");
//     }
//   };

//   render() {
//     return (
//       <React.Fragment>
//         <div className=" col-md-12">
//           <div className="row">
//             <div className="col-md-5">
//               <h3 className="card-title bold regular_font">Sessions</h3>
//               <div className="col lrp-0">
//                 <span>
//                   {/* <b>Course title:</b>
//                   {this.state.courseTitle != undefined
//                     ? " (" + this.state.courseTitle + ")"
//                     : ""} */}
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>
//         <hr />
//         {this.state.loader ? (
//           <div className="loaderDiv text-center">
//             <Loader type="Oval" color="#06580e" height={80} width={80} />
//           </div>
//         ) : (
//           <div className="row">
//             <div className="form-group col-md-6">
//               <label>
//                 Title <span className="clr-red">*</span>
//               </label>
//               <input
//                 type="text"
//                 value={this.state.sessiondetails.sessiontitle}
//                 placeholder="Enter session title"
//                 className="form-control admin-form-control"
//                 name="sessiontitle"
//                 onChange={this.handleChange1}
//                 required
//                 ref={this.focusInput}
//               />
//             </div>
//             <div className="form-group col-md-6">
//               <label>
//                 Resource Person <span className="clr-red">*</span>
//               </label>
//               <ReactSuperSelect
//                 // placeholder={
//                 //   this.props.isEdit == true
//                 //     ? this.state.InstructorPlaceHolder
//                 //     : "Select Instructor"
//                 // }
//                 initialValue={this.state.InstructorPlaceHolder}
//                 clearSearchOnSelection={true}
//                 deselectOnSelectedOptionClick={false}
//                 dataSource={this.state.InstructorSet}
//                 onChange={this.handlerExample}
//                 searchable={true}
//               />
//             </div>
//             <div className="form-group col-md-6">
//               <label>Start Date</label>
//               <br />
//               <DatePicker
//                 showTimeSelect
//                 className="form-control w-100"
//                 selected={this.state.sessiondetails.startDate}
//                 dateFormat="MMMM d, yyyy h:mm aa"
//                 // minDate={new Date()}
//                 onChange={this.handleChangeStart}
//               />
//             </div>
//             <div className="form-group col-md-6">
//               <label>End Date</label>
//               <br />
//               <DatePicker
//                 showTimeSelect
//                 className="form-control w-100"
//                 selected={this.state.sessiondetails.endDate}
//                 dateFormat="MMMM d, yyyy h:mm aa"
//                 minDate={this.state.sessiondetails.startDate}
//                 onChange={this.handleChangeStart2}
//               />
//             </div>
//             <div className="form-group col-md-6">
//               <label>
//                 CPD Points <span className="clr-red">*</span>
//               </label>
//               <input
//                 type="number"
//                 min={0.5}
//                 max={5}
//                 step={0.1}
//                 value={this.state.sessiondetails.CPDPoints}
//                 placeholder="Enter CPD Points"
//                 className="form-control admin-form-control"
//                 name="CPDPoints"
//                 onChange={this.handleChange1}
//                 onBlur={this.handleBlur}
//                 required
//               />
//             </div>
//             <div className="form-group col-md-6">
//               <label>
//                 Total Seats <span className="clr-red">*</span>
//               </label>
//               <input
//                 type="number"
//                 min={0}
//                 value={this.state.sessiondetails.totalSeats}
//                 placeholder="Enter seats"
//                 className="form-control admin-form-control"
//                 name="totalSeats"
//                 onChange={this.handleChange1}
//                 required
//               />
//             </div>

//             <div className="form-group col-md-6">
//               <label>Registration Status</label>

//               <select
//                 className="form-control admin-form-control"
//                 value={this.state.sessiondetails.registrationstatus}
//                 onChange={this.handleChange1}
//                 name="registrationstatus"
//               >
//                 <option value="Close">Closed</option>
//                 <option value="Open">Open </option>
//               </select>
//             </div>
//             <div className="form-group col-md-6"></div>
//             <div className="form-group col-md-6">
//               <label htmlFor="validationDefault01">
//                 Price Type<span className="co_red">*</span>
//               </label>
//               <div className="fz_16">
//                 <select
//                   name="priceType"
//                   onChange={this.handleChange1}
//                   value={this.state.sessiondetails.priceType}
//                   id="validationDefault01"
//                   className="form-control co_black"
//                 >
//                   <option value="Free">Free of Cost</option>
//                   <option value="OneTimePurchase">One Time Purchase</option>
//                 </select>
//               </div>
//             </div>

//             {this.state.sessiondetails.priceType !== "Free" && (
//               <div className="form-group col-md-4">
//                 <label>Select Plan </label>
//                 <Select
//                   options={this.state.PricePlans}
//                   placeholder={
//                     this.state.sessiondetails.planplaceholder !==
//                     "Select a Plan"
//                       ? this.state.sessiondetails.planplaceholder
//                       : "Select a Plan"
//                   }
//                   value={this.state.selectedPlan}
//                   onChange={this.onChangeSelectPlan}
//                 />
//               </div>
//             )}
//             {this.state.sessiondetails.priceType !== "Free" && (
//               <div className="form-group w-100 col-md-2 btn_div">
//                 <button className="btn btn-save  bold" onClick={this.addPlan}>
//                   Add Plan
//                 </button>
//               </div>
//             )}

//             {this.state.createPlanFlag == true &&
//               this.state.sessiondetails.priceType !== "Free" && (
//                 <div className="form-group col-md-6">
//                   <label htmlFor="validationDefault02">
//                     Plan Name<span className="co_red">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     name="pricePlan"
//                     onChange={this.handleChange1}
//                     value={this.state.sessiondetails.pricePlan}
//                     className="form-control fz_16"
//                     placeholder="Enter plan name"
//                     required
//                   />
//                 </div>
//               )}

//             {this.state.createPlanFlag == true &&
//               this.state.sessiondetails.priceType !== "Free" && (
//                 <div className="form-group col-md-6">
//                   <label htmlFor="validationDefault03">
//                     Price in Rs<span className="co_red">*</span>
//                   </label>
//                   <input
//                     type="number"
//                     min={0}
//                     name="price"
//                     onChange={this.handleChange1}
//                     value={this.state.sessiondetails.price}
//                     placeholder="Enter Price in Rs"
//                     className="form-control fz_16"
//                     required
//                   />
//                 </div>
//               )}
//             {this.state.createPlanFlag &&
//               this.state.sessiondetails.priceType !== "Free" && (
//                 <div className="form-group col-md-6">
//                   <label htmlFor="validationDefault04">Discount in Rs</label>
//                   <input
//                     type="number"
//                     min={0}
//                     name="discount"
//                     onChange={this.handleChange1}
//                     value={this.state.sessiondetails.discount}
//                     placeholder="Enter Discount in Rs"
//                     className="form-control fz_16"
//                     required
//                   />
//                 </div>
//               )}
//             {this.state.createPlanFlag &&
//               this.state.sessiondetails.priceType !== "Free" && (
//                 <div className="btn_div">
//                   <button
//                     className="btn btn-save  bold"
//                     onClick={
//                       this.state.editflag ? this.editPlan : this.createPlan
//                     }
//                   >
//                     {this.state.editflag ? "Save Plan" : "Create Plan"}
//                   </button>
//                 </div>
//               )}

//             {this.state.editable == true ? (
//               <div className="col-md-12 mtb-10">
//                 &nbsp;
//                 <button
//                   className="btn btn-save fix_width_btn   bold fz_14"
//                   onClick={this.editform}
//                 >
//                   Save Session
//                 </button>
//               </div>
//             ) : (
//               <div className="col-md-12 mtb-10 align-left">
//                 <button
//                   className="btn btn-save fix_width_btn   bold fz_14"
//                   onClick={this.addSection}
//                 >
//                   Save Session
//                 </button>
//               </div>
//             )}
//           </div>
//         )}
//         <div className="CreatedSessions">
//           <div className="row createdsessionheader">
//             <div className="col-md-1 fs-14">Title</div>
//             <div className="col-md-2 fs-14">Instructor</div>
//             <div className="col-md-2 fs-14">Start Date</div>
//             <div className="col-md-2 fs-14">End Date</div>
//             <div className="col-md-1 fs-14">CPD</div>
//             <div className="col-md-1 fs-14">Total Seats</div>
//             <div className="col-md-1 fs-14">Status</div>
//             <div className="col-md-1 fs-14">Price</div>

//             <div className="col-md-1 fs-14"></div>
//           </div>

//           {this.props.sessions && this.props.sessions.length > 0 ? (
//             this.props.sessions.map((item, key) => (
//               <div key={item.session_id} className="row createdsessionDiv">
//                 <div className="col-md-1 fs-14">{item.Session_Name}</div>
//                 <div className="col-md-2 fs-14">{item.FullName}</div>
//                 <div className="col-md-2 fs-14">
//                   <Moment format="DD-MM-YY hh:mm a">
//                     {item.Session_StartDate}
//                   </Moment>
//                 </div>
//                 <div className="col-md-2 fs-14">
//                   <Moment format="DD-MM-YY hh:mm a">
//                     {item.Session_EndDate}
//                   </Moment>
//                 </div>
//                 <div className="col-md-1 fs-14">{item.CPDPoints}</div>
//                 <div className="col-md-1 fs-14">{item.total_seats}</div>
//                 <div className="col-md-1 fs-14">{item.Registration}</div>

//                 <div className="col-md-1 fs-14">
//                   {item.pricing_type == "Free" ? "Free" : item.price}
//                 </div>
//                 <div className="col-md-1 fs-14">
//                   <button
//                     className="cancel-file-btn lrm-5"
//                     onClick={() => this.edit(item)}
//                   >
//                     <i className="fa fa-edit co_green" aria-hidden="true" />
//                   </button>
//                   <button
//                     className="cancel-file-btn "
//                     onClick={() => this.delete(item.session_id)}
//                   >
//                     <i className="fa fa-trash delbutton" aria-hidden="true" />
//                   </button>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <h4 className="mtb-20 text-center ">No Sessions created yet</h4>
//           )}

//           {/* {
//           this.state.sessions.length>0 
//           ?
//               this.state.sessions.map((item,key)=> 
//               <div key={key} className="row createdsessionDiv"> 
//                 <div  className="col-md-2 fs-14">
//                  {item.sessiontitle}
//                 </div>
//                 <div className="col-md-2 fs-14">
//                   {item.sessionInstructor}
//                 </div>
//                 <div className="col-md-2 fs-14">
//                   <Moment format='dddd Do MMMM, YYYY'>{item.startDate}</Moment>
//                 </div>
//                 <div className="col-md-2 fs-14">
//                 <Moment format='dddd Do MMMM, YYYY'>{item.endDate}</Moment>
//                 </div>
//                 <div className="col-md-1 fs-14">
//                 {item.CPDPoints}
//                 </div>
//                 <div className="col-md-2 fs-14">
//                 {item.registrationstatus}
//                 </div>
//                 <div className="col-md-1 fs-14">
//                 <button className="cancel-file-btn" onClick={()=>this.delete(key)}><i className="fa fa-trash delbutton" aria-hidden="true" /></button>
//                 </div>
                
//               </div>
            
//             ):  
//             <h4 className="mtb-20 text-center ">No Sessions created yet</h4>
//         } */}
//         </div>

//         {this.props.isEdit == true &&
//         ((this.props.User.RoleID && this.props.User.RoleID === 8) ||
//           (this.props.User.RoleID && this.props.User.RoleID === 7) ||
//           (this.props.User.RoleID && this.props.User.RoleID === 3) ||
//           (this.props.User.RoleID && this.props.User.RoleID === 4) ||
//           (this.props.User.RoleID && this.props.User.RoleID === 5)) ? (
//           <Feedback_Webinar
//             webinarId={this.props.currentWebinarData.CourseID}
//             owner={this.props.currentWebinarData.user_id}
//           />
//         ) : (
//           ""
//         )}

//         {/* <div className="col-md-12 " >
//               &nbsp;
//               <button
//                 className="btn float-right btn-save fix_width_btn   bold fz_14"
//                 onClick={this.saveForm}
//                 {...(this.state.sessions.length>0
//                   ? { disabled: false }
//                   : { disabled: true })}
//               >
//                 Save
//               </button>
//               &nbsp;
              
//             </div> */}

//         {/* <PaperSection
//           items={this.state.items}
//           courseType={this.state.courseType}
//           // keyProp={this.state.keyProp}
//           // isEdit={this.state.isEdit}
//           remapData={this.remapData}
//         /> */}
//         {/* {this.props.isEdit == true && (this.props.User.RoleID !== 7 || this.props.User.RoleID !== 8 || this.props.User.RoleID === 3 || this.props.User.RoleID === 4) &&
//           <div className="col-md-12">
//             <button
//               className="btn btn-save float-right bold "
//               // style={{marginTop:'7px'}}
//               onClick={this.addSection}
//             >
//               {this.props.isEdit == true && "Update and Next"}
//             </button>
//           </div>} */}
//       </React.Fragment>
//     );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     sessions: state.webinarData.webinarSessions,
//     webinarDetails: state.webinarData.webinarDetails,
//     User: state.userData.admin,
//   };
// }

// export default connect(mapStateToProps, {
//   getInstructors,
//   get_sessions,
//   editSession,
//   updatePaymentPlan,
//   add_session,
//   getPaymentPlans,
//   addPricePlan,
//   delete_session,
// })(CCSessionsHome);

import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-loader-spinner";
import WebNotif from "../WebNotif";
import ReactSuperSelect from "react-super-select";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Moment from "react-moment";
import { connect } from "react-redux";
import Select from "react-select";
import Feedback_Webinar from "./Feedback_Webinar";
import {
  getInstructors,
  get_sessions,
  editSession,
  add_session,
  addPricePlan,
  getPaymentPlans,
  updatePaymentPlan,
  delete_session,
} from "../../redux/admin/webinar/webinarActions";
var obj = new WebNotif();

class CCSessionsHome extends Component {
  constructor(props) {
    super(props);
    this.focusInput = React.createRef();
  }
  state = {
    items: [],
    keyProp: null,
    activeComp: 0,
    contentType: null,
    allowSave: true,
    isEdit: false,
    loader: false,
    courseType: null,
    editable: false,
    startDate: null,
    createPlanFlag: false,
    selectedInstructor: false,
    sessions: [],
    sessiondetails: {
      sessiontitle: "",
      sessionInstructor: "",
      planplaceholder: "Select a Plan",
      CPDPoints: 0,
      totalSeats: 0,
      startDate: new Date(),
      endDate: new Date(),
      registrationstatus: "Open",
      priceType: "Free",
      selectedPlan: { pricePlan: "", price: "", discount: "" },
      pricePlan: "",
      updateFlag: false,
      price: 0,
      discount: 0,
    },

    InstructorSet: [{}],
    PricePlans: [{ value: {}, label: "" }],
  };

  PoolData = {};

  UNSAFE_componentWillMount() {
    this.props.getInstructors().then((res) => {
      var temp = [];
      for (var i = 0; i < res.length; i++) {
        var obj = {
          id: res[i].instructor_id,
          name: res[i].FullName,
          size: "large",
        };
        temp.push(obj);
      }
      let temp2 = [];
      temp2.push({
        id: res[0].instructor_id,
        name: res[0].FullName,
        size: "large",
      });
      this.setState(
        { InstructorSet: temp, InstructorPlaceHolder: temp2, InstPlace: temp2 },
        () => {}
      );
    });
    if (this.props.isEdit == false) {
      if (this.props.webinarDetails.insertId) {
        this.props.get_sessions(this.props.webinarDetails.insertId);
      } else {
      }
    }
    // if(this.props.isEdit==true){
    //   const a = this.props.currentWebinarData;
    //   const start_date= new Date(a.Session_StartDate)
    //   const end_date= new Date(a.Session_EndDate)

    //    var tempSessions=[]

    //    for(var i =0; i<a.sessions.length;i++ ){
    //     const start_date= new Date(a.sessions[i].Start)
    //     const end_date= new Date(a.sessions[i].End)

    //      tempSessions.push(  {sessiontitle: a.sessions[i].Name,
    //                           sessionInstructor: a.sessions[i].Instructor,
    //                           CPDPoints:a.sessions[i].Points,
    //                           startDate: start_date,
    //                           endDate: end_date})

    //   }
    //   tempSessions.push({sessiontitle : a.Session_Name,
    //     sessionInstructor: a.Instructor_Name,
    //     CPDPoints   : a.CPDPoints,
    //     startDate : start_date,
    //     endDate : end_date })
    //   this.setState({
    //               ...this.state,      sessions:tempSessions
    //   },()=>
    //   console.log(this.state.sessions,this.state.sessiondetails))
    // }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.sessiondetails.priceType !== prevState.sessiondetails.priceType
    ) {
      if (this.state.sessiondetails.priceType === "OneTimePurchase") {
        const data = {
          id: this.props.webinarDetails.insertId
            ? this.props.webinarDetails.insertId
            : this.props.currentWebinarData &&
              this.props.currentWebinarData.CourseID,
        };
        let options = [];
        this.props
          .getPaymentPlans(data)
          .then((res) => {
            res.map((item) => {
              options.push({
                label: item.pricing_plan + " Price " + item.price,
                value: {
                  discount: item.discount,
                  price: item.price,
                  pricePlan: item.pricing_plan,
                  id: item.pricing_id,
                },
              });
            });
            this.setState({
              PricePlans: options,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
  componentDidMount() {
    if (this.props.sessions && this.props.sessions.length > 0) {
    }
    for (let i = 0; i < this.props.sessions.length; i++) {
      if (this.props.sessions[i].pricing_type == "OneTimePurchase") {
        const plan = {
          pricePlan: this.props.sessions[i].pricing_plan,
          price: this.props.sessions[i].price,
          discount: this.props.sessions[i].discount,
        };
        const temp = {
          value: plan,
          label: plan.pricePlan,
        };
        this.setState({ PricePlans: [...this.state.PricePlans, temp] });
      }
    }
  }

  handlerExample = (option) => {
    if (option) {
      this.setState({
        ...this.state,
        sessiondetails: {
          ...this.state.sessiondetails,
          sessionInstructor: option.id,
        },
      });
    }
  };

  onChangeSelectPlan = (selectedOption) => {
    this.setState({
      editflag: true,
      sessiondetails: {
        ...this.state.sessiondetails,
        selectedPlan: selectedOption.value,
        pricePlan: selectedOption.value.pricePlan,
        price: selectedOption.value.price,
        discount: selectedOption.value.discount,
        updateFlag: true,
      },
    });
  };
  createPlan = () => {
    if (this.state.sessiondetails.pricePlan == "") {
      obj.createNotification("error", "Price Plan and Price Cannot Be Empty.");
    } else {
      if (
        parseInt(this.state.sessiondetails.discount, 10) >
        parseInt(this.state.sessiondetails.price, 10)
      ) {
        obj.createNotification(
          "error",
          "Discount Cannot Be Greater Than Price."
        );
      } else if (
        this.state.sessiondetails.price < 0 ||
        this.state.sessiondetails.discount < 0
      ) {
        obj.createNotification(
          "error",
          "Price and Discount Cannot Be Negative."
        );
      } else {
        this.setState({ createPlanFlag: false, editflag: false }, () => {
          const plan = {
            pricePlan: this.state.sessiondetails.pricePlan,
            price: this.state.sessiondetails.price,
            discount: this.state.sessiondetails.discount,
          };
          let newdata = {
            priceType: this.state.sessiondetails.priceType,
            pricePlan: this.state.sessiondetails.pricePlan,
            price: this.state.sessiondetails.price,
            discount: this.state.sessiondetails.discount
              ? this.state.sessiondetails.discount
              : 0,
            id: this.props.webinarDetails.insertId
              ? this.props.webinarDetails.insertId
              : this.props.currentWebinarData.CourseID,
          };

          const temp = {
            value: plan,
            label: plan.pricePlan,
          };
          this.setState(
            { PricePlans: [...this.state.PricePlans, temp] },
            () => {
              this.setState(
                {
                  sessiondetails: {
                    ...this.state.sessiondetails,
                    pricePlan: "",
                    price: 0,
                    discount: 0,
                  },
                },
                () => {
                  this.props
                    .addPricePlan(newdata)
                    .then((res) => {})
                    .catch((err) => console.log(err));
                }
              );
            }
          );
        });
      }
    }
  };

  editPlan = () => {
    if (this.state.sessiondetails.pricePlan == "") {
      obj.createNotification("error", "Price Plan and Price Cannot Be Empty.");
    } else {
      if (
        parseInt(this.state.sessiondetails.discount, 10) >
        parseInt(this.state.sessiondetails.price, 10)
      ) {
        obj.createNotification(
          "error",
          "Discount Cannot Be Greater Than Price."
        );
      } else if (
        this.state.sessiondetails.price < 0 ||
        this.state.sessiondetails.discount < 0
      ) {
        obj.createNotification(
          "error",
          "Price and Discount Cannot Be Negative."
        );
      } else {
        let newdata = {
          name: this.state.sessiondetails.pricePlan,
          price: this.state.sessiondetails.price,
          discount: this.state.sessiondetails.discount,
          id: this.state.sessiondetails.selectedPlan.id,
        };
        let newitem = [...this.state.PricePlans];
        this.state.PricePlans.map((item, index) => {
          if (item.value.id === this.state.sessiondetails.selectedPlan.id) {
            newitem[index].value.discount = this.state.sessiondetails.discount;
            newitem[index].value.price = this.state.sessiondetails.price;
            newitem[
              index
            ].value.pricePlan = this.state.sessiondetails.pricePlan;
          }
        });
        this.props
          .updatePaymentPlan(newdata)
          .then((res) => {
            this.setState(
              {
                PricePlans: newitem,
              },
              () => {
                obj.createNotification(
                  "success",
                  "Price Plan Updated Successfully"
                );
              }
            );
            this.setState({
              sessiondetails: {
                ...this.state.sessiondetails,
                pricePlan: "",
                price: 0,
                discount: 0,
              },
            });
          })
          .catch((req) => {
            console.log(req);
          });
      }
    }
  };

  addPlan = () => {
    this.setState({ createPlanFlag: true, editflag: false });
  };

  validate = (value) => {
    let CPDPointsValid = value.CPDPoints;
    let DatesValid = false;

    //  if(value.startDate.getTime() < new Date().getTime() ){
    //    return ({isValidated:false, errors:'Start Time Needs To Be Greater Than Current Time!'})

    //  }
    if (value.startDate.getTime() >= value.endDate.getTime()) {
      return {
        isValidated: false,
        errors: "End date cannot be equal or smaller",
      };
    } else {
      DatesValid = true;
      {
        if (value.priceType == "OneTimePurchase") {
          if (value.selectedPlan.pricePlan == "") {
            return { isValidated: false, errors: "Select A Plan" };
          }
        }
        if (value.totalSeats > 500) {
          return {
            isValidated: false,
            errors: "Total Number of Seats Cannot Exceed 500",
          };
        }
        return { isValidated: true, errors: "" };
      }
    }
  };

  addSection = () => {
    this.setState(
      {
        loader: true,
      },
      () => {
        if (
          this.state.sessiondetails.sessiontitle === "" ||
          (this.state.sessiondetails.sessiontitle === undefined &&
            this.state.sessiondetails.sessionInstructor === "") ||
          (this.state.sessiondetails.sessionInstructor === undefined &&
            this.state.sessiondetails.CPDPoints === "") ||
          this.state.sessiondetails.CPDPoints === undefined
        ) {
          this.setState({ loader: false }, () => {
            obj.createNotification("error", "Fill Required Fields");
          });
        } else {
          const instructorId = this.state.sessionInstructor;
          const resultValidation = this.validate(this.state.sessiondetails);
          if (resultValidation.isValidated) {
            let data;
            if (this.props.isEdit == false) {
              if (this.props.webinarDetails.insertId) {
                data = {
                  session: this.state.sessiondetails,
                  id: this.props.webinarDetails.insertId,
                  isFirst:this.props.sessions && this.props.sessions.length > 0 ? false: true
                };
                this.props.add_session(data);
                this.setState({
                  InstructorPlaceHolder: this.state.InstPlace,
                  editable: false,
                  loader: false,
                  sessiondetails: {
                    ...this.state.sessiondetails,
                    s_id: "",
                    sessiontitle: "",
                    selectedPlan: { pricePlan: "", price: "", discount: "" },
                    CPDPoints: "",
                    sessionInstructor: "",
                    totalSeats: 0,
                    startDate: new Date(),
                    endDate: new Date(),
                    registrationstatus: "Open",
                    priceType: "Free",
                    pricePlan: "",
                    price: 0,
                    discount: 0,
                  },
                });

                obj.createNotification("success", "New Session Created");
                this.props.setsessionDetails();
              } else {
                obj.createNotification("error", "Save Webinar Details First");
              }
            } else {
              data = {
                session: this.state.sessiondetails,
                id: this.props.currentWebinarData.CourseID,
              };
              this.props.add_session(data);
              this.setState({
                InstructorPlaceHolder: this.state.InstPlace,
                editable: false,
                loader: false,
                sessiondetails: {
                  ...this.state.sessiondetails,
                  s_id: "",
                  sessiontitle: "",
                  selectedPlan: { pricePlan: "", price: "", discount: "" },
                  CPDPoints: "",
                  sessionInstructor: "",
                  totalSeats: 0,
                  startDate: new Date(),
                  endDate: new Date(),
                  registrationstatus: "Open",
                  priceType: "Free",
                  pricePlan: "",
                  price: 0,
                  discount: 0,
                },
              });
              obj.createNotification("success", "New Session Created");
            }
          } else {
            this.setState({ loader: false }, () => {
              obj.createNotification("error", resultValidation.errors);
            });
          }
        }
      }
    );
  };

  

  handleChange1 = (event) => {
    const { name, value } = event.target;
    // Add validation for numeric fields
    if (name === 'price' || name === 'discount') {
      if (!/^\d*\.?\d*$/.test(value)) {
        return; // Invalid input, so do nothing
      }
    }
    
    this.setState(
      {
        sessiondetails: {
          ...this.state.sessiondetails,
          [name]: value,
        },
      },
      () => {
        if (
          this.state.sessiondetails.priceType !== "Free" &&
          ((this.state.PricePlans === [] &&
            this.state.PricePlans[0].label === "") ||
            (this.state.PricePlans[1] && this.state.PricePlans[1].label))
        ) {
          this.setState({ createPlanFlag: true });
        }
      }
    );
  };

  handleChangeStart = (date) => {
    this.setState({
      sessiondetails: { ...this.state.sessiondetails, startDate: date },
    });
  };

  handleChangeStart2 = (date) => {
    this.setState({
      sessiondetails: { ...this.state.sessiondetails, endDate: date },
    });
  };

  delete = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to delete this session.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (this.props.isEdit == false) {
              if (this.props.sessions.length > 1) {
                this.props.delete_session({
                  s_id: id,
                  c_id: this.props.webinarDetails.insertId,
                });
                obj.createNotification("success", "Webinar Session Deleted");
              } else {
                obj.createNotification(
                  "warning",
                  "There must be atleast one session"
                );
              }
            } else {
              if (this.props.sessions.length > 1) {
                this.props.delete_session({
                  s_id: id,
                  c_id: this.props.currentWebinarData.CourseID,
                });
                obj.createNotification("success", "Webinar Session Deleted");
              } else {
                obj.createNotification(
                  "warning",
                  "There must be atleast one session"
                );
              }
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  edit = (s) => {
    this.setState({ createPlanFlag: true }, () => {
      if (s.pricing_type == "OneTimePurchase") {
        const plan = {
          pricePlan: s.pricing_plan,
          price: s.price,
          discount: s.discount,
        };

        const temp = {
          value: plan,
          label: plan.pricePlan,
        };
        let sessionarray = [];
        sessionarray.push({
          id: s.Instructor_ID,
          name: s.FullName,
          size: "large",
        });
        this.setState(
          {
            InstructorPlaceHolder: sessionarray,
            editable: true,
            sessiondetails: {
              ...this.state.sessiondetails,
              planplaceholder: s.pricing_plan,
              s_id: s.session_id,
              sessiontitle: s.Session_Name,
              sessionInstructor: s.Instructor_ID,
              CPDPoints: s.CPDPoints,
              startDate: new Date(s.Session_StartDate),
              endDate: new Date(s.Session_EndDate),
              registrationstatus: s.Registration,
              totalSeats: s.total_seats,
              planplaceholder: s.pricing_plan,
              selectedPlan: { pricePlan: "", price: "", discount: "" },
              priceType: s.pricing_type,
              pricePlan: s.pricing_plan,
              price: s.price,
              discount: s.discount,
            },
          },
          () => {
            let alreadyPresent = false;

            if (this.state.PricePlans.length > 1) {
              for (let i = 0; i < this.state.PricePlans.length; i++) {
                if (
                  plan.pricePlan == this.state.PricePlans[i].value.pricePlan
                ) {
                  alreadyPresent = true;
                  break;
                }
              }
              if (!alreadyPresent) {
                this.setState(
                  {
                    PricePlans: [...this.state.PricePlans, temp],
                    sessiondetails: {
                      ...this.state.sessiondetails,
                      selectedPlan: plan,
                    },
                  },
                  () => {
                    this.focusInput.current.focus();
                  }
                );
              } else {
                console.log("already Present");
              }
            } else {
              this.setState(
                {
                  PricePlans: [...this.state.PricePlans, temp],
                  sessiondetails: {
                    ...this.state.sessiondetails,
                    selectedPlan: plan,
                  },
                },
                () => {
                  this.focusInput.current.focus();
                }
              );
            }
          }
        );
      } else {
        let sessionarray = [];
        sessionarray.push({
          id: s.Instructor_ID,
          name: s.FullName,
          size: "large",
        });
        this.setState(
          {
            InstructorPlaceHolder: sessionarray,
            editable: true,
            sessiondetails: {
              ...this.state.sessiondetails,
              s_id: s.session_id,
              sessiontitle: s.Session_Name,
              sessionInstructor: s.Instructor_ID,
              CPDPoints: s.CPDPoints,
              startDate: new Date(s.Session_StartDate),
              endDate: new Date(s.Session_EndDate),
              registrationstatus: s.Registration,
              totalSeats: s.total_seats,
              planplaceholder: s.pricing_plan,
              selectedPlan: { pricePlan: "", price: "", discount: "" },
              priceType: s.pricing_type,
              pricePlan: s.pricing_plan,
              price: s.price,
              discount: s.discount,
            },
          },
          () => {
            this.focusInput.current.focus();
          }
        );
      }
    });
  };

  saveForm = () => {
    this.props.setsessionDetails(this.state.sessions);
  };

  editform = () => {
    if (
      this.state.sessiondetails.sessiontitle !== "" &&
      this.state.sessiondetails.sessionInstructor !== "" &&
      this.state.sessiondetails.CPDPoints !== ""
    ) {
      let data;
      const instructorId = this.state.sessionInstructor;
      const resultValidation = this.validate(this.state.sessiondetails);
      if (resultValidation.isValidated) {
        if (this.state.sessiondetails.priceType == "Free") {
          this.setState(
            {
              sessiondetails: {
                ...this.state.sessiondetails,
                price: 0,
                discount: 0,
              },
            },
            () => {
              if (this.props.isEdit == false) {
                data = {
                  session: this.state.sessiondetails,
                  c_id: this.props.webinarDetails.insertId,
                };
              } else {
                data = {
                  session: this.state.sessiondetails,
                  c_id: this.props.currentWebinarData.CourseID,
                };
              }

              this.props.editSession(data);
              this.setState({
                InstructorPlaceHolder: this.state.InstPlace,
                editable: false,
                sessiondetails: {
                  ...this.state.sessiondetails,
                  s_id: "",
                  sessiontitle: "",
                  selectedPlan: { pricePlan: "", price: "", discount: "" },
                  CPDPoints: "",
                  startDate: new Date(),
                  totalSeats: 0,
                  endDate: new Date(),
                  registrationstatus: "Open",
                  priceType: "Free",
                  pricePlan: "",
                  price: 0,
                  discount: 0,
                },
              });
              obj.createNotification("success", "Webinar Session Edited");
            }
          );
        } else {
          if (this.props.isEdit == false) {
            data = {
              session: this.state.sessiondetails,
              c_id: this.props.webinarDetails.insertId,
            };
          } else {
            data = {
              session: this.state.sessiondetails,
              c_id: this.props.currentWebinarData.CourseID,
            };
          }
          this.props.editSession(data);
          this.setState({
            editable: false,
            InstructorPlaceHolder: this.state.InstPlace,
            sessiondetails: {
              ...this.state.sessiondetails,
              s_id: "",
              sessiontitle: "",
              selectedPlan: { pricePlan: "", price: "", discount: "" },
              CPDPoints: "",
              startDate: new Date(),
              sessionInstructor: "",
              totalSeats: 0,
              endDate: new Date(),
              registrationstatus: "Open",
              priceType: "Free",
              pricePlan: "",
              price: 0,
              discount: 0,
            },
          });
          obj.createNotification("success", "Webinar Session Edited");
        }
      } else {
        obj.createNotification("error", resultValidation.errors);
      }
    } else {
      obj.createNotification("error", "Fill Required Fields");
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className=" col-md-12">
          <div className="row">
            <div className="col-md-5">
              <h3 className="card-title bold regular_font">Sessions</h3>
              <div className="col lrp-0">
                <span>
                  {/* <b>Course title:</b>
                  {this.state.courseTitle != undefined
                    ? " (" + this.state.courseTitle + ")"
                    : ""} */}
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {this.state.loader ? (
          <div className="loaderDiv text-center">
            <Loader type="Oval" color="#06580e" height={80} width={80} />
          </div>
        ) : (
          <div className="row">
            <div className="form-group col-md-6">
              <label>
                Title <span className="clr-red">*</span>
              </label>
              <input
                type="text"
                value={this.state.sessiondetails.sessiontitle}
                placeholder="Enter session title"
                className="form-control admin-form-control"
                name="sessiontitle"
                onChange={this.handleChange1}
                required
                ref={this.focusInput}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Resource Person <span className="clr-red">*</span>
              </label>
              <ReactSuperSelect
                // placeholder={
                //   this.props.isEdit == true
                //     ? this.state.InstructorPlaceHolder
                //     : "Select Instructor"
                // }
                initialValue={this.state.InstructorPlaceHolder}
                clearSearchOnSelection={true}
                deselectOnSelectedOptionClick={false}
                dataSource={this.state.InstructorSet}
                onChange={this.handlerExample}
                searchable={true}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Start Date</label>
              <br />
              <DatePicker
                showTimeSelect
                className="form-control w-100"
                selected={this.state.sessiondetails.startDate}
                dateFormat="MMMM d, yyyy h:mm aa"
                // minDate={new Date()}
                onChange={this.handleChangeStart}
              />
            </div>
            <div className="form-group col-md-6">
              <label>End Date</label>
              <br />
              <DatePicker
                showTimeSelect
                className="form-control w-100"
                selected={this.state.sessiondetails.endDate}
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={this.state.sessiondetails.startDate}
                onChange={this.handleChangeStart2}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                CPD Points <span className="clr-red">*</span>
              </label>
              <input
                type="number"
                min={0.5}
                max={5}
                step={0.1}
                value={this.state.sessiondetails.CPDPoints}
                placeholder="Enter CPD Points"
                className="form-control admin-form-control"
                name="CPDPoints"
                onChange={this.handleChange1}
                
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                Total Seats <span className="clr-red">*</span>
              </label>
              <input
                type="number"
                min={0}
                value={this.state.sessiondetails.totalSeats}
                placeholder="Enter seats"
                className="form-control admin-form-control"
                name="totalSeats"
                onChange={this.handleChange1}
                required
              />
            </div>

            <div className="form-group col-md-6">
              <label>Registration Status</label>

              <select
                className="form-control admin-form-control"
                value={this.state.sessiondetails.registrationstatus}
                onChange={this.handleChange1}
                name="registrationstatus"
              >
                <option value="Close">Closed</option>
                <option value="Open">Open </option>
              </select>
            </div>
            <div className="form-group col-md-6"></div>
            <div className="form-group col-md-6">
              <label htmlFor="validationDefault01">
                Price Type<span className="co_red">*</span>
              </label>
              <div className="fz_16">
                <select
                  name="priceType"
                  onChange={this.handleChange1}
                  value={this.state.sessiondetails.priceType}
                  id="validationDefault01"
                  className="form-control co_black"
                >
                  <option value="Free">Free of Cost</option>
                  <option value="OneTimePurchase">One Time Purchase</option>
                </select>
              </div>
            </div>

            {this.state.sessiondetails.priceType !== "Free" && (
              <div className="form-group col-md-4">
                <label>Select Plan </label>
                <Select
                  options={this.state.PricePlans}
                  placeholder={
                    this.state.sessiondetails.planplaceholder !==
                    "Select a Plan"
                      ? this.state.sessiondetails.planplaceholder
                      : "Select a Plan"
                  }
                  value={this.state.selectedPlan}
                  onChange={this.onChangeSelectPlan}
                />
              </div>
            )}
            {this.state.sessiondetails.priceType !== "Free" && (
              <div className="form-group w-100 col-md-2 btn_div">
                <button className="btn btn-save  bold" onClick={this.addPlan}>
                  Add Plan
                </button>
              </div>
            )}

            {this.state.createPlanFlag == true &&
              this.state.sessiondetails.priceType !== "Free" && (
                <div className="form-group col-md-6">
                  <label htmlFor="validationDefault02">
                    Plan Name<span className="co_red">*</span>
                  </label>
                  <input
                    type="text"
                    name="pricePlan"
                    onChange={this.handleChange1}
                    value={this.state.sessiondetails.pricePlan}
                    className="form-control fz_16"
                    placeholder="Enter plan name"
                    required
                  />
                </div>
              )}

            {this.state.createPlanFlag == true &&
              this.state.sessiondetails.priceType !== "Free" && (
                <div className="form-group col-md-6">
                  <label htmlFor="validationDefault03">
                    Price in Rs<span className="co_red">*</span>
                  </label>
                  <input
                    type="text"
                    name="price"
                    onChange={this.handleChange1}
                    value={this.state.sessiondetails.price}
                    placeholder="Enter Price in Rs"
                    className="form-control fz_16"
                    required
                  />
                </div>
              )}
            {this.state.createPlanFlag &&
              this.state.sessiondetails.priceType !== "Free" && (
                <div className="form-group col-md-6">
                  <label htmlFor="validationDefault04">Discount in Rs</label>
                  <input
                    type="text"
                    name="discount"
                    onChange={this.handleChange1}
                    value={this.state.sessiondetails.discount}
                    placeholder="Enter Discount in Rs"
                    className="form-control fz_16"
                    required
                  />
                </div>
              )}
            {this.state.createPlanFlag &&
              this.state.sessiondetails.priceType !== "Free" && (
                <div className="btn_div">
                  <button
                    className="btn btn-save  bold"
                    onClick={
                      this.state.editflag ? this.editPlan : this.createPlan
                    }
                  >
                    {this.state.editflag ? "Save Plan" : "Create Plan"}
                  </button>
                </div>
              )}

            {this.state.editable == true ? (
              <div className="col-md-12 mtb-10">
                &nbsp;
                <button
                  className="btn btn-save fix_width_btn   bold fz_14"
                  onClick={this.editform}
                >
                  Save Session
                </button>
              </div>
            ) : (
              <div className="col-md-12 mtb-10 align-left">
                <button
                  className="btn btn-save fix_width_btn   bold fz_14"
                  onClick={this.addSection}
                >
                  Save Session
                </button>
              </div>
            )}
          </div>
        )}
        <div className="CreatedSessions">
          <div className="row createdsessionheader">
            <div className="col-md-1 fs-14">Title</div>
            <div className="col-md-2 fs-14">Instructor</div>
            <div className="col-md-2 fs-14">Start Date</div>
            <div className="col-md-2 fs-14">End Date</div>
            <div className="col-md-1 fs-14">CPD</div>
            <div className="col-md-1 fs-14">Total Seats</div>
            <div className="col-md-1 fs-14">Status</div>
            <div className="col-md-1 fs-14">Price</div>

            <div className="col-md-1 fs-14"></div>
          </div>

          {this.props.sessions && this.props.sessions.length > 0 ? (
            this.props.sessions.map((item, key) => (
              <div key={item.session_id} className="row createdsessionDiv">
                <div className="col-md-1 fs-14">{item.Session_Name}</div>
                <div className="col-md-2 fs-14">{item.FullName}</div>
                <div className="col-md-2 fs-14">
                  <Moment format="DD-MM-YY hh:mm a">
                    {item.Session_StartDate}
                  </Moment>
                </div>
                <div className="col-md-2 fs-14">
                  <Moment format="DD-MM-YY hh:mm a">
                    {item.Session_EndDate}
                  </Moment>
                </div>
                <div className="col-md-1 fs-14">{item.CPDPoints}</div>
                <div className="col-md-1 fs-14">{item.total_seats}</div>
                <div className="col-md-1 fs-14">{item.Registration}</div>

                <div className="col-md-1 fs-14">
                  {item.pricing_type == "Free" ? "Free" : item.price}
                </div>
                <div className="col-md-1 fs-14">
                  <button
                    className="cancel-file-btn lrm-5"
                    onClick={() => this.edit(item)}
                  >
                    <i className="fa fa-edit co_green" aria-hidden="true" />
                  </button>
                  <button
                    className="cancel-file-btn "
                    onClick={() => this.delete(item.session_id)}
                  >
                    <i className="fa fa-trash delbutton" aria-hidden="true" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <h4 className="mtb-20 text-center ">No Sessions created yet</h4>
          )}

          {/* {
          this.state.sessions.length>0 
          ?
              this.state.sessions.map((item,key)=> 
              <div key={key} className="row createdsessionDiv"> 
                <div  className="col-md-2 fs-14">
                 {item.sessiontitle}
                </div>
                <div className="col-md-2 fs-14">
                  {item.sessionInstructor}
                </div>
                <div className="col-md-2 fs-14">
                  <Moment format='dddd Do MMMM, YYYY'>{item.startDate}</Moment>
                </div>
                <div className="col-md-2 fs-14">
                <Moment format='dddd Do MMMM, YYYY'>{item.endDate}</Moment>
                </div>
                <div className="col-md-1 fs-14">
                {item.CPDPoints}
                </div>
                <div className="col-md-2 fs-14">
                {item.registrationstatus}
                </div>
                <div className="col-md-1 fs-14">
                <button className="cancel-file-btn" onClick={()=>this.delete(key)}><i className="fa fa-trash delbutton" aria-hidden="true" /></button>
                </div>
                
              </div>
            
            ):  
            <h4 className="mtb-20 text-center ">No Sessions created yet</h4>
        } */}
        </div>

        {this.props.isEdit == true &&
        ((this.props.User.RoleID && this.props.User.RoleID === 8) ||
          (this.props.User.RoleID && this.props.User.RoleID === 7) ||
          (this.props.User.RoleID && this.props.User.RoleID === 3) ||
          (this.props.User.RoleID && this.props.User.RoleID === 4) ||
          (this.props.User.RoleID && this.props.User.RoleID === 5)) ? (
          <Feedback_Webinar
            webinarId={this.props.currentWebinarData.CourseID}
            owner={this.props.currentWebinarData.user_id}
          />
        ) : (
          ""
        )}

        {/* <div className="col-md-12 " >
              &nbsp;
              <button
                className="btn float-right btn-save fix_width_btn   bold fz_14"
                onClick={this.saveForm}
                {...(this.state.sessions.length>0
                  ? { disabled: false }
                  : { disabled: true })}
              >
                Save
              </button>
              &nbsp;
              
            </div> */}

        {/* <PaperSection
          items={this.state.items}
          courseType={this.state.courseType}
          // keyProp={this.state.keyProp}
          // isEdit={this.state.isEdit}
          remapData={this.remapData}
        /> */}
        {/* {this.props.isEdit == true && (this.props.User.RoleID !== 7 || this.props.User.RoleID !== 8 || this.props.User.RoleID === 3 || this.props.User.RoleID === 4) &&
          <div className="col-md-12">
            <button
              className="btn btn-save float-right bold "
              // style={{marginTop:'7px'}}
              onClick={this.addSection}
            >
              {this.props.isEdit == true && "Update and Next"}
            </button>
          </div>} */}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessions: state.webinarData.webinarSessions,
    webinarDetails: state.webinarData.webinarDetails,
    User: state.userData.admin,
  };
}

export default connect(mapStateToProps, {
  getInstructors,
  get_sessions,
  editSession,
  updatePaymentPlan,
  add_session,
  getPaymentPlans,
  addPricePlan,
  delete_session,
})(CCSessionsHome);

