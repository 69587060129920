import React, { Component, Fragment } from "react";
import Header from "../Header";
import WebNotif from "../../components/WebNotif";
import { withRouter } from "react-router-dom";
// import disciplineData from "../../config/discipline.json";

const disciplineData = [
  {
    id: 1,
    name: "ELECT",
    value: "ELECT",
  },
  {
    id: 2,
    name: "ELECTRO",
    value: "ELECTRO",
  },
  {
    id: 3,
    name: "TELE",
    value: "TELE",
  },
  {
    id: 4,
    name: "MECHATRO",
    value: "MECHATRO",
  },
  {
    id: 5,
    name: "BIOMEDICAL",
    value: "BIOMEDICAL",
  },
  {
    id: 6,
    name: "ENGG.SCI.",
    value: "ENGG.SCI.",
  },
  {
    id: 7,
    name: "COMP",
    value: "COMP",
  },
  {
    id: 8,
    name: "AVION",
    value: "AVION",
  },
  {
    id: 9,
    name: "CIVIL",
    value: "CIVIL",
  },
  {
    id: 10,
    name: "ARCH.ENGG",
    value: "ARCH.ENGG",
  },
  {
    id: 11,
    name: "ENVIRO",
    value: "ENVIRO",
  },
  {
    id: 12,
    name: "URBAN",
    value: "URBAN",
  },
  {
    id: 13,
    name: "MECH",
    value: "MECH",
  },
  {
    id: 14,
    name: "AERO",
    value: "AERO",
  },
  {
    id: 15,
    name: "INDUS",
    value: "INDUS",
  },
  {
    id: 16,
    name: "METAL",
    value: "METAL",
  },
  {
    id: 17,
    name: "CHEM",
    value: "CHEM",
  },
  {
    id: 18,
    name: "POLY",
    value: "POLY",
  },
  {
    id: 19,
    name: "MINING",
    value: "MINING",
  },
  {
    id: "20",
    name: "AGRI",
    value: "AGRI",
  },
  {
    id: "21",
    name: "TEXTILE",
    value: "TEXTILE",
  },
  {
    id: "22",
    name: "PETGAS",
    value: "PETGAS",
  },
  {
    id: "23",
    name: "GEOLOGY",
    value: "GEOLOGY",
  },
  {
    id: "24",
    name: "TRANSPORT",
    value: "TRANSPORT",
  },
  {
    id: "25",
    name: "AUTO",
    value: "AUTO",
  },
];

var obj = new WebNotif();

class EngineerSponsorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sponsor1PECNo: "",
      sponsor1Name: "",
      sponsor1Discipline: "", // Added discipline field
      sponsor1Experience: "", // Added experience field
      sponsor1Designation: "", // Added designation field
      reduxEpeForm: JSON.parse(localStorage.getItem("reduxEpeForm")) || {},
    };
  }

  componentDidMount() {
    const savedFormData = JSON.parse(localStorage.getItem("reduxEpeForm"));
    if (savedFormData && savedFormData.form) {
      const {
        sponsor1PECNo,
        sponsor1Name,
        sponsor1Discipline,
        sponsor1Experience,
        sponsor1Designation,
        status1,
      } = savedFormData.form;
      this.setState({
        sponsor1PECNo: sponsor1PECNo || "",
        sponsor1Name: sponsor1Name || "",
        sponsor1Discipline: sponsor1Discipline || "",
        sponsor1Experience: sponsor1Experience || "",
        sponsor1Designation: sponsor1Designation || "",
        status1: status1 || "",
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleKeyDown = (event) => {
    if (event.key === "." || event.key === ",") {
      event.preventDefault(); // Prevent the use of the dot or comma key
    }
  };

  saveToLocalStorage = () => {
    const {
      sponsor1PECNo,
      sponsor1Name,
      sponsor1Discipline,
      sponsor1Experience,
      sponsor1Designation,
      status1,
    } = this.state;

    // Validation for empty fields
    if (
      !sponsor1PECNo ||
      !sponsor1Name ||
      !sponsor1Discipline ||
      !sponsor1Experience ||
      !sponsor1Designation ||
      !status1
    ) {
      obj.createNotification("error", "All fields for Sponsor 1 are required.");
      return;
    }

    // Update reduxEpeForm with sponsor details
    const updatedForm = {
      ...this.state.reduxEpeForm,
      form: {
        ...this.state.reduxEpeForm.form,
        sponsor1PECNo,
        sponsor1Name,
        sponsor1Discipline,
        sponsor1Experience,
        sponsor1Designation,
        status1,
      },
    };

    // Save the updated reduxEpeForm back to localStorage
    localStorage.setItem("reduxEpeForm", JSON.stringify(updatedForm));
    obj.createNotification("success", "Sponsor details saved successfully.");

    // Redirect to preview page
    this.props.history.push("/engineer/application-preview");
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="container mt-4">
          <h3 className="text-center">Sponsor Details</h3>
          <h5 className="text-center mt-3 co_g20A84C font-weight-bold font-16 mb-4">
            Step 5: Add Sponsor Information
          </h5>

          {/* Sponsor 1 Details */}
          <div className="container" style={styles.container}>
            <h5>Sponsor 1 Details</h5>
            <div className="row mtb-15" style={{ gap: "15px 0px" }}>
              <div className="col-md-4">
                <label style={styles.label}>
                  PEC Registration Number <span style={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  name="sponsor1PECNo"
                  className="form-control"
                  value={this.state.sponsor1PECNo}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <label style={styles.label}>
                  Name <span style={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  name="sponsor1Name"
                  className="form-control"
                  value={this.state.sponsor1Name}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="col-md-4">
                <label style={styles.label}>
                  Discipline <span style={styles.required}>*</span>
                </label>
                {/* Discipline Dropdown for Sponsor 1 */}
                <select
                  name="sponsor1Discipline"
                  className="form-control"
                  value={this.state.sponsor1Discipline}
                  onChange={this.handleChange}
                  required
                >
                  <option disabled value="">
                    Select Discipline
                  </option>
                  {disciplineData.map((discipline) => (
                    <option key={discipline.value} value={discipline.value}>
                      {discipline.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <label style={styles.label}>
                  {" "}
                  Year of Experience <span style={styles.required}>*</span>
                </label>
                <input
                  type="number"
                  step="1"
                  name="sponsor1Experience"
                  className="form-control"
                  value={this.state.sponsor1Experience}
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                  required
                />
              </div>
              <div className="col-md-4">
                <label style={styles.label}>
                  Designation <span style={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  name="sponsor1Designation"
                  className="form-control"
                  value={this.state.sponsor1Designation}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <label style={styles.label}>
                  Status <span style={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  name="status1"
                  className="form-control"
                  value={this.state.status1}
                  onChange={this.handleChange}
                  required
                  maxLength={20}
                />
              </div>
            </div>
          </div>

          {/* Save Button */}
          <div className="row col_margin mt-40 mb-40 ">
            <div
              className="d-flex justify-content-end mt-4 mb-4"
              style={{ width: "95%" }}
            >
              <button
                type="button"
                className="btn admin_btn_default bold  float-right  text-capitalize"
                style={{ width: "200px" }}
                onClick={this.saveToLocalStorage}
              >
                Save and Preview
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
    marginBottom: "30px",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "5px",
    display: "block",
  },
  required: {
    color: "#06580e",
  },
};

export default withRouter(EngineerSponsorForm);
