import React, { Component } from "react";
import AddCourse from "./AddCourse";
import Addfaq from "./Addfaq";
import Loader from "react-loader-spinner";

import Assessments from "./Assessments";
import CCSessionsHome from "./CCSessionsHome";
import FreeCC from "./FreeCC";
import Stepper from "bs-stepper";
import $ from "jquery";
import { urlapi } from "../../config/urlapi";
import axios from "axios";
import WebNotif from "../WebNotif";
import { withRouter } from "react-router";
import { Prompt } from "react-router";
import "bs-stepper/dist/css/bs-stepper.min.css";

import { connect } from "react-redux";
import {
  publishwebinar,
  create_webinar_details,
  create_webinar_faqs,
  get_faqs,
  get_ExtraResources,
  edit_webinar_details,
  save_webinar_form,
  setFilesFirstSaveFlag,
  get_sessions,
  create_webinar_sessions,
} from "../../redux/admin/webinar/webinarActions";
var obj = new WebNotif();

var newKey = null;
class WebinarForm extends Component {
  nextStep = (comp) => {
    this.setState({ currentComp: comp });
  };
  state = {
    newKey: null,
    isEdit: false,
    courseTitle: null,
    activeStepper: "test-l-1",
    render: false,
    courseDateType: null,
    changeFlag: false,
    sessionFlag: false,
    update: true,
    shouldBlockNavigation: true,
    loading: false,
    loader:false,
    formdata: {
      webinarDetails: {},

      webinarSessions: {},
      webinarExams: {},
      webinarPricing: {},
      webinarFiles: [],
      faqs: [],
    },
    currentWebinarData: null,
  };
  componentWillMount() {
    if (this.props.webinars) {
      for (var i = 0; i < this.props.webinars.length; i++) {
        if (this.props.location.state.ID == this.props.webinars[i].CourseID) {
          this.setState(
            {
              isEdit: true,
              shouldBlockNavigation: false,
              currentWebinarData: this.props.webinars[i],
            }
          );
        }
      }
    }
  }

  componentDidMount() {
    if (this.state.currentWebinarData) {
      this.props.get_sessions(this.state.currentWebinarData.CourseID);
      this.props.get_ExtraResources(this.state.currentWebinarData.CourseID);
      this.props.get_faqs(this.state.currentWebinarData.CourseID);
    }

    $("#stepper1 div.bs-stepper-header div.step").bind("click", function () {
      //console.log($(this).removeAttr("background-color"));
      // $(this).next().css("border", "1px solid #d3d3d396");
      var x = document.getElementsByClassName("line");
      var i;
      for (i = 0; i < x.length; i++) {
        x[i].style.border = "1px solid #d3d3d396";
      }
      $(this).next().css("border", "1px solid #20A84C");
      // $(this).removeAttr("background-color");

      // alert( $('#stepper1 div.bs-stepper-header div.step ').index(this) );
    });

    // $( "div.bs-stepper-header>.step.active:eq( 2 )" ).css( "color", "red" );
    if (this.props.location.state.courseType == 2) {
      this.setState({ courseDateType: "0" });
    }
    this.stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    });
    if (
      this.props.keyProp == "null" ||
      this.props.keyProp == null ||
      this.props.keyProp == undefined
    ) {
      this.setState({
        currentComp: (
          <AddCourse
            keyProp={newKey}
            isEdit={this.state.isEdit}
            setCourseType={this.setCourse}
            setTitle={this.setTitle}
            courseType={this.props.location.state.courseType}
            isChanged={this.isChanged}
            changeFlag={this.state.changeFlag}
            courseTitle={this.state.courseTitle}
            //Getting Data Back from WebinarDetails
            setwebinarDetails={this.getwebinarDetails}
            onRef={(ref) => (this.child = ref)}
            //INCASE OF EDIT
            currentWebinarData={this.state.currentWebinarData}
            editwebinarDetails={this.getEditWebinarDetails}
          />
        ),
        newKey: newKey,

        render: true,
      });
    } else {
    }

    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }
  componentWillUnmount = () => {
    window.onbeforeunload = null;
  };
  callchildmethod = () => {
    this.child.changeSaveStatus();
  };
  callFileschildmethod = () => {
    this.fileChild.toggleLoading();
  };
  //CALLBACK FROM WEBINAR DETAILS STEPPER
  getwebinarDetails = (value) => {
    this.setState(
      { formdata: { webinarDetails: value.webinardetails }, loader: true },
      () => {
        // console.log(value.fd.values())
      //   let fd = value.fd
      // let urls = [];
      //   for (var val of value.fd.values()) {
      //     console.log(window.URL.createObjectURL(val));
      //     urls.push(window.URL.createObjectURL(val))
      //  }
      //  console.log(urls)
        const data = this.state.formdata.webinarDetails;
        let newData = []
        newData.push({data:this.state.formdata.webinarDetails});
        
        this.props
          .create_webinar_details(data)
          .then((res) => {
            axios
              .post(
                urlapi + `/admin/create_webinar_files/${res.insertId}`,
                value.fd,
                {
                  headers: {
                    accept: "application/json",
                    "Accept-Language": "en-US,en;q=0.8",
                    "Content-Type": `multipart/form-data ; boundary= ${value.fd._boundary}`,
                  },
                }
              )
              .then((data) => {
                let fileData = data.data.data;
                newData.push({file: data.data.data})
                this.setState()
                obj.createNotification(
                  "success",
                  "Successfully Saved Webinar Details"
                );
                this.props.save_webinar_form(newData);
                this.setState({
                  sessionFlag: true
                },()=>{
                this.stepperClick("test-l-9", "Sessions");
                this.stepper.next();
                this.callchildmethod();
              })
                this.setState({loader:false})
              })
              .catch((error) => { });
              
          })
          .catch((error) => {console.log(error)
            this.setState({loader:false})});
      }
    );
  };
  //CALLBACK FROM WEBINAR SESSIONS
  getSessionDetails = () => {
    this.setState({
      shouldBlockNavigation: false,
    });
  };

  //CALLBACK FROM WEBINAR FILES

  getWebinarFiles = (value) => {
    if (this.props.webinarDetails.insertId) {
      this.setState(
        {
          formdata: {
            ...this.state.formdata,
            webinarFiles: value.webinarFiles,
          },
        },
        () => {
          axios
            .post(
              urlapi +
              `/admin/create_webinar_extraResources/${this.props.webinarDetails.insertId}`,
              value.fd,
              {
                headers: {
                  accept: "application/json",
                  "Accept-Language": "en-US,en;q=0.8",
                  "Content-Type": `multipart/form-data ; boundary= ${value.fd._boundary}`,
                },
              }
            )
            .then((res) => {
              obj.createNotification("success", "Successfully Saved Files");

              this.callFileschildmethod();
              this.stepperClick("test-l-8", "Assessments");
              this.stepper.next();
            })
            .catch((error) => { });
        }
      );
    } else {
      obj.createNotification("error", "Save Webinar Details first");
    }
  };
  //CALLBACK FROM ADDFAQS
  getFaqs = (value) => {
    if (this.props.webinarDetails.insertId) {
      this.setState(
        { formdata: { ...this.state.formdata, faqs: value } },
        () => {
          this.props
            .create_webinar_faqs({
              data: this.state.formdata.faqs,
              id: this.props.webinarDetails.insertId,
            })
            .then((res) => {
              obj.createNotification("success", "Successfully Saved FAQS");
            })
            .catch((err) => {
              console.log(err);
              obj.createNotification("error", "try again");
            });
        }
      );
    } else {
      obj.createNotification("error", "Save Webinar Details first");
    }
  };
  //PUBLISJH WEBINAR IF ALL VALUES VALIDATED
  validateAndPublish = () => {
    if (this.props.webinarDetails.insertId) {
      this.props
        .publishwebinar(this.props.webinarDetails.insertId)
        .then((res) => {
          obj.createNotification("success", "Webinar Published");

          this.props.history.push("/admin/dashboard/webinar");
        });
    } else {
      obj.createNotification("error", "Complete Webinar Details & Sessions");
    }
  };
  //Save Draft WEBINAR IF ALL VALUES VALIDATED
  savedraft = () => {
    if (this.props.webinarDetails.insertId) {
      this.props
        .savedraft(this.props.webinarDetails.insertId)
        .then((res) => {
          obj.createNotification("success", "Webinar Published");

          // this.props.history.push("/admin/dashboard/webinar");
        });
    } else {
      obj.createNotification("error", "Complete Webinar Details & Sessions");
    }
  };

  //getEditWebinarDetails
  getEditWebinarDetails = (value) => {
    var data = value.webinardetails;
    if (this.props.webinarDetails.insertId) {
      data = {
        ...data,
        courseID: this.props.webinarDetails.insertId,
      };
    } else {
      data = {
        ...data,
        courseID: this.state.currentWebinarData.CourseID,
      };
    }
    const fd = new FormData();
   
    this.props.edit_webinar_details(data).then((res) => {
      if (data.files.thumbnailImage) {
        fd.append("thumbnailImage", data.files.thumbnailImage);
        //fd.append('previousImage', this.state.currentWebinarData.Image_URL)
      }
      if (data.files.thumbnailVideo) {
        fd.append("thumbnailVideo", data.files.thumbnailVideo);
        //fd.append('previousVideo', this.state.currentWebinarData.Preview_VideoURL)
      }
      if (data.files.bannerImage) {
        fd.append("bannerImage", data.files.bannerImage);
        //fd.append('previousBannerImage', this.state.currentWebinarData.BannerImage_URL)
      }
      if (data.files.recordedWebinarVideo) {
        fd.append("recordedWebinarVideo", data.files.recordedWebinarVideo);
        // fd.append('previousRecordedVideo', this.state.currentWebinarData.Recorded_URL)
      }
      axios
        .post(urlapi + `/admin/edit_webinar_files/${data.courseID}`, fd, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data ; boundary= ${fd._boundary}`,
          },
        })
        .then((data) => {
          obj.createNotification(
            "success",
            "Successfully Edited Webinar Files"
          );
        })
        .catch((error) => {
          console.log(error);
        });

      obj.createNotification("success", "Successfully Edited Webinar Details");

      this.stepperClick("test-l-9", "Sessions");
      this.stepper.next();
      this.callchildmethod();
    });
  };

  AddMoreExtraFiles = (data) => {
    if (this.props.webinarDetails.insertId) {
      axios
        .post(
          urlapi +
          `/admin/create_webinar_extraResources/${this.props.webinarDetails.insertId}`,
          data.fd,
          {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
              "Content-Type": `multipart/form-data ; boundary= ${data.fd._boundary}`,
            },
          }
        )
        .then((res) => {
          obj.createNotification("success", "Successfully Saved Files");
          this.callFileschildmethod();
          this.stepperClick("test-l-6", "FAQ");
          this.stepper.next();
          this.props.get_ExtraResources(this.props.webinarDetails.insertId);

          //dispatch => dispatch(photographerUpdateRequest(res.data.photographer));
        })
        .catch((error) => { });
    } else {
      axios
        .post(
          urlapi +
          `/admin/create_webinar_extraResources/${this.state.currentWebinarData.CourseID}`,
          data.fd,
          {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
              "Content-Type": `multipart/form-data ; boundary= ${data.fd._boundary}`,
            },
          }
        )
        .then((res) => {
          obj.createNotification("success", "Successfully Saved Files");
          this.callFileschildmethod();
          this.props.get_ExtraResources(this.state.currentWebinarData.CourseID);
          this.nextFromFiles();

          //dispatch => dispatch(photographerUpdateRequest(res.data.photographer));
        })
        .catch((error) => { });
    }
  };

  getEditFaqs = (data) => {
    this.props
      .create_webinar_faqs({
        data: data,
        id: this.state.currentWebinarData.CourseID,
      })
      .then((res) => {
        this.props.get_faqs(this.state.currentWebinarData.CourseID);
        obj.createNotification("success", "Successfully Saved FAQS");
      })
      .catch((err) => {
        console.log(err);
        obj.createNotification("error", "try again");
      });
  };

  nextFromFiles = () => {
    this.stepperClick("test-l-8", "Assessments");
    this.stepper.next();
  };
  nextFromAssessment = () => {
    this.stepperClick("test-l-6", "Faqs");
    this.stepper.next();
  };

  isChanged = (flag) => {
    this.setState({ changeFlag: flag });
  };
  setTitle = (value) => {
    this.setState({ courseTitle: value });
  };

  isUpdate = () => {
    this.setState({ update: false });
    this.setState({ update: true });
  };
  setCourse = (dataType) => {
    // set course type between live and recorded

    this.setState({ courseDateType: dataType });
  };
  //post data here
  //generate template on types
  //maintain previous node here
  stepperClick = (stepper_id, title) => {
    this.setState({ activeStepper: stepper_id, activeStepper_title: title });
  };
  steppernext = () => { };
  render() {
    return (
      <React.Fragment>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message="You have unsaved changes, are you sure you want to leave?"
        />

        <div className="stepper-body">
          <div id="stepper1" className="bs-stepper stepper_center">
            <div>
              <div className="bs-stepper-header ptb-20 steper_back_box">
                <div
                  className="step"
                  data-target="#test-l-1"
                  onClick={() => this.stepperClick("test-l-1", "Details")}
                >
                  <a href="#">
                    <div className="stepper-box">
                      {this.state.activeStepper == "test-l-1" ? (
                        <React.Fragment>
                          <span className="bs-stepper-label fontz_b fs-11">
                            DETAILS
                          </span>
                          <br></br>
                          <span
                            className="bs-stepper-circle stepper-active"
                            title="Details"
                          ></span>
                          <div>
                            <i
                              className="fa fa-caret-down co_g20A84C"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </React.Fragment>
                      ) : (
                          <React.Fragment>
                            <span className="bs-stepper-label fontz_b fs-11">
                            DETAILS
                          </span>
                            <br></br>
                            <span
                              className="bs-stepper-circle "
                              title="Details"
                            ></span>
                          </React.Fragment>
                        )}
                    </div>
                  </a>
                </div>
                <div className="line" />
                {/* <div
                  className={this.props.location.state.courseType == 0 ? " step show" : "hide"}
                data-target="#test-l-5"
                onClick={() => this.stepperClick("test-l-5", "Features")}
              >
                <a href="#">
                  <div className="stepper-box">
                    {this.state.activeStepper == "test-l-5" ? (
                      <React.Fragment>
                       <span className="bs-stepper-label fontz_b fs-11">Features</span>
                       <br></br>
                        <span
                          className="bs-stepper-circle stepper-active"
                          title="Features"
                        >
                        </span>
                        <div>
                            <i className="fa fa-caret-down co_g20A84C" aria-hidden="true"></i> 
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span className="bs-stepper-label fontz_b fs-11">Features</span>
                        <br></br>
                        <span className="bs-stepper-circle " title="Features">
                       </span>
                      </React.Fragment>
                    )}

                    <span className="bs-stepper-label" />
                  </div>
                </a>
              </div>
              <div className={this.props.location.state.courseType == 0 ? " line show" : "hide"} />
              <div
                className={this.props.location.state.courseType == 0 ? " step show" : "hide"}
                data-target="#test-l-2"
                onClick={() => this.stepperClick("test-l-2", "Topics")}
              >
                <a href="#">
                  <div className="stepper-box">
                    {this.state.activeStepper == "test-l-2" ? (
                      <div>
                      <span className="bs-stepper-label fontz_b fs-11">Topics</span>
                       <br></br>
                      <span
                        className="bs-stepper-circle stepper-active"
                        title="Topics"
                      >
                      </span>
                         <div>
                          <i className="fa fa-caret-down co_g20A84C" aria-hidden="true"></i> 
                      </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <span className="bs-stepper-label fontz_b fs-11">Topics</span>
                        <br></br>
                        <span className="bs-stepper-circle " title="Topics">
                         </span>
                      </React.Fragment>
                    )}
                    <span className="bs-stepper-label" />
                  </div>
                </a>
              </div>
              <div
                className={+this.props.location.state.courseType == 0 ? "line show" : "hide"}
              />
              <div
                 className={this.props.location.state.courseType == 2 ? "hide" : "hide"}
                data-target="#test-l-3"
                onClick={() =>
                  this.stepperClick(
                    "test-l-3",
                    this.props.location.state.courseType == 0
                      ? "Questions"
                      : this.props.location.state.courseType == 1
                      ? "Paper"
                      : this.props.location.state.courseType == 2
                      ? "Content "
                      : ""
                  )
                }
              >
                <a href="#">
                  <div className="stepper-box">
                    {this.state.activeStepper == "test-l-3" ? (
                      <div>
                     <span className="bs-stepper-label fontz_b fs-11">Questions</span>
                       <br></br>
                      <span
                        className="bs-stepper-circle stepper-active"
                        title={
                          this.props.location.state.courseType == 0
                            ? ""
                            : this.props.location.state.courseType == 1
                            ? ""
                            : this.props.location.state.courseType == 2
                            ? " "
                            : ""
                        }
                      >
                        {this.props.location.state.courseType == 0
                          ? ""
                          : this.props.location.state.courseType == 1
                          ? ""
                          : this.props.location.state.courseType == 2
                          ? " "
                          : ""}
                      </span>
                         <div>
                          <i className="fa fa-caret-down co_g20A84C" aria-hidden="true"></i> 
                      </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <span className="bs-stepper-label fontz_b fs-11">
                          {this.props.location.state.courseType == 0
                            ? "Questions"
                            : this.props.location.state.courseType == 1
                            ? "Paper"
                            : this.props.location.state.courseType == 2
                            ? "Content "
                            : ""}
                        </span>
                        <br></br>
                        <span
                          className="bs-stepper-circle "
                          title={
                            this.props.location.state.courseType == 0
                              ? "Questions"
                              : this.props.location.state.courseType == 1
                              ? "Paper"
                              : this.props.location.state.courseType == 2
                              ? "Content "
                              : ""
                          }
                        >
                          {this.props.location.state.courseType == 0
                            ? ""
                            : this.props.location.state.courseType == 1
                            ? ""
                            : this.props.location.state.courseType == 2
                            ? ""
                            : ""}
                        </span>
                      </React.Fragment>
                    )}

                    <span className="bs-stepper-label" />
                  </div>
                </a>
              </div> */}
                {/* <div    className={this.props.location.state.courseType == 2 ? "line hide" : "hide"} /> */}
                {/* start extra steper for course */}
              
                <div
                  className={
                    +(this.state.courseDateType && this.state.courseDateType) == "0" &&
                      (this.props.location.state.courseType && this.props.location.state.courseType) == 2
                      ? " step show"
                      : "hide"
                  }
                  data-target="#test-l-9"
                  onClick={() => this.stepperClick("test-l-9", "Sessions")}
                >
                  <a href="#">
                    <div className="stepper-box">
                      {this.state.activeStepper == "test-l-9" ? (
                        <div>
                          <span className="bs-stepper-label fontz_b fs-11">
                            SESSIONS
                          </span>
                          <br></br>
                          <span
                            className="bs-stepper-circle stepper-active"
                            title="Sessions"
                          ></span>
                          <div>
                            <i
                              className="fa fa-caret-down co_g20A84C"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      ) : (
                          <React.Fragment>
                            <span className="bs-stepper-label fontz_b fs-11">
                            SESSIONS
                          </span>
                            <br></br>
                            <span
                              className="bs-stepper-circle "
                              title="Sessions"
                            ></span>
                          </React.Fragment>
                        )}
                    </div>
                  </a>
                </div>

                <div
                  className={
                    this.state.courseDateType == "0" &&
                      this.props.location.state.courseType == 2
                      ? "line show"
                      : "hide"
                  }
                />
                {/* end extra steper for course */}

                <div
                  className={
                    this.props.location.state.courseType == 2
                      ? " step show"
                      : "hide"
                  }
                  data-target="#test-l-7"
                  onClick={() => this.stepperClick("test-l-7", "Files")}
                >
                  <a href="#">
                    <div className="stepper-box">
                      {this.state.activeStepper == "test-l-7" ? (
                        <div>
                          <span className="bs-stepper-label fontz_b fs-11">
                            FILES
                          </span>
                          <br></br>
                          <span
                            className="bs-stepper-circle stepper-active"
                            title="Files"
                          ></span>
                          <div>
                            <i
                              className="fa fa-caret-down co_g20A84C"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      ) : (
                          <React.Fragment>
                            <span className="bs-stepper-label fs-11">FILES</span>{" "}
                            <br></br>
                            <span
                              className="bs-stepper-circle "
                              title="Files"
                            ></span>
                          </React.Fragment>
                        )}
                      <span className="bs-stepper-label" />
                    </div>
                  </a>
                </div>
                <div
                  className={
                    +this.props.location.state.courseType == 2
                      ? "line show"
                      : "hide"
                  }
                />

                <div
                  className="step"
                  data-target="#test-l-8"
                  onClick={() => this.stepperClick("test-l-8", "Assessments")}
                >
                  <a href="#">
                    <div className="stepper-box">
                      {this.state.activeStepper == "test-l-8" ? (
                        <div>
                          <span className="bs-stepper-label fontz_b ">
                            ASSESSMENTS
                          </span>
                          <br></br>
                          <span
                            className="bs-stepper-circle stepper-active"
                            title="Assessments"
                          ></span>
                          <div>
                            <i
                              className="fa fa-caret-down co_g20A84C"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      ) : (
                          <React.Fragment>
                            <span className="bs-stepper-label fontz_b ">
                            ASSESSMENTS
                          </span>
                            <br></br>
                            <span
                              className="bs-stepper-circle "
                              title="Assessments"
                            ></span>
                          </React.Fragment>
                        )}
                      <span className="bs-stepper-label" />
                    </div>
                  </a>
                </div>
                <div
                  className={
                    +this.props.location.state.courseType == 2
                      ? "line show"
                      : "hide"
                  }
                />

                <div
                  className="step"
                  data-target="#test-l-6"
                  onClick={() => this.stepperClick("test-l-6", "Faq")}
                >
                  <a href="#">
                    <div className="stepper-box">
                      {this.state.activeStepper == "test-l-6" ? (
                        <div>
                          <span className="bs-stepper-label fontz_b fs-11">
                            FAQ
                          </span>
                          <br></br>
                          <span
                            className="bs-stepper-circle stepper-active"
                            title="Faq"
                          ></span>
                          <div>
                            <i
                              className="fa fa-caret-down co_g20A84C"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      ) : (
                          <React.Fragment>
                            <span className="bs-stepper-label fontz_b fs-11">
                            FAQ
                          </span>
                            <br></br>
                            <span
                              className="bs-stepper-circle "
                              title="Faq"
                            ></span>
                          </React.Fragment>
                        )}
                      <span className="bs-stepper-label" />
                    </div>
                  </a>
                </div>
              </div>
            </div>

            {/* stepper content  */}

            <div className="bs-stepper-content col">
              <div id="test-l-1" className="content">
                <div className="container form-container">
                  <div className="admin-card steper_below card">
                    <div className="card-body">
                      <div className="form-group">{this.state.loader ?   <div className="loaderDiv text-center">
                          <Loader
                            type="Oval"
                            color="#06580e"
                            height={80}
                            width={80}
                          />
                        </div> : this.state.currentComp}</div>
                      <div className="col-md-12 col_up">
                        {/* <div className="float-left">
                          <button
                            type="button"
                            className="btn admin_btn "
                            onClick={() => {
                              this.stepperClick("test-l-1", "Details");
                              this.stepper.previous();
                            }}
                            disabled
                          >
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp; Back
                          </button>
                        </div> */}
                        <div className="float-right">
                          <button
                            type="button"
                            className="btn admin_btn"
                            onClick={() => {
                              this.stepperClick("test-l-9", "Sessions");
                              this.stepper.next();
                            }}
                          >
                            {" "}
                            Next &nbsp;
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="test-l-2"
                className={
                  this.props.location.state.courseType != 0 && this.state.render
                    ? "content hideimp"
                    : "content"
                }
              >
                <div className="container form-container">
                  <div className="admin-card steper_below card">
                    <div className="card-body">
                      <div className="form-group"></div>
                      <div className="col-md-12">
                        <div className="float-right">
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="button"
                            className="btn admin_btn"
                            onClick={() => this.stepper.next()}
                          >
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp; Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="test-l-5" className="content">
                <div className="container form-container">
                  <div className="admin-card steper_below card">
                    <div className="card-body">
                      <div className="form-group">
                        {/* 0= course faq 1= website general faq */}
                        {/* {this.state.render ? (
                            <Coursefeature
                              keyProp={"courses/" + newKey + "/courseFeatures/"}
                              isEdit={this.state.isEdit}
                              permissions={this.props.permissions}
                              courseTitle={this.state.courseTitle}
                            />
                          ) : (
                            ""
                          )} */}
                      </div>
                      <div className="col-md-12">
                        <div className="float-right">
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="button"
                            className="btn admin_btn"
                            onClick={() => this.stepper.next()}
                          >
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp; Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="test-l-6" className="content">
                <div className="container form-container">
                  <div className="admin-card steper_below card">
                    <div className="card-body">
                      <div className="form-group">
                        {/* 0= course faq 1= website general faq */}

                        {this.state.render ? (
                          <Addfaq
                            keyProp={"courses/" + newKey + "/"}
                            isEdit={this.state.isEdit}
                            courseTitle={this.state.courseTitle}
                            currentWebinarData={this.state.currentWebinarData}
                            setFaqs={this.getFaqs}
                            setEditFaqs={this.getEditFaqs}
                            permissions={this.props&&this.props.permissions}
                            publishWebinar={this.validateAndPublish}
                            savedraft={this.savedraft}
                          />
                        ) : (
                            ""
                          )}
                      </div>
                      <div className="col-md-12">
                        <div className="float-left">
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="button"
                            className="btn admin_btn"
                            onClick={() => {
                              this.stepperClick("test-l-8", "Assessments");
                              this.stepper.previous();
                            }}
                          >
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp; Back &nbsp;
                          </button>
                        </div>
                        {/* <div className="float-right">
                          <button
                            type="button"
                            className="btn admin_btn float-right"
                            disabled
                          >
                            Next &nbsp;
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="test-l-9"
                className={
                  (this.props.location.state.courseType != 2 ||
                    this.state.courseDateType == null) &&
                    this.state.render
                    ? "content hideimp"
                    : "content"
                }
              >
                <div className="container form-container">
                  <div className="admin-card steper_below card">
                    <div className="card-body">
                      <div className="form-group">
                        {this.state.render == "1" ? (
                          <CCSessionsHome
                            courseType={this.props.location.state.courseType}
                            keyProp={newKey}
                            changeFlag={this.state.changeFlag}
                            update={this.state.update}
                            isEdit={this.state.isEdit}
                            render={this.state.render}
                            courseTitle={this.state.courseTitle}
                            isUpdate={this.isUpdate}
                            currentWebinarData={this.state.currentWebinarData}
                            seteditsessionDetails={this.geteditsessionDetails}
                            setsessionDetails={this.getSessionDetails}
                          />
                        ) : (
                            ""
                          )}
                      </div>
                      <div className="col-md-12">
                        <div className="float-left">
                          <button
                            type="button"
                            className="btn admin_btn "
                            onClick={() => {
                              this.stepperClick("test-l-1", "Details");
                              this.stepper.previous();
                            }}
                          >
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp; Back
                          </button>
                        </div>
                        <div className="float-right">
                          <button
                            type="button"
                            className="btn admin_btn float-right"
                            onClick={() => {
                              this.stepperClick("test-l-7", "Files");
                              this.stepper.next();
                            }}
                          >
                            Next &nbsp;
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="test-l-11"
                className={
                  (this.props.location.state.courseType != 2 ||
                    this.state.courseDateType == null) &&
                    this.state.render
                    ? "content hideimp"
                    : "content"
                }
              >
                <div className="container form-container">
                  <div className="admin-card steper_below card">
                    <div className="card-body">
                      <div className="form-group"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="test-l-3" className="content">
                <div className="container form-container">
                  <div className="admin-card steper_below card">
                    <div className="card-body">
                      <div className="form-group"></div>

                      <div className="col-md-12">
                        <div className="float-right">
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="button"
                            className="btn admin_btn"
                            onClick={() => this.stepper.next()}
                          >
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp; Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="test-l-8" className="content">
                <div className="container">
                  <div className="admin-card steper_below card">
                    <div className="card-body">
                      <div className="form-group">
                        {this.state.render &&
                          parseInt(this.props.location.state.courseType) == 2 ? (
                            <Assessments
                              isEdit={this.state.isEdit}
                              currentWebinarData={this.state.currentWebinarData}
                              nextFromAssessment={this.nextFromAssessment}
                            />
                          ) : (
                            ""
                          )}
                      </div>
                      <div className="col-md-12">
                        <div className="float-left">
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="button"
                            className="btn admin_btn"
                            onClick={() => {
                              this.stepperClick("test-l-7", "Files");
                              this.stepper.previous();
                            }}
                          >
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp; Back
                          </button>
                        </div>
                        <div className="float-right">
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="button"
                            className="btn admin_btn"
                            onClick={() => {
                              this.stepperClick("test-l-6", "Faqs");
                              this.stepper.next();
                            }}
                          >
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp; Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="test-l-7" className="content">
                <div className="container form-container">
                  <div className="admin-card steper_below card">
                    <div className="card-body">
                      <div className="form-group">
                        {this.state.render && this.state.update ? (
                          this.props.location.state.courseType == 2 ? (
                            <FreeCC
                              courseType={this.props.location.state.courseType}
                              keyProp={newKey}
                              isEdit={this.state.isEdit}
                              render={this.state.render}
                              courseTitle={this.state.courseTitle}
                              isUpdate={this.isUpdate}
                              update={this.state.update}
                              currentWebinarData={this.state.currentWebinarData}
                              setWebinarFiles={this.getWebinarFiles}
                              AddMoreExtraFiles={this.AddMoreExtraFiles}
                              onRef={(ref1) => (this.fileChild = ref1)}
                              nextifEmpty={this.nextFromFiles}
                            />
                          ) : (
                              ""
                            )
                        ) : (
                            ""
                          )}
                      </div>
                      <div className="col-md-12 ">
                        <div className="float-left">
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="button"
                            className="btn admin_btn"
                            onClick={() => {
                              this.stepperClick("test-l-9", "Sessions");
                              this.stepper.previous();
                            }}
                          >
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp; Back
                          </button>
                        </div>
                        <div className="float-right">
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="button"
                            className="btn admin_btn"
                            onClick={() => {
                              this.stepperClick("test-l-8", "Assessments");
                              this.stepper.next();
                            }}
                          >
                            {" "}
                            Next &nbsp;
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    webinarDetails: state.webinarData.webinarDetails,
    webinars: state.webinarData.allWebinars,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    publishwebinar,
    create_webinar_faqs,
    create_webinar_sessions,
    get_faqs,
    get_ExtraResources,
    save_webinar_form,
    create_webinar_details,
    get_sessions,
    setFilesFirstSaveFlag,
    edit_webinar_details,
  })(WebinarForm)
);
