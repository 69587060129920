import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import WebNotif from "../../components/WebNotif";
import Header from "../Header";
import { urlapiEPE } from "../../config/urlapi";
import { confirmAlert } from "react-confirm-alert";
import Loader from "react-loader-spinner";
var obj = new WebNotif();

class ApplicationPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      savedData: JSON.parse(localStorage.getItem("reduxEpeForm")),
      pageLoader: false,
    };
  }

  submitApplication = () => {
    const token = localStorage.getItem("authToken");
    const { savedData } = this.state;

    return confirmAlert({
      customUI: ({ onClose }) => (
        <div className="custom-payment-modal">
          <h2>Undertaking!</h2>
          <ul>
            <li>
              I wish to take the EPE for {savedData?.form.examSeason} year{" "}
              {savedData?.form.examYear}
            </li>
            <li>
              I have no objection against my EPE process being stopped, should
              there be inaccuracy in the submitted information.
            </li>
            <li>
              I also undertake to abide by the PEC Act, Bye-Laws, relevant rules
              and Codes of Ethics and Conduct, prescribed for the members of the
              Council.
            </li>
            <li>
              I certify that the above particulars mentioned by me are true and
              correct.
            </li>
          </ul>
          <div className="custom-payment-buttons">
            <button
              className="btn btn-sm btn-success"
              onClick={async () => {
                try {
                  onClose();
                  const examId = localStorage.getItem("getLatestExamId");

                  this.setState({ pageLoader: true });

                  // Map the fields according to backend expectations
                  const application = {
                    ...savedData.form,
                    fullName: savedData.form.name, // Map name to fullName
                    selectedPaperAttempt: savedData?.form?.selectedPaperPart,
                    singlePaperPart: savedData?.form?.selectedPaperType,
                    examId: examId,
                  };

                  // Remove the original 'name' field if it's not needed
                  delete application.name;
                  delete application.selectedPaperPart;
                  delete application.selectedPaperType;

                  const response = await axios.post(
                    `${urlapiEPE}/cpd/applicant`,
                    application
                  );
                  if (response.status === 200) {
                    this.setState({ pageLoader: false });
                    obj.createNotification(
                      "success",
                      `${response?.data?.message}`
                    );
                    localStorage.removeItem("reduxEpeForm");
                    this.props.history.push("/cpd/CpdFormProfile");
                  } else if (response.status === 400) {
                    this.setState({ pageLoader: false });
                    obj.createNotification("error", `${response?.message}`);
                    localStorage.removeItem("reduxEpeForm");
                    this.props.history.push("/cpd/CpdFormProfile");
                  } else {
                    this.setState({ pageLoader: false });
                  }
                } catch (err) {
                  this.setState({ pageLoader: false });

                  obj.createNotification(
                    "error",
                    err?.response?.data?.message
                      ? err?.response?.data?.message
                      : "Something went wrong"
                  );
                } finally {
                  this.setState({ pageLoader: false });
                }
              }}
            >
              Accept
            </button>
            <button className="btn btn-sm btn-dark" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      ),
    });
  };

  updateApplication = () => {
    console.log("INSIDE updateApplication");
    return confirmAlert({
      customUI: ({ onClose }) => (
        <div className="custom-payment-modal">
          <h2>Undertaking!</h2>
          <ul>
            <li>
              I wish to take the EPE for {this.state.examSeason} year{" "}
              {this.state.examYear}
            </li>
            <li>
              I have no objection against my EPE process being stopped, should
              there be inaccuracy in the submitted information.
            </li>
            <li>
              I also undertake to abide by the PEC Act, Bye-Laws, relevant rules
              and Codes of Ethics and Conduct, prescribed for the members of the
              Council.
            </li>
            <li>
              I certify that the above particulars mentioned by me are true and
              correct.
            </li>
          </ul>
          <div className="custom-payment-buttons">
            <button
              className="btn btn-sm btn-success"
              onClick={async () => {
                try {
                  onClose();
                  const examId = localStorage.getItem("getLatestExamId");

                  const reduxEpeForm = JSON.parse(
                    localStorage.getItem("reduxEpeForm")
                  );

                  this.setState({ pageLoader: true });

                  const application = {
                    userProfile: reduxEpeForm?.form?.userProfile,
                    pecNo: reduxEpeForm?.form?.regNo,
                    cnic: reduxEpeForm?.form?.cnic,
                    examId: examId,
                    fullName: reduxEpeForm?.form?.name,
                    regNo: reduxEpeForm?.form?.regNo,
                    discipline: reduxEpeForm?.form.discipline,
                    alliedDiscipline: reduxEpeForm?.form.alliedDiscipline,
                    subDiscipline: reduxEpeForm?.form?.subDiscipline,
                    registrationDate: reduxEpeForm?.form?.registrationDate,
                    postalAddress: reduxEpeForm?.form?.postalAddress,
                    alternateContact: reduxEpeForm?.form?.alternateContact,
                    mobile: reduxEpeForm?.form?.mobile,
                    email: reduxEpeForm?.form?.email,
                    nationality: reduxEpeForm?.form?.nationality,
                    DOB: reduxEpeForm?.form?.DOB,
                    centerPrority_1: reduxEpeForm.form.centerPrority_1,
                    centerPrority_2: reduxEpeForm.form.centerPrority_2,
                    centerPrority_3: reduxEpeForm.form.centerPrority_3,
                    paper_count: reduxEpeForm?.form?.paper_count,
                    historyExist: reduxEpeForm?.form?.historyExist,
                    cpdPointsData: reduxEpeForm?.form?.cpdPointsData,
                    rejectionReason: "",
                  };

                  const rejectedApplication =
                    localStorage.getItem("application");
                  if (!!rejectedApplication) {
                    const parsedApplication = JSON.parse(rejectedApplication);
                    application["applicantId"] = parsedApplication.applicant_id;
                  }
                  const response = await axios.put(
                    `${urlapiEPE}/cpd/update-application`,
                    application
                  );
                  if (response.status === 200) {
                    this.setState({ pageLoader: false });
                    obj.createNotification(
                      "success",
                      `${response?.data?.message}`
                    );
                    localStorage.removeItem("reduxEpeForm");
                    localStorage.removeItem("application");
                    localStorage.removeItem("paymentData");
                    localStorage.removeItem("updateApplication");
                    this.props.history.push("/cpd/CpdFormProfile");
                  } else if (response.status === 400) {
                    this.setState({ pageLoader: false });
                    obj.createNotification("error", `${response?.message}`);
                    localStorage.removeItem("reduxEpeForm");
                    localStorage.removeItem("application");
                    localStorage.removeItem("paymentData");
                    localStorage.removeItem("updateApplication");

                    this.props.history.push("/cpd/CpdFormProfile");
                  } else {
                    setLoad(this.setState({ pageLoader: false }));
                  }
                } catch (err) {
                  console.log("dddddddddddddddd", err);
                  this.setState({ pageLoader: false });

                  obj.createNotification(
                    "error",
                    err?.response?.data?.message
                      ? err?.response?.data?.message
                      : "Something went wrong"
                  );
                } finally {
                  this.setState({ pageLoader: false });
                }
              }}
            >
              Accept
            </button>
            <button className="btn btn-sm btn-dark" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      ),
    });
  };

  render() {
    const { savedData } = this.state;

    if (!savedData) {
      return <div>No data available.</div>;
    }

    return (
      <>
        {this.state.pageLoader ? (
          <Fragment>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <Loader type="Oval" color="#06580e" height={80} width={80} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Header />
            <div className="App">
              <div className="container-fluid ">
                <div className="mt-4 px-5 py-3">
                  <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-2"></div>
                    <div className="col-xl-8 col-lg-8 col-md-8 message-background text-left px-5 p-4">
                      <h3 className="text-center pb-3 bold">
                        Application Preview
                      </h3>
                      <div>
                        {/* Full Name */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">Full Name</label>
                          <input
                            type="text"
                            name="fullName"
                            value={savedData?.form.name}
                            className="form-control form-control-sm disabled text-capitalize"
                            disabled
                          />
                        </div>

                        {/* Email */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">Email</label>
                          <input
                            type="text"
                            name="email"
                            value={savedData?.form.email}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        {/* DOB */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">DOB</label>
                          <input
                            type="text"
                            name="dob"
                            value={savedData?.form.DOB}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        {/* Mobile */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">Mobile</label>
                          <input
                            type="text"
                            name="mobile"
                            value={savedData?.form.mobile}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        {/* Alternate Contact */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">
                            Alternative Contact
                          </label>
                          <input
                            type="text"
                            name="alternateContact"
                            value={savedData?.form.alternateContact}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        {/* Postal Address */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">Postal Address</label>
                          <input
                            type="text"
                            name="postalAddress"
                            value={savedData?.form.postalAddress}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        {/* CNIC */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">CNIC</label>
                          <input
                            type="text"
                            name="cnic"
                            value={savedData?.form.cnic}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        {/* Nationality */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">Nationality</label>
                          <input
                            type="text"
                            name="nationality"
                            value={savedData?.form.nationality}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        {/* Registration No */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">
                            Registration No
                          </label>
                          <input
                            type="text"
                            name="registrationNo"
                            value={savedData?.form.regNo}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        {/* Challan Number */}
                        {savedData?.form?.isAlreadyPaymentDone !== "true" && (
                          <>
                            {/* Application Type */}
                            <div className="form-group mt-4">
                              <label className="mb-2 font-14">
                                Application Type
                              </label>
                              <input
                                type="text"
                                name="applicationType"
                                value={savedData?.form.applicationType.label}
                                className="form-control form-control-sm disabled"
                                disabled
                              />
                            </div>

                            {/* Bank Name */}
                            <div className="form-group mt-4">
                              <label className="mb-2 font-14">Bank Name</label>
                              <input
                                type="text"
                                name="bankName"
                                value={savedData?.form.bankName}
                                className="form-control form-control-sm disabled"
                                disabled
                              />
                            </div>
                            <div className="form-group mt-4">
                              <label className="mb-2 font-14">
                                Challan Number
                              </label>
                              <input
                                type="text"
                                name="challanNumber"
                                value={savedData?.form.challanNumber}
                                className="form-control form-control-sm disabled"
                                disabled
                              />
                            </div>

                            {/* Challan Amount */}
                            <div className="form-group mt-4">
                              <label className="mb-2 font-14">
                                Challan Amount
                              </label>
                              <input
                                type="text"
                                name="challanAmount"
                                value={savedData?.form.challanAmount}
                                className="form-control form-control-sm disabled"
                                disabled
                              />
                            </div>

                            {/* Submission Date */}
                            <div className="form-group mt-4">
                              <label className="mb-2 font-14">
                                Submission Date
                              </label>
                              <input
                                type="text"
                                name="submissionDate"
                                value={savedData?.form.submissionDate}
                                className="form-control form-control-sm disabled"
                                disabled
                              />
                            </div>
                            {savedData?.form?.applicationType?.value === 2 && (
                              <>
                                <div className="form-group mt-4">
                                  <label className="mb-2 font-14">
                                    Choose Paper Part
                                  </label>
                                  <input
                                    type="text"
                                    name="paperPart"
                                    value={savedData?.form.selectedPaperPart}
                                    className="form-control form-control-sm disabled"
                                    disabled
                                  />
                                </div>

                                {/* Choose Attempt Number */}
                                <div className="form-group mt-4">
                                  <label className="mb-2 font-14">
                                    Choose Attempt Number
                                  </label>
                                  <input
                                    type="text"
                                    name="attemptNumber"
                                    value={savedData?.form.selectedPaperType}
                                    className="form-control form-control-sm disabled"
                                    disabled
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}

                        {/* Choose Paper Part */}

                        {/* Registration Date */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">
                            Date of Registration
                          </label>
                          <input
                            type="text"
                            name="registrationDate"
                            value={savedData?.form.registrationDate}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        {/* Discipline */}
                        <div className="form-group mt-4">
                          <label className="mb-2 font-14">Discipline</label>
                          <input
                            type="text"
                            name="discipline"
                            value={savedData?.form.alliedDiscipline}
                            className="form-control form-control-sm disabled"
                            disabled
                          />
                        </div>

                        <div className="w-100 mt-4">
                          <h5 className="mb-3">Sponsor Details</h5>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-2 font-14">Name</label>
                                <input
                                  type="text"
                                  name="sponsor1Name"
                                  value={savedData?.form?.sponsor1Name}
                                  className="form-control form-control-sm disabled"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-2 font-14">PEC No</label>
                                <input
                                  type="text"
                                  name="sponsor1PECNo"
                                  value={savedData?.form?.sponsor1PECNo}
                                  className="form-control form-control-sm disabled"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-2 font-14">
                                  Discipline
                                </label>
                                <input
                                  type="text"
                                  name="sponsor1Discipline"
                                  value={savedData?.form?.sponsor1Discipline}
                                  className="form-control form-control-sm disabled"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-2 font-14">
                                  Designation
                                </label>
                                <input
                                  type="text"
                                  name="sponsor1Designation"
                                  value={savedData?.form?.sponsor1Designation}
                                  className="form-control form-control-sm disabled"
                                  disabled
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-2 font-14">
                                  Experience
                                </label>
                                <input
                                  type="text"
                                  name="sponsor1Experience"
                                  value={savedData?.form?.sponsor1Experience}
                                  className="form-control form-control-sm disabled"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "20px",
                          }}
                        >
                          <div>
                            <p>
                              <strong>Profile Image:</strong>
                            </p>
                            <img
                              src={savedData?.form.userProfile}
                              alt="Profile"
                              style={{
                                maxHeight: "250px",
                                maxWidth: "250px",
                                objectFit: "cover",
                                borderRadius: "8px",
                                border: "1px solid #ccc",
                              }}
                            />
                          </div>
                          {savedData?.form?.isAlreadyPaymentDone !== "true" && (
                            <div>
                              <p>
                                <strong>Payment Image:</strong>
                              </p>
                              <img
                                src={savedData?.form.paymentImage}
                                alt="Payment"
                                style={{
                                  maxHeight: "250px",
                                  maxWidth: "250px",
                                  objectFit: "cover",
                                  borderRadius: "8px",
                                  border: "1px solid #ccc",
                                }}
                              />
                            </div>
                          )}
                        </div>

                        {/* <div className="form-group">
                <button
                  type="button"
                  className="btn admin_btn_default btn-sm fontSize1Rem float-right"
                  style={{ fontWeight: 'bold', marginTop: '15px' }}
                  onClick={this.submitApplication}
                >
                  Submit Application
                </button>
              </div> */}
                        <div className="row col_margin mt-5">
                          <div className="col-md-3"></div>
                          <div className="col-md-6 text-center">
                            <button
                              type="button"
                              className="btn btn-success bold  text-capitalize"
                              onClick={
                                savedData?.form?.isAlreadyPaymentDone !== "true"
                                  ? this.submitApplication
                                  : this.updateApplication
                              }
                            >
                              Submit Application
                            </button>
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </>
    );
  }
}

export default withRouter(ApplicationPreview);
